/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { Button, Popconfirm, Tooltip } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { MdDeleteOutline } from 'react-icons/md';
import { toast } from 'react-toastify';
import {
  Container,
  ProductQuestionContent,
  ProductQuestionOptions,
  ProductQuestionsBox,
  SubQuestions,
} from './styles';
import { AddProductSubQuestionModal } from './components/AddProductSubQuestionModal';
import { api } from '../../../services/api';

export function ProductQuestions({
  productQuestions,
  setProductQuestions,
  handleOnDragEnd,
  productId,
  getProduct,
}) {
  const [isModalAddQuestionsOpen, setIsModalAddQuestionsOpen] = useState(false);
  const [productQuestionOpen, setProductQuestionOpen] = useState();

  const openModalAddSubQuestion = (productQuestion) => {
    setIsModalAddQuestionsOpen(true);
    setProductQuestionOpen(productQuestion);
  };

  const getProductSubQuestion = useCallback(
    (question_id) => {
      const productQuestion = productQuestions.find(
        (productSubQuestionItem) => productSubQuestionItem.id === question_id,
      );

      if (productQuestion) {
        return productQuestion.subquestions;
      }

      return [];
    },
    [productQuestions],
  );

  async function deleteSubquestion(subquestionId) {
    try {
      await api.delete(`/products/subquestions/${subquestionId}`);
      getProduct();
      toast.success('Subquestõa removida com sucesso');
    } catch (error) {
      toast.error('Erro ao excluir subquestão');
    }
  }

  return (
    <Container>
      <DragDropContext onDragEnd={handleOnDragEnd}>
        <Droppable droppableId="characters">
          {(provided) => (
            <ProductQuestionsBox
              {...provided.droppableProps}
              ref={provided.innerRef}
            >
              {productQuestions.map((productQuestionItem, index) => (
                <Draggable
                  key={String(productQuestionItem.id)}
                  draggableId={String(productQuestionItem.id)}
                  index={index}
                >
                  {(providedDrag) => (
                    <div
                      ref={providedDrag.innerRef}
                      {...providedDrag.draggableProps}
                      {...providedDrag.dragHandleProps}
                    >
                      <ProductQuestionContent className="content">
                        <div>
                          <div>
                            <strong>{productQuestionItem.title}</strong>
                            <p>
                              Limite de caracteries:
                              {` ${productQuestionItem.character_limit}`}
                            </p>
                            <p>
                              Nota:
                              {productQuestionItem.score}
                            </p>
                          </div>

                          <ProductQuestionOptions>
                            <Tooltip title="Adicionar sub questão">
                              <Button
                                style={{ marginBottom: 50 }}
                                type="primary"
                                shape="circle"
                                icon={<PlusOutlined />}
                                onClick={() =>
                                  openModalAddSubQuestion(productQuestionItem)
                                }
                              />
                            </Tooltip>
                          </ProductQuestionOptions>
                        </div>

                        {/* Sub Questions */}
                        {getProductSubQuestion(productQuestionItem.id).map(
                          (productSubquestion) => (
                            <SubQuestions>
                              <div>
                                <strong>{`Sub item - ${productSubquestion.title}`}</strong>
                                <p>
                                  Limite de caracteries:
                                  {` ${productSubquestion.character_limit}`}
                                </p>
                                <p>
                                  Nota:
                                  {productSubquestion.score}
                                </p>
                              </div>
                              <Popconfirm
                                title="Apagar banner"
                                description="Tem certeza que deseja apagar o banner ?"
                                onConfirm={() =>
                                  deleteSubquestion(productSubquestion.id)
                                }
                                okText="Confirmar"
                                cancelText="Não"
                              >
                                <Button
                                  style={{
                                    background: '#ff4d4f',
                                    marginLeft: 15,
                                  }}
                                  type="primary"
                                  icon={
                                    <MdDeleteOutline size={20} color="#fff" />
                                  }
                                />
                              </Popconfirm>
                            </SubQuestions>
                          ),
                        )}
                      </ProductQuestionContent>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </ProductQuestionsBox>
          )}
        </Droppable>
      </DragDropContext>

      <AddProductSubQuestionModal
        isModalAddQuestionsOpen={isModalAddQuestionsOpen}
        setIsModalAddQuestionsOpen={setIsModalAddQuestionsOpen}
        productQuestions={productQuestions}
        setProductQuestions={setProductQuestions}
        productQuestionOpen={productQuestionOpen}
        productId={productId}
        getProduct={getProduct}
      />
    </Container>
  );
}
