/* eslint-disable react/jsx-no-bind */
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Select } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import { BoxForm, BoxInput, ButtonContainer, Container } from './styles';
import { PageTitle } from '../../components/PageTitle';
import { api } from '../../services/api';
import { Loading } from '../../components/Loading';

const { Option } = Select;
export function MessageCenterUpdate() {
  const [initialValues, setIntialValues] = useState();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [messageAllowTo, setMessageAllowTo] = useState('all');
  const [emailMessage, setEmailMessage] = useState('');
  const [loading, setLoading] = useState(true);

  const { central_message_id } = useParams();

  const navigate = useNavigate();

  async function updateMessageCenter(values) {
    setButtonLoading(true);
    try {
      const { title } = values;

      await api.put(`/message-center/${central_message_id}`, {
        title,
        message: emailMessage,
        allow_to: messageAllowTo,
      });
      toast.success('Mensagem atualizada com sucesso');

      setButtonLoading(false);
      navigate(-1);
    } catch (err) {
      toast.error('Erro ao atualizar mensagem');
      setButtonLoading(false);
    }
  }

  function handleUserType(type) {
    setMessageAllowTo(type);
  }

  useEffect(() => {
    async function getMesssages() {
      try {
        const response = await api.get(
          `/message-center/show/${central_message_id}`,
        );
        setIntialValues(response.data);
        setEmailMessage(response.data.message);
        setMessageAllowTo(response.data.allow_to);
        setLoading(false);
      } catch (error) {
        toast.error('Erro ao carregar mensagem');
        setLoading(false);
      }
    }
    getMesssages();
  }, []);

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="/central-mensagens">
          <NavLink to="/central-mensagens">Central de Mensagens</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Atualização</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Atualização de mensagens"
        description="Mensagens para coordenadores e especialistas"
      />

      {!loading && initialValues && (
        <BoxForm>
          <Formik initialValues={initialValues} onSubmit={updateMessageCenter}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <BoxInput>
                  <label htmlFor="name">Habilitado para</label>
                  <Select
                    defaultValue={messageAllowTo}
                    style={{ width: 300, height: 38 }}
                    onChange={handleUserType}
                  >
                    <Option key="all">Todos</Option>
                    <Option key="coordinator">Coordenador</Option>
                    <Option key="proofreader">Especialista</Option>
                  </Select>
                </BoxInput>

                <BoxInput>
                  <label htmlFor="title">Título</label>
                  <Field
                    id="title"
                    type="text"
                    name="title"
                    placeholder="Título do e-mail"
                  />
                </BoxInput>

                <BoxInput>
                  <label htmlFor="message">Mensagem</label>
                  <textarea
                    value={emailMessage}
                    onChange={(event) => setEmailMessage(event.target.value)}
                    style={{ height: 400 }}
                  />
                </BoxInput>

                <ButtonContainer>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    loading={buttonLoading}
                  >
                    Atualiza mensagem
                  </Button>
                </ButtonContainer>
              </form>
            )}
          </Formik>
        </BoxForm>
      )}

      {loading && <Loading />}
    </Container>
  );
}
