import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  padding: 20px 50px;
  width: 100%;
  max-width: 1600px;
  margin-bottom: 80px;

  .button-container {
    margin-top: 60px;
    display: flex;
    justify-content: center;
  }
`;

export const ProductList = styled.div``;

export const ProductListItem = styled.div`
  border-block-end: 1px solid rgba(5, 5, 5, 0.06);
  display: flex;
  justify-content: space-between;
  padding: 25px 0;

  .info {
    display: flex;
    flex-direction: column;

    strong {
      margin: 0 0 4px 0;
      color: rgba(0, 0, 0, 0.88);
      font-size: 16px;
      line-height: 1.5714285714285714;
    }

    p {
      color: rgba(0, 0, 0, 0.45);
      font-size: 14px;
      line-height: 1.5714285714285714;
    }
  }

  .actions {
    display: flex;
  }
`;

export const AssociationProductContent = styled.div`
  border-radius: 6px;
  margin-top: 40px;
  margin-bottom: 60px;

  h3 {
    margin-bottom: 15px;
  }

  .ant-table-wrapper {
    margin-top: 50px;
  }

  .css-b62m3t-container,
  .css-b62m3t-container {
    width: 100%;
  }
`;

export const AssociationProductContentSelect = styled.div`
  display: flex;
  align-items: center;
`;
