/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useRef, useState } from 'react';
import { Breadcrumb, Button, Image, Modal, Popconfirm, Switch } from 'antd';
import { NavLink } from 'react-router-dom';
import {
  CloudUploadOutlined,
  DownloadOutlined,
  HomeOutlined,
  PlusOutlined,
} from '@ant-design/icons';
import { toast } from 'react-toastify';
import { MdDeleteOutline, MdModeEditOutline } from 'react-icons/md';
import { BannerBox, Container, UploadButton, UploadContainer } from './styles';
import { PageTitle } from '../../components/PageTitle';
import { api } from '../../services/api';
import { BoxInput } from '../ProductUpdate/styles';

export function Banners() {
  const [loading, setLoading] = useState(false);
  const [isModalAddBannerOpen, setIsModalAddBannerOpen] = useState(false);
  const [isModalUpdateBannerOpen, setisModalUpdateBannerOpen] = useState(false);
  const [bannerType, setBannerType] = useState('');
  const [banners, setBanners] = useState();

  const [desktopImageToUpload, setDesktopImageToUpload] = useState();
  const [mobileImageToUpload, setMobileImageToUpload] = useState();
  const [bannerLink, setBannerLink] = useState();
  const [isBannerActive, setIsBannerActive] = useState(false);

  const [bannerToUpdate, setbannerToUpdate] = useState('');
  const [previewUrlDesktopImage, setPreviewUrlDesktopImage] = useState('');
  const [previewUrlMobileImage, setPreviewUrlMobileImage] = useState('');

  async function getBanners() {
    try {
      const response = await api.get('/banners');
      setBanners(response.data);
    } catch (error) {
      toast.error('Erro a listar banners');
    }
  }

  async function uploadBannerMain() {
    try {
      setLoading(true);
      const formData = new FormData();

      if (desktopImageToUpload) {
        formData.append(
          'imageDesktop',
          desktopImageToUpload,
          desktopImageToUpload.name,
        );
      }

      if (mobileImageToUpload) {
        formData.append(
          'imageMobile',
          mobileImageToUpload,
          mobileImageToUpload.name,
        );
      }

      formData.append('banner_type', bannerType);
      formData.append('link', bannerLink);
      formData.append('is_active', isBannerActive);

      await api.post('/banners', formData);

      setIsBannerActive(false);
      setBannerLink('');
      setMobileImageToUpload();
      setDesktopImageToUpload();
      setIsModalAddBannerOpen();
      setLoading(false);

      getBanners();
      toast.success('Upload feito com sucesso');
    } catch (error) {
      toast.error('Erro ao fazer upload do banner ');
    }
  }

  function handleUploadBannerDesktop(event) {
    if (event.target.files) {
      const file = event.target.files[0];
      setDesktopImageToUpload(file);

      const reader = new FileReader();

      reader.onload = () => {
        setPreviewUrlDesktopImage(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  }

  function handleUploadBannerMobile(event) {
    if (event.target.files) {
      const file = event.target.files[0];
      setMobileImageToUpload(file);

      const reader = new FileReader();

      reader.onload = () => {
        setPreviewUrlMobileImage(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  }

  useEffect(() => {
    getBanners();
  }, []);

  async function getBannerById(bannerId) {
    try {
      const response = await api.get(`/banners/show/${bannerId}`);
      setisModalUpdateBannerOpen(true);
      setIsBannerActive(response.data.is_active);
      setBannerLink(response.data.link);
      setbannerToUpdate(response.data);
    } catch (error) {
      toast.error('Erro a obter banner');
    }
  }

  async function updateBanner() {
    try {
      setLoading(true);
      const formData = new FormData();

      if (desktopImageToUpload) {
        formData.append(
          'imageDesktop',
          desktopImageToUpload,
          desktopImageToUpload.name,
        );
      }

      if (mobileImageToUpload) {
        formData.append(
          'imageMobile',
          mobileImageToUpload,
          mobileImageToUpload.name,
        );
      }

      formData.append('banner_type', bannerType);
      formData.append('link', bannerLink);
      formData.append('is_active', isBannerActive);

      await api.put(`/banners/${bannerToUpdate.id}`, formData);

      setIsBannerActive(false);
      setBannerLink('');
      setMobileImageToUpload();
      setDesktopImageToUpload();
      setIsModalAddBannerOpen();
      setisModalUpdateBannerOpen(false);

      setLoading(false);

      getBanners();
      toast.success('Banner atualizado comsucesso');
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao atualizar do banner ');
    }
  }

  async function deleteBanner(bannerId) {
    try {
      await api.delete(`/banners/${bannerId}`);
      getBanners();
      toast.success('Banner removido com sucesso');
    } catch (error) {
      toast.error('Erro ao deletar banner');
    }
  }

  const inputImageDesktopRef = useRef(null);

  const handleClickImageDesktop = () => {
    inputImageDesktopRef.current.click();
  };

  const inputImageMobileRef = useRef(null);

  const handleClickImageMobile = () => {
    inputImageMobileRef.current.click();
  };

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Banners</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Banners"
        description="Gerenciamento do banner principal e seções"
      />

      <BannerBox>
        <div className="header">
          <h2>Banner Principal</h2>

          <Button
            style={{ marginBottom: 50 }}
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            loading={loading}
            onClick={() => {
              setIsModalAddBannerOpen(true);
              setBannerType('BANNER_MAIN');
            }}
          />
        </div>
        <div className="images">
          {!!banners &&
            !!banners.banners_main.length &&
            banners.banners_main.map((bannerItem) => (
              <div className="banner-image">
                <Image
                  width={700}
                  height={150}
                  src={bannerItem.image_desktop_url}
                />

                <Button
                  style={{ marginLeft: 20 }}
                  type="primary"
                  icon={<MdModeEditOutline size={20} color="#fff" />}
                  onClick={() => getBannerById(bannerItem.id)}
                />

                <Popconfirm
                  title="Apagar banner"
                  description="Tem certeza que deseja apagar o banner ?"
                  onConfirm={() => deleteBanner(bannerItem.id)}
                  okText="Confirmar"
                  cancelText="Não"
                >
                  <Button
                    style={{ background: '#ff4d4f', marginLeft: 15 }}
                    type="primary"
                    icon={<MdDeleteOutline size={20} color="#fff" />}
                  />
                </Popconfirm>
              </div>
            ))}
        </div>
      </BannerBox>

      <BannerBox>
        <div className="header">
          <h2>Banner Seção 01</h2>

          <Button
            style={{ marginBottom: 50 }}
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsModalAddBannerOpen(true);
              setBannerType('SECTION_01');
            }}
          />
        </div>
        <div className="images">
          {!!banners &&
            !!banners.section_01.length &&
            banners.section_01.map((bannerItem) => (
              <div className="banner-image">
                <Image
                  width={700}
                  height={150}
                  src={bannerItem.image_desktop_url}
                />

                <Button
                  style={{ marginLeft: 20 }}
                  type="primary"
                  icon={<MdModeEditOutline size={20} color="#fff" />}
                  onClick={() => getBannerById(bannerItem.id)}
                />
                <Popconfirm
                  title="Apagar banner"
                  description="Tem certeza que deseja apagar o banner ?"
                  onConfirm={() => deleteBanner(bannerItem.id)}
                  okText="Confirmar"
                  cancelText="Não"
                >
                  <Button
                    style={{ background: '#ff4d4f', marginLeft: 15 }}
                    type="primary"
                    icon={<MdDeleteOutline size={20} color="#fff" />}
                  />
                </Popconfirm>
              </div>
            ))}
        </div>
      </BannerBox>

      <BannerBox>
        <div className="header">
          <h2>Banner Seção 02</h2>

          <Button
            style={{ marginBottom: 50 }}
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsModalAddBannerOpen(true);
              setBannerType('SECTION_02');
            }}
          />
        </div>
        <div className="images">
          {!!banners &&
            !!banners.section_02.length &&
            banners.section_02.map((bannerItem) => (
              <div className="banner-image">
                <Image
                  width={700}
                  height={150}
                  src={bannerItem.image_desktop_url}
                />

                <Button
                  style={{ marginLeft: 20 }}
                  type="primary"
                  icon={<MdModeEditOutline size={20} color="#fff" />}
                  onClick={() => getBannerById(bannerItem.id)}
                />
                <Popconfirm
                  title="Apagar banner"
                  description="Tem certeza que deseja apagar o banner ?"
                  onConfirm={() => deleteBanner(bannerItem.id)}
                  okText="Confirmar"
                  cancelText="Não"
                >
                  <Button
                    style={{ background: '#ff4d4f', marginLeft: 15 }}
                    type="primary"
                    icon={<MdDeleteOutline size={20} color="#fff" />}
                  />
                </Popconfirm>
              </div>
            ))}
        </div>
      </BannerBox>

      <BannerBox>
        <div className="header">
          <h2>Banner Seção 03</h2>

          <Button
            style={{ marginBottom: 50 }}
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsModalAddBannerOpen(true);
              setBannerType('SECTION_03');
            }}
          />
        </div>
        <div className="images">
          {!!banners &&
            !!banners.section_03.length &&
            banners.section_03.map((bannerItem) => (
              <div className="banner-image">
                <Image
                  width={700}
                  height={150}
                  src={bannerItem.image_desktop_url}
                />

                <Button
                  style={{ marginLeft: 20 }}
                  type="primary"
                  icon={<MdModeEditOutline size={20} color="#fff" />}
                  onClick={() => getBannerById(bannerItem.id)}
                />
                <Popconfirm
                  title="Apagar banner"
                  description="Tem certeza que deseja apagar o banner ?"
                  onConfirm={() => deleteBanner(bannerItem.id)}
                  okText="Confirmar"
                  cancelText="Não"
                >
                  <Button
                    style={{ background: '#ff4d4f', marginLeft: 15 }}
                    type="primary"
                    icon={<MdDeleteOutline size={20} color="#fff" />}
                  />
                </Popconfirm>
              </div>
            ))}
        </div>
      </BannerBox>

      <BannerBox>
        <div className="header">
          <h2>Banner Seção 04</h2>

          <Button
            style={{ marginBottom: 50 }}
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsModalAddBannerOpen(true);
              setBannerType('SECTION_04');
            }}
          />
        </div>
        <div className="images">
          {!!banners &&
            !!banners.section_04.length &&
            banners.section_04.map((bannerItem) => (
              <div className="banner-image">
                <Image
                  width={700}
                  height={150}
                  src={bannerItem.image_desktop_url}
                />

                <Button
                  style={{ marginLeft: 20 }}
                  type="primary"
                  icon={<MdModeEditOutline size={20} color="#fff" />}
                  onClick={() => getBannerById(bannerItem.id)}
                />
                <Popconfirm
                  title="Apagar banner"
                  description="Tem certeza que deseja apagar o banner ?"
                  onConfirm={() => deleteBanner(bannerItem.id)}
                  okText="Confirmar"
                  cancelText="Não"
                >
                  <Button
                    style={{ background: '#ff4d4f', marginLeft: 15 }}
                    type="primary"
                    icon={<MdDeleteOutline size={20} color="#fff" />}
                  />
                </Popconfirm>
              </div>
            ))}
        </div>
      </BannerBox>

      <BannerBox>
        <div className="header">
          <h2>Banner Seção 05</h2>

          <Button
            style={{ marginBottom: 50 }}
            type="primary"
            shape="circle"
            icon={<PlusOutlined />}
            onClick={() => {
              setIsModalAddBannerOpen(true);
              setBannerType('SECTION_05');
            }}
          />
        </div>
        <div className="images">
          {!!banners &&
            !!banners.section_05.length &&
            banners.section_05.map((bannerItem) => (
              <div className="banner-image">
                <Image
                  width={700}
                  height={150}
                  src={bannerItem.image_desktop_url}
                />

                <Button
                  style={{ marginLeft: 20 }}
                  type="primary"
                  icon={<MdModeEditOutline size={20} color="#fff" />}
                  onClick={() => getBannerById(bannerItem.id)}
                />
                <Popconfirm
                  title="Apagar banner"
                  description="Tem certeza que deseja apagar o banner ?"
                  onConfirm={() => deleteBanner(bannerItem.id)}
                  okText="Confirmar"
                  cancelText="Não"
                >
                  <Button
                    style={{ background: '#ff4d4f', marginLeft: 15 }}
                    type="primary"
                    icon={<MdDeleteOutline size={20} color="#fff" />}
                  />
                </Popconfirm>
              </div>
            ))}
        </div>
      </BannerBox>

      {/* Modal Add Banner */}
      <Modal
        title="Cadastrar banner"
        open={isModalAddBannerOpen}
        footer={null}
        onCancel={() => setIsModalAddBannerOpen(false)}
        width={700}
      >
        <BoxInput style={{ marginTop: 30 }}>
          <label htmlFor="imageDesktop">Imagem Desktop</label>
          <input
            id="imageDesktop"
            type="file"
            name="imageDesktop"
            onChange={handleUploadBannerDesktop}
          />
        </BoxInput>

        <BoxInput>
          <label htmlFor="imageMobile">Imagem Mobile</label>
          <input
            id="imageMobile"
            type="file"
            name="imageMobile"
            onChange={handleUploadBannerMobile}
          />
        </BoxInput>

        <BoxInput>
          <label htmlFor="bannerLink">Link</label>
          <input
            id="bannerLink"
            type="text"
            name="bannerLink"
            value={bannerLink}
            onChange={(event) => setBannerLink(event.target.value)}
          />
        </BoxInput>

        <BoxInput>
          <label htmlFor="available_for_sale">Mostrar na loja ?</label>
          <Switch
            defaultChecked
            checked={isBannerActive}
            onChange={() => setIsBannerActive(!isBannerActive)}
          />
        </BoxInput>

        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Button type="primary" onClick={uploadBannerMain} loading={loading}>
            Fazer upload
          </Button>
        </div>
      </Modal>

      {/* Modal Update Banner */}
      <Modal
        title="Atualizar banner"
        open={isModalUpdateBannerOpen}
        footer={null}
        onCancel={() => setisModalUpdateBannerOpen(false)}
        width={700}
      >
        <BoxInput style={{ marginTop: 30 }}>
          <UploadContainer>
            <div>
              <UploadButton htmlFor="imageDesktop">
                <span>Imagem Desktop</span>

                <input
                  style={{ display: 'none' }}
                  id="imageDesktop"
                  type="file"
                  name="imageDesktop"
                  onChange={handleUploadBannerDesktop}
                  ref={inputImageDesktopRef}
                />
              </UploadButton>
              <Button
                htmlFor="imageDesktop"
                type="default"
                icon={<CloudUploadOutlined />}
                onClick={handleClickImageDesktop}
              >
                Fazer upload
              </Button>
            </div>

            <div style={{ marginTop: 20 }}>
              <Image
                height={50}
                src={previewUrlDesktopImage || bannerToUpdate.image_desktop_url}
              />
            </div>
          </UploadContainer>
        </BoxInput>

        <BoxInput>
          <UploadContainer>
            <div>
              <UploadButton htmlFor="imageMobile">
                <span>Imagem Mobile</span>
                <input
                  style={{ display: 'none' }}
                  id="imageMobile"
                  type="file"
                  name="imageMobile"
                  onChange={handleUploadBannerMobile}
                  ref={inputImageMobileRef}
                />
              </UploadButton>

              <Button
                htmlFor="imageDesktop"
                type="default"
                icon={<CloudUploadOutlined />}
                onClick={handleClickImageMobile}
              >
                Fazer upload
              </Button>
            </div>

            <div style={{ marginTop: 20 }}>
              <Image
                height={50}
                src={previewUrlMobileImage || bannerToUpdate.image_mobile_url}
              />
            </div>
          </UploadContainer>
        </BoxInput>

        <BoxInput>
          <label htmlFor="bannerLink">Link</label>
          <input
            id="bannerLink"
            type="text"
            name="bannerLink"
            value={bannerLink}
            onChange={(event) => setBannerLink(event.target.value)}
          />
        </BoxInput>

        <BoxInput>
          <label htmlFor="available_for_sale">Mostrar na loja ?</label>
          <Switch
            defaultChecked
            checked={isBannerActive}
            onChange={() => setIsBannerActive(!isBannerActive)}
          />
        </BoxInput>

        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Button type="primary" onClick={updateBanner} loading={loading}>
            Atualizar banner
          </Button>
        </div>
      </Modal>
    </Container>
  );
}
