import React, { useMemo, useState } from 'react';
import {
  MdBarChart,
  MdCategory,
  MdMenuOpen,
  MdOutlineMessage,
  MdOutlineTableChart,
  MdOutlineWysiwyg,
  MdProductionQuantityLimits,
  MdRateReview,
  MdSettings,
  MdShoppingCart,
  MdSupervisorAccount,
  MdWallpaper,
} from 'react-icons/md';
import { RiTeamFill } from 'react-icons/ri';

import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import { Container, Logo, Menu, ToggleMenu } from './styles';
import logo from '../../assets/logo.png';
import logoMini from '../../assets/logo-mini.png';
import { useAuth } from '../../hooks/AuthContext';

function Sidebar() {
  const { user } = useAuth();
  const isAdmin = useMemo(
    () =>
      user.permission_level === 'admin' || user.permission_level === 'master',
  );

  const [isMenuMinimized, setIsMenuMinimized] = useState(true);

  return (
    <Container isMenuMinimized={isMenuMinimized}>
      <Link to="/">
        <Logo isMenuMinimized={isMenuMinimized}>
          <img src={isMenuMinimized ? logoMini : logo} alt="" />
        </Logo>
      </Link>
      <Menu isMenuMinimized={isMenuMinimized}>
        <li>
          <Tooltip title="Pedidos" placement="rightTop">
            <Link to="/">
              <MdShoppingCart size={22} color="#fff" />
              <span>Pedidos</span>
            </Link>
          </Tooltip>
        </li>

        <li>
          <Tooltip title="Meus recursos" placement="rightTop">
            <Link to="/meus-recursos">
              <MdOutlineWysiwyg size={22} color="#fff" />
              <span>Meus Recursos</span>
            </Link>
          </Tooltip>
        </li>

        {isAdmin && (
          <li>
            <Tooltip title="Usuários" placement="rightTop">
              <Link to="/usuarios">
                <MdSupervisorAccount size={22} color="#fff" />
                <span>Úsuários</span>
              </Link>
            </Tooltip>
          </li>
        )}

        {isAdmin && (
          <li>
            <Tooltip title="Produtos" placement="rightTop">
              <Link to="/produtos">
                <MdProductionQuantityLimits size={22} color="#fff" />
                <span>Produtos</span>
              </Link>
            </Tooltip>
          </li>
        )}

        {isAdmin && (
          <li>
            <Tooltip title="Categorias" placement="rightTop">
              <Link to="/categorias">
                <MdCategory size={22} color="#fff" />
                <span>Categorias</span>
              </Link>
            </Tooltip>
          </li>
        )}

        {isAdmin && (
          <li>
            <Tooltip title="Central de mensagens" placement="rightTop">
              <Link to="/central-mensagens">
                <MdOutlineMessage size={22} color="#fff" />
                <span>Central de Mensagens</span>
              </Link>
            </Tooltip>
          </li>
        )}

        {isAdmin && (
          <li>
            <Tooltip title="Depoimentos" placement="rightTop">
              <Link to="/depoimentos">
                <MdRateReview size={22} color="#fff" />
                <span>Depoimentos</span>
              </Link>
            </Tooltip>
          </li>
        )}

        {isAdmin && (
          <li>
            <Tooltip title="Corpo técnico" placement="rightTop">
              <Link to="/corpo-tecnico">
                <RiTeamFill size={22} color="#fff" />
                <span>Corpo técnico</span>
              </Link>
            </Tooltip>
          </li>
        )}

        {isAdmin && (
          <li>
            <Tooltip title="Relatórios" placement="rightTop">
              <Link to="/relatorios">
                <MdBarChart size={22} color="#fff" />
                <span>Relatórios</span>
              </Link>
            </Tooltip>
          </li>
        )}

        {isAdmin && (
          <li>
            <Tooltip title="Banners" placement="rightTop">
              <Link to="/banners">
                <MdWallpaper size={22} color="#fff" />
                <span>Banners</span>
              </Link>
            </Tooltip>
          </li>
        )}

        {isAdmin && (
          <li>
            <Tooltip title="Produtos Destaque" placement="rightTop">
              <Link to="/produtos-destaque">
                <MdOutlineTableChart size={22} color="#fff" />
                <span>Produtos Destaque</span>
              </Link>
            </Tooltip>
          </li>
        )}

        <li>
          <Tooltip title="Configurações" placement="rightTop">
            <Link to="/configuracoes">
              <MdSettings size={22} color="#fff" />
              <span>Configurações</span>
            </Link>
          </Tooltip>
        </li>

        <ToggleMenu onClick={() => setIsMenuMinimized(!isMenuMinimized)}>
          <MdMenuOpen size={22} color="#000" />
        </ToggleMenu>
      </Menu>
    </Container>
  );
}

export default Sidebar;
