import React from 'react';
import { Container } from './styles';

export function NotFound() {
  return (
    <Container>
      <h1>Não encontrado</h1>
    </Container>
  );
}
