/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Table, Empty } from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import { Container } from './styles';
import { PageTitle } from '../../components/PageTitle';

import { api } from '../../services/api';
import { Filters } from './components/Filters';
import { Loading } from '../../components/Loading';
import { CorrectionsTable } from './components/CorrectionsTable';

export function Corrections() {
  const [corrections, setCorrections] = useState([]);
  const [correctionsTotal, setCorrectionsTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  // Filters
  const [filterCorrectionStatus, setFilterCorrectionStatus] = useState('');
  const [searchWord, setSearchWord] = useState('');
  const [isSearching, setIsSearching] = useState(false);
  const [paginationPage, setPaginationPage] = useState(1);

  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  async function loadCorrections() {
    try {
      const response = await api.get('/corrections');
      const ordersFormmated = response.data.map((correction, index) => ({
        ...correction,
        key: index,
        product_id: correction.order.product.code,
        product_name: correction.order.product.name,
        correction_status: [correction.order.correction_status],
        order_id: correction.order.order_number,
        updatedFormatted: format(
          parseISO(correction.updated_at),
          'dd/MM/yyyy - H:mm:ss',
        ),
      }));

      setCorrections(ordersFormmated);
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao carregar correções');
      setLoading(false);
    }
  }

  useEffect(() => {
    loadCorrections();
  }, []);

  const searchOrders = useCallback(async () => {
    try {
      setIsSearching(true);
      setLoading(true);
      const response = await api.get(
        `/corrections/admin/search/?search_word=${searchWord}&correction_status=${filterCorrectionStatus}&start_date=${
          startDate && format(startDate, 'yyyy-MM-dd')
        }&end_date=${endDate && format(endDate, 'yyyy-MM-dd')}`,
      );

      const ordersFormmated = response.data.map((correction, index) => ({
        ...correction,
        key: index,
        product_id: correction.order.product.code,
        product_name: correction.order.product.name,
        order_id: correction.order.order_number,
        payment_status: [correction.order.payment_status],
        correction_status: [correction.order.correction_status],
        updatedFormatted: format(
          parseISO(correction.updated_at),
          'dd/MM/yyyy - H:mm:ss',
        ),
      }));

      setCorrections(ordersFormmated);
      setCorrectionsTotal(response.data.length);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
      toast.error('Erro ao buscar usuários');
    }
  }, [filterCorrectionStatus, searchWord, startDate, endDate]);

  function clearSearch() {
    setFilterCorrectionStatus('');
    setSearchWord('');
    setIsSearching(false);
    setStartDate();
    setEndDate();
    loadCorrections();
  }

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="/">Meus Recursos</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Meus Recursos"
        description="Recursos que iniciei a correção"
      />

      <Filters
        filterCorrectionStatus={filterCorrectionStatus}
        setFilterCorrectionStatus={setFilterCorrectionStatus}
        setSearchWord={setSearchWord}
        clearSearch={clearSearch}
        search={searchOrders}
        searchWord={searchWord}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
      />

      {!loading && !!corrections.length && (
        <CorrectionsTable
          corrections={corrections}
          loading={loading}
          setIsSearching
          setPaginationPage={setPaginationPage}
          paginationPage={paginationPage}
          ordersTotal={isSearching ? corrections.length : correctionsTotal}
        />
      )}

      {!loading && !corrections.length && (
        <Empty
          description="Nenhum produto foi encontrado"
          style={{ marginTop: '150px' }}
        />
      )}

      {loading && <Loading />}
    </Container>
  );
}
