/* eslint-disable react/jsx-no-bind */
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button } from 'antd';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import { BoxForm, BoxInput, ButtonContainer, Container } from './styles';
import { PageTitle } from '../../components/PageTitle';
import { api } from '../../services/api';

export function CategoriesCreate() {
  const [initialValues] = useState({
    name: '',
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  async function createProductCategorie(values) {
    setButtonLoading(true);
    try {
      const { name } = values;

      await api.post('/products/categories', {
        name,
      });

      toast.success('Categoria cadastrada com sucesso');
      setButtonLoading(false);
      navigate(-1);
    } catch (err) {
      toast.error('Erro ao cadastrar categoria');
      setButtonLoading(false);
    }
  }

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="/categorias">
          <NavLink to="/categorias">Categorias</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Criação</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle title="Cadastro de categoria" />

      <BoxForm>
        <Formik initialValues={initialValues} onSubmit={createProductCategorie}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <BoxInput>
                <label htmlFor="name">Nome</label>
                <Field id="name" type="text" name="name" placeholder="Nome" />
              </BoxInput>

              <ButtonContainer>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={buttonLoading}
                >
                  Cadastrar categoria
                </Button>
              </ButtonContainer>
            </form>
          )}
        </Formik>
      </BoxForm>
    </Container>
  );
}
