import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  padding: 20px 50px;
  width: 100%;
  max-width: 1600px;
`;

export const BoxForm = styled.div`
  background: #fff;
  padding: 40px 30px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 0px 2px;
  min-height: 200px;

  h2 {
    font-size: 18px;
    margin-bottom: 40px;
  }

  &FormButton {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

export const BoxInput = styled.div`
  display: flex;
  margin-bottom: 20px;

  label {
    position: relative;
    display: inline-flex;
    align-items: center;
    max-width: 100%;
    height: 32px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    margin-right: 20px;
    width: 100px;
  }

  :global(#background) {
    display: none;
  }

  input {
    box-sizing: border-box;
    margin: 0;
    padding: 7px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
      helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
      segoe ui emoji, segoe ui symbol, noto color emoji;
    position: relative;
    display: inline-block;
    width: 300px;
    min-width: 0;
    background-color: #fff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
  }

  textarea {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
      helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
      segoe ui emoji, segoe ui symbol, noto color emoji;
    position: relative;
    display: inline-block;
    width: 500px;
    min-width: 0;
    background-color: #fff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
  }
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 60px;
`;
