/* eslint-disable no-return-assign */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useRef } from 'react';

import ReactToPrint from 'react-to-print';
import { Button } from 'antd';
import { ComponentToPrint } from './ComponentToPrint';

export function QuestionsPrint({ questionsAnswer, order }) {
  const componentRef = useRef();

  console.log({ questionsAnswer });
  return (
    <div style={{ textAlign: 'center', marginBottom: 15 }}>
      <ReactToPrint
        trigger={() => <Button type="link">Visualizar PDF</Button>}
        content={() => componentRef.current}
      />

      <ComponentToPrint
        correction={questionsAnswer}
        order={order}
        ref={componentRef}
      />
    </div>
  );
}
