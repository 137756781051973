import { Alert } from 'antd';
import React, { useMemo } from 'react';

export function CorrectionAlerts({ order }) {
  const isCorrectionStarded = useMemo(() => {
    if (order && order.correction_status === 'STARTED') {
      return true;
    }

    return false;
  }, [order]);

  const isCorrectionFinish = useMemo(() => {
    if (order && order.correction_status === 'COMPLETED') {
      return true;
    }

    return false;
  }, [order]);

  const isCorrectionUnaffordable = useMemo(() => {
    if (order && order.correction_status === 'UNAFFORDABLE') {
      return true;
    }

    return false;
  }, [order]);

  const isCorrectionCoordinatorRevision = useMemo(() => {
    if (order && order.correction_status === 'COORDINATOR_REVISION') {
      return true;
    }

    return false;
  }, [order]);

  return (
    <>
      {isCorrectionStarded && (
        <Alert
          message="Atenção"
          description="A correção está em produção pelo especialista"
          type="warning"
          showIcon
          closable
          style={{ marginBottom: 40 }}
        />
      )}

      {isCorrectionFinish && (
        <Alert
          message="Atenção"
          description="A correção foi finalizada"
          type="success"
          showIcon
          closable
          style={{ marginBottom: 40 }}
        />
      )}

      {isCorrectionUnaffordable && (
        <Alert
          message="Recurso incabível"
          description="Esse recurso foi dado com incabível"
          type="warning"
          showIcon
          closable
          style={{ marginBottom: 40 }}
        />
      )}

      {isCorrectionCoordinatorRevision && (
        <Alert
          message="Atenção"
          description="A correção esta sendo revisada pelo coordenador"
          type="warning"
          showIcon
          closable
          style={{ marginBottom: 40 }}
        />
      )}
    </>
  );
}
