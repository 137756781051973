import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  padding: 20px 50px;
  width: 100%;
  max-width: 1600px;
  margin-bottom: 80px;
`;

export const Cards = styled.div`
  display: flex;
`;

export const Card = styled.div`
  background: #fff;
  border-radius: 5px;
  margin-right: 30px;
  padding: 15px 20px;
  max-width: 327.5;
  width: 100%;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;

  &.captured {
    border-left: 2px solid #ff9398;

    .icon {
      background: #fff0f0;
    }
  }

  &.fatured {
    border-left: 2px solid #8cbfad;

    .icon {
      background: #dbf4eb;
    }
  }

  &.orders {
    border-left: 2px solid #a59761;

    .icon {
      background: #f8f7f3;
    }
  }

  &.corrections {
    border-left: 2px solid #95c1d9;

    .icon {
      background: #ebf3f9;
    }
  }

  > div {
    display: flex;
    align-items: center;
    margin-bottom: 15px;

    span {
      color: #525152;
    }

    .icon {
      margin-right: 15px;
      width: 35px;
      height: 35px;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  strong {
    font-size: 26px;
  }
`;

export const Row = styled.div`
  display: flex;
  margin-top: 70px;
`;

export const BoxDoughnut = styled.div`
  background: #fff;
  border-radius: 10px;
  width: 35%;
  margin-right: 40px;
  padding: 20px;

  span {
    font-size: 14px;
    margin-bottom: 15px;
    color: #525152;
    display: inline-block;
  }
`;

export const BoxLine = styled.div`
  background: #fff;
  border-radius: 10px;
  width: 65%;
  padding: 20px;

  span {
    font-size: 14px;
    margin-bottom: 15px;
    color: #525152;
    display: inline-block;
  }
`;

export const BoxBar = styled.div`
  background: #fff;
  border-radius: 10px;
  width: 100%;
  padding: 20px;

  span {
    font-size: 14px;
    margin-bottom: 15px;
    color: #525152;
    display: inline-block;
  }
`;
