import { Spin } from 'antd';
import React from 'react';

import styles from './Loading.module.scss';

export function Loading() {
  return (
    <div className={styles.container}>
      <Spin tip="Carregando..." size="large" />
    </div>
  );
}
