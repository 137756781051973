/* eslint-disable prettier/prettier */
/* eslint-disable indent */
/* eslint-disable react/jsx-curly-newline */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import PinchZoomPan from 'react-responsive-pinch-zoom-pan';
import { Button } from 'antd';
import ReactInputMask from 'react-input-mask';
import { format } from 'date-fns';
import { MdOutlineCloudDone } from 'react-icons/md';
import LazyLoad from 'react-lazy-load';
import { BoxInput, Container, QuestionFiles, QuestionHeader } from './styles';

export function QuestionForm({
  current,
  handleQuestionResourceChange,
  isCorrectionBlocked,
  handleQuestionScoreChange,
  handleQuestionObservationChange,
  handleQuestionClientScoreChange,
  automaticSaveDate,
  automaticSaveDateLoading,
  isRevisionStep,
  currentQuestionAnswer,
  handleSaveQuestionAnswer,
}) {
  const [questionFileSelected, setQuestionFileSelected] = useState();

  const isQuestionFileSelectedPDF = useMemo(
    () =>
      questionFileSelected && questionFileSelected.split('.').pop() === 'pdf',
    [questionFileSelected],
  );

  useEffect(() => {
    if (
      currentQuestionAnswer &&
      currentQuestionAnswer.product_files_mirror.length
    ) {
      setQuestionFileSelected(
        currentQuestionAnswer.product_files_mirror[0].file_url,
      );

      return;
    }

    if (
      currentQuestionAnswer &&
      currentQuestionAnswer.product_files_no_question.length
    ) {
      setQuestionFileSelected(
        currentQuestionAnswer.product_files_no_question[0].file_url,
      );

      return;
    }

    if (
      currentQuestionAnswer &&
      currentQuestionAnswer.product_question_files.length
    ) {
      setQuestionFileSelected(
        currentQuestionAnswer.product_question_files[0].file_url,
      );
    }
  }, [currentQuestionAnswer]);

  const questionResourceLimiteCharacter = useCallback(
    (subquestionIndex) => {
      if (currentQuestionAnswer && !isRevisionStep) {
        const productQuestionHasSubquestions =
          currentQuestionAnswer.subquestions.length;

        const questionFormSelected = productQuestionHasSubquestions
          ? currentQuestionAnswer.subquestions[subquestionIndex]
          : currentQuestionAnswer;

        const introductionText = `Ante o exposto, requer seja atribuída nota ${questionFormSelected.answer_score}`;

        const resourceLenth = questionFormSelected.answer_resource
          ? questionFormSelected.answer_resource.length +
          introductionText.length
          : 0;

        return resourceLenth;
      }

      return 0;
    },
    [currentQuestionAnswer, current],
  );

  const isCurrentQuestionCharacterLimitOver = useCallback(
    (subquestionIndex) => {
      if (!isRevisionStep && currentQuestionAnswer) {
        const productQuestionHasSubquestions =
          currentQuestionAnswer.subquestions.length;

        const questionFormSelected = productQuestionHasSubquestions
          ? currentQuestionAnswer.subquestions[subquestionIndex]
          : currentQuestionAnswer;

        const introductionText = `Ante o exposto, requer seja atribuída nota ${questionFormSelected.answer_score}`;

        const fullTextLength =
          questionFormSelected.answer_resource.length + introductionText.length;

        return fullTextLength > questionFormSelected.question_character_limit;
      }

      return false;
    },
    [current, currentQuestionAnswer],
  );

  const isCurrentQuestionScoreOver = useCallback(
    (subquestionIndex) => {
      // Valida subquestões
      if (!isRevisionStep && currentQuestionAnswer) {
        const productQuestionHasSubquestions =
          currentQuestionAnswer.subquestions.length;

        const questionFormSelected = productQuestionHasSubquestions
          ? currentQuestionAnswer.subquestions[subquestionIndex]
          : currentQuestionAnswer;

        return (
          Number(questionFormSelected.answer_score) >
          questionFormSelected.question_score ||
          Number(questionFormSelected.answer_score) <
          questionFormSelected.answer_client_score
        );
      }

      return false;
    },
    [current, currentQuestionAnswer, isRevisionStep],
  );

  const isCurrentQuestionClientScoreOver = useCallback(
    (subquestionIndex) => {
      // Valida subquestões
      if (!isRevisionStep && currentQuestionAnswer) {
        const productQuestionHasSubquestions =
          currentQuestionAnswer.subquestions.length;

        const questionFormSelected = productQuestionHasSubquestions
          ? currentQuestionAnswer.subquestions[subquestionIndex]
          : currentQuestionAnswer;

        return (
          Number(questionFormSelected.answer_client_score) >
          questionFormSelected.question_score
        );
      }

      return false;
    },
    [current, currentQuestionAnswer, isRevisionStep],
  );

  return (
    <Container>
      {currentQuestionAnswer && (
        <QuestionHeader>
          <div className="info">
            <strong>{currentQuestionAnswer.question_title}</strong>

            {!currentQuestionAnswer.subquestions.length && (
              <>
                <p>
                  Limite de caracteries:
                  {` ${currentQuestionAnswer.question_character_limit}`}
                </p>
                <p>
                  Nota:
                  {` ${currentQuestionAnswer.question_score}`}
                </p>
              </>
            )}
          </div>

          {!isCorrectionBlocked && (
            <div className="automatic-save">
              {automaticSaveDateLoading && <span>Salvando...</span>}

              {!automaticSaveDateLoading && (
                <>
                  <MdOutlineCloudDone size={18} color="#000000c4" />
                  {`Alterações salvas em ${automaticSaveDate
                    ? format(automaticSaveDate, 'dd/MM/yyyy - H:mm:ss')
                    : ''
                    }`}

                  <Button
                    loading={automaticSaveDateLoading}
                    style={{ marginLeft: 20 }}
                    onClick={handleSaveQuestionAnswer}
                  >
                    Salvar Questão
                  </Button>
                </>
              )}
            </div>
          )}
        </QuestionHeader>
      )}

      {/* Renderiza Questões */}
      {currentQuestionAnswer && !currentQuestionAnswer.subquestions.length && (
        <div className="forms">
          <div className="left-content">
            <QuestionFiles>
              <ul>
                {/* Arquivos espelho */}
                {!!currentQuestionAnswer.product_files_mirror.length &&
                  currentQuestionAnswer.product_files_mirror.map(
                    (questionFile, index) => (
                      <li key={index}>
                        <Button
                          type="link"
                          onClick={() =>
                            setQuestionFileSelected(questionFile.file_url)
                          }
                        >
                          {`Espelho ${index + 1}`}
                        </Button>
                      </li>
                    ),
                  )}

                {/* Arquivos sem atribuição a uma questão */}
                {!!currentQuestionAnswer.product_files_no_question.length &&
                  currentQuestionAnswer.product_files_no_question.map(
                    (questionFile, index) => (
                      <li>
                        <Button
                          type="link"
                          onClick={() =>
                            setQuestionFileSelected(questionFile.file_url)
                          }
                        >
                          {`Arquivo ${index + 1}`}
                        </Button>
                      </li>
                    ),
                  )}

                {/* Arquivos atribuido a uma questão */}
                {!!currentQuestionAnswer.product_question_files.length &&
                  currentQuestionAnswer.product_question_files.map(
                    (questionFile, index) => (
                      <li>
                        <Button
                          type="link"
                          onClick={() =>
                            setQuestionFileSelected(questionFile.file_url)
                          }
                        >
                          {`Arquivo ${index + 1}`}
                        </Button>
                      </li>
                    ),
                  )}

                {!currentQuestionAnswer.product_files_mirror.length &&
                  !currentQuestionAnswer.product_files_no_question.length &&
                  !currentQuestionAnswer.product_question_files.length && (
                    <span>Nenhum arquivo enviado</span>
                  )}
              </ul>

              <div className="visualization">
                {questionFileSelected &&
                  (isQuestionFileSelectedPDF ? (
                    <LazyLoad>
                      <iframe
                        title="pdf"
                        src={questionFileSelected}
                        width="100%"
                        height="800"
                      />
                    </LazyLoad>
                  ) : (
                    <PinchZoomPan
                      maxScale={5}
                      zoomButtons={false}
                      doubleTapBehavior="zoom"
                    >
                      <img src={questionFileSelected} alt="generic" />
                    </PinchZoomPan>
                  ))}
              </div>
            </QuestionFiles>
          </div>

          <div className="rigth-content">
            <BoxInput
              isCharacterLimitOver={isCurrentQuestionCharacterLimitOver()}
            >
              <label htmlFor="description">Recurso da Questão</label>
              <textarea
                value={currentQuestionAnswer.answer_resource}
                onChange={handleQuestionResourceChange}
                disabled={isCorrectionBlocked}
                style={{ height: 780 }}
              />

              <div className="question-footer">
                <p>{`Ante o exposto, requer seja atribuída nota ${currentQuestionAnswer.answer_score}`}</p>
                <span>
                  {`${questionResourceLimiteCharacter()}/${currentQuestionAnswer.question_character_limit
                    }`}
                </span>
              </div>
            </BoxInput>

            <BoxInput isCharacterLimitOver={isCurrentQuestionClientScoreOver()}>
              <label htmlFor="client_score">Nota atribuída pela banca</label>
              <ReactInputMask
                mask="999.999"
                maskChar="0"
                type="text"
                value={currentQuestionAnswer.answer_client_score}
                onChange={handleQuestionClientScoreChange}
                disabled={isCorrectionBlocked}
              />
            </BoxInput>

            <BoxInput isCharacterLimitOver={isCurrentQuestionScoreOver()}>
              <label htmlFor="description">Nota Requerida</label>
              <ReactInputMask
                mask="999.999"
                maskChar="0"
                type="text"
                value={currentQuestionAnswer.answer_score}
                onChange={handleQuestionScoreChange}
                disabled={isCorrectionBlocked}
              />
            </BoxInput>

            <BoxInput>
              <label htmlFor="description">Observação para o candidato</label>
              <textarea
                value={currentQuestionAnswer.answer_observation}
                onChange={handleQuestionObservationChange}
                disabled={isCorrectionBlocked}
              />
            </BoxInput>
          </div>
        </div>
      )}

      {/* Renderiza Subquestões */}
      {currentQuestionAnswer &&
        !!currentQuestionAnswer.subquestions.length &&
        currentQuestionAnswer.subquestions.map(
          (subquestionItem, subquestionIndex) => (
            <>
              <QuestionHeader>
                <div className="info">
                  <strong>{subquestionItem.question_title}</strong>
                  <p>
                    Limite de caracteries:
                    {` ${subquestionItem.question_character_limit}`}
                  </p>
                  <p>
                    Nota:
                    {` ${subquestionItem.question_score}`}
                  </p>
                </div>
              </QuestionHeader>

              <div className="forms">
                <div className="left-content">
                  <QuestionFiles>
                    <ul>
                      {/* Arquivos espelho */}
                      {!!currentQuestionAnswer.product_files_mirror.length &&
                        currentQuestionAnswer.product_files_mirror.map(
                          (questionFile, index) => (
                            <li key={index}>
                              <Button
                                type="link"
                                onClick={() =>
                                  setQuestionFileSelected(questionFile.file_url)
                                }
                              >
                                {`Espelho ${index + 1}`}
                              </Button>
                            </li>
                          ),
                        )}

                      {/* Arquivos sem atribuição a uma questão */}
                      {!!currentQuestionAnswer.product_files_no_question
                        .length &&
                        currentQuestionAnswer.product_files_no_question.map(
                          (questionFile, index) => (
                            <li>
                              <Button
                                type="link"
                                onClick={() =>
                                  setQuestionFileSelected(questionFile.file_url)
                                }
                              >
                                {`Arquivo ${index + 1}`}
                              </Button>
                            </li>
                          ),
                        )}

                      {/* Arquivos atribuido a uma questão */}
                      {!!currentQuestionAnswer.product_question_files.length &&
                        currentQuestionAnswer.product_question_files.map(
                          (questionFile, index) => (
                            <li>
                              <Button
                                type="link"
                                onClick={() =>
                                  setQuestionFileSelected(questionFile.file_url)
                                }
                              >
                                {`Arquivo ${index + 1}`}
                              </Button>
                            </li>
                          ),
                        )}

                      {!currentQuestionAnswer.product_files_mirror.length &&
                        !currentQuestionAnswer.product_files_no_question
                          .length &&
                        !currentQuestionAnswer.product_question_files
                          .length && <span>Nenhum arquivo enviado</span>}
                    </ul>

                    <div className="visualization">
                      {questionFileSelected &&
                        (isQuestionFileSelectedPDF ? (
                          <LazyLoad>
                            <iframe
                              title="pdf"
                              src={questionFileSelected}
                              width="100%"
                              height="800"
                            />
                          </LazyLoad>
                        ) : (
                          <PinchZoomPan
                            maxScale={5}
                            zoomButtons={false}
                            doubleTapBehavior="zoom"
                          >
                            <img src={questionFileSelected} alt="generic" />
                          </PinchZoomPan>
                        ))}
                    </div>
                  </QuestionFiles>
                </div>

                <div className="rigth-content">
                  <BoxInput
                    isCharacterLimitOver={isCurrentQuestionCharacterLimitOver(
                      subquestionIndex,
                    )}
                  >
                    <label htmlFor="description">Recurso da Questão</label>
                    <textarea
                      value={
                        currentQuestionAnswer.subquestions[subquestionIndex]
                          .answer_resource
                      }
                      onChange={(event) =>
                        handleQuestionResourceChange(event, subquestionIndex)
                      }
                      disabled={isCorrectionBlocked}
                      style={{ height: 780 }}
                    />

                    <div className="question-footer">
                      <p>{`Ante o exposto, requer seja atribuída nota ${currentQuestionAnswer.subquestions[subquestionIndex].answer_score}`}</p>
                      <span>
                        {`${questionResourceLimiteCharacter(
                          subquestionIndex,
                        )}/${currentQuestionAnswer.subquestions[subquestionIndex]
                          .question_character_limit
                          }`}
                      </span>
                    </div>
                  </BoxInput>

                  <BoxInput
                    isCharacterLimitOver={isCurrentQuestionClientScoreOver(
                      subquestionIndex,
                    )}
                  >
                    <label htmlFor="client_score">
                      Nota atribuída pela banca
                    </label>
                    <ReactInputMask
                      mask="999.999"
                      maskChar="0"
                      type="text"
                      value={
                        currentQuestionAnswer.subquestions[subquestionIndex]
                          .answer_client_score
                      }
                      onChange={(event) =>
                        handleQuestionClientScoreChange(event, subquestionIndex)
                      }
                      disabled={isCorrectionBlocked}
                    />
                  </BoxInput>

                  <BoxInput
                    isCharacterLimitOver={isCurrentQuestionScoreOver(
                      subquestionIndex,
                    )}
                  >
                    <label htmlFor="description">Nota Requerida</label>
                    <ReactInputMask
                      mask="999.999"
                      maskChar="0"
                      type="text"
                      value={
                        currentQuestionAnswer.subquestions[subquestionIndex]
                          .answer_score
                      }
                      onChange={(event) =>
                        handleQuestionScoreChange(event, subquestionIndex)
                      }
                      disabled={isCorrectionBlocked}
                    />
                  </BoxInput>

                  <BoxInput>
                    <label htmlFor="description">
                      Observação para o candidato
                    </label>
                    <textarea
                      value={
                        currentQuestionAnswer.subquestions[subquestionIndex]
                          .answer_observation
                      }
                      onChange={(event) =>
                        handleQuestionObservationChange(event, subquestionIndex)
                      }
                      disabled={isCorrectionBlocked}
                    />
                  </BoxInput>
                </div>
              </div>
            </>
          ),
        )}
    </Container>
  );
}
