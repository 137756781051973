import styled from 'styled-components/macro';

export const Container = styled.div`
  background: #fff;
  padding: 40px 30px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 0px 2px;
  min-height: 400px;

  h3 {
    margin-bottom: 15px;
  }

  .ant-table-wrapper {
    margin-top: 50px;
  }

  .css-b62m3t-container,
  .css-b62m3t-container {
    width: 100%;
    max-width: 900px;
  }
`;

export const AssociationProductContentSelect = styled.div`
  display: flex;
  align-items: center;
`;
