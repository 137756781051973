import { Button, Table, Tag, Tooltip } from 'antd';
import React from 'react';
import { MdOutlineNotStarted } from 'react-icons/md';

import { getOrderCorrectionStatusColor } from '../../../../utils/getOrderCorrectionStatusColor';
import { getOrderCorrectionStatus } from '../../../../utils/getOrderCorrectionStatus';
import { Pagination } from './styles';

export function ProofreaderOrdersTable({
  orders,
  loading,
  paginationPage,
  setPaginationPage,
  ordersTotal,
  isSearching,
  setOrderToStartCorrection,
  setIsOpenModalStartCorrection,
  searchPaginationPage,
  setSearchPaginationPage,
}) {
  const columns = [
    {
      title: 'Id',
      dataIndex: 'product_id',
      key: '1',
      sorter: (a, b) => a.product_id - b.product_id,
    },
    {
      title: 'Produto',
      dataIndex: 'product_name',
      key: '2',
    },
    {
      title: 'Pedido',
      dataIndex: 'order_id',
      key: '3',
      sorter: (a, b) => a.order_number - b.order_number,
    },
    {
      title: 'Data da Compra',
      dataIndex: 'createdFormatted',
      key: '4',
    },
    {
      title: 'Correção',
      key: 'correction_status',
      dataIndex: 'correction_status',
      render: (_, { correction_status }) => (
        <>
          {correction_status.map((tag) => (
            <Tag color={getOrderCorrectionStatusColor(tag)} key="nice">
              {getOrderCorrectionStatus(tag)}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (order) => (
        <div>
          <Tooltip title="Iniciar correção">
            <Button
              onClick={() => {
                setIsOpenModalStartCorrection(true);
                setOrderToStartCorrection(order);
              }}
              icon={<MdOutlineNotStarted size={20} color="#fff" />}
              style={{ background: '#1677ff' }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={orders}
        loading={loading}
        footer={() => `${orders.length} pedidos`}
        pagination={false}
      />

      {!isSearching && (
        <Pagination
          onChange={setPaginationPage}
          current={paginationPage}
          total={ordersTotal}
          showSizeChanger={false}
          defaultPageSize={20}
        />
      )}

      {isSearching && (
        <Pagination
          onChange={setSearchPaginationPage}
          current={searchPaginationPage}
          total={ordersTotal}
          showSizeChanger={false}
          defaultPageSize={20}
        />
      )}
    </>
  );
}
