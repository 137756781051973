import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  margin-bottom: 50px;
`;

export const BoxInput = styled.div`
  display: flex;
  margin-bottom: 20px;
  flex: 1;

  input {
    box-sizing: border-box;
    margin: 0;
    padding: 7px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
      helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
      segoe ui emoji, segoe ui symbol, noto color emoji;
    position: relative;
    display: flex;
    width: 100%;
    min-width: 0;
    background-color: #fff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
    margin-right: 20px;
  }
`;

export const BoxSelect = styled.div``;
