import styled from 'styled-components/macro';

export const Container = styled.div`
  background: #1a1a1a;
  font-family: 'Comfortaa';
  padding-bottom: 100px;
`;

export const ContainerObservetion = styled.div`
  background: #7f7f7f;
  font-family: 'Comfortaa';
  padding-bottom: 100px;
`;

export const Content = styled.div`
  width: 490px;
  margin: 0 auto;
`;

export const Logo = styled.div`
  padding-top: 70px;
  padding-bottom: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 248px;
  }
`;

export const Info = styled.div`
  margin-top: 25px;
  min-height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
  width: 490px;
  margin: 0 auto;
  border: 2px dashed #fac623;
  border-radius: 10px;

  &.observation {
    border-color: #1a1a1a;
  }

  .text {
    color: #fff;
    flex: 1;
    text-align: center;

    strong {
      font-weight: normal;
      font-size: 11px;
    }

    p {
      font-size: 8px;
      margin-top: 10px !important;
      display: inline-block;
      max-width: 600px;
    }
  }
`;

export const ClientInfo = styled.div`
  margin-top: 50px;

  > div {
    display: flex;

    &:last-child {
      border: none;
    }

    strong {
      width: 70px;
      min-width: 70px;
      height: 23px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: #fac623;
      padding: 6px 16px 6px 16px;
      border-radius: 5px;
      font-size: 10px;
      margin-right: 5px;
      margin-bottom: 5px;
    }

    p {
      width: 70px;
      height: 23px;
      padding: 6px 16px 6px 16px;
      border-radius: 5px;
      background: #faf8f2;
      width: 100%;
      font-size: 10px;
    }
  }
`;

export const Questions = styled.div`
  margin-top: 25px;
`;

export const Question = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  span {
    background: #fac623;
    padding: 6px 16px 6px 16px;
    font-size: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
    min-height: 23px;
    display: inline;
    align-self: flex-start;
  }

  strong {
    text-align: center;
    width: 100%;
    background: #fac623;
    padding: 6px 16px 6px 16px;
    min-height: 23px;
    font-size: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  div {
    font-size: 10px;
    padding: 6px 16px 6px 16px;
    background: #faf8f2;
    line-height: 26px;
    border-radius: 5px;

    span {
      display: inline-block;
      width: 100%;
      border-top: 1px solid rgb(0 0 0 / 6%);
      font-size: 7px;
      font-weight: 700;
    }
  }
`;

export const Scores = styled.div`
  margin-top: 40px;
  margin-bottom: 40px;

  div {
    display: flex;

    > span {
      font-size: 10px;
      flex: 1;
      padding: 20px;
      height: 23px;
      padding: 6px 16px 6px 16px;
      background: #f8c523;
      margin-right: 8px;
      border-radius: 5px;
    }

    > p {
      font-size: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100px;
      border-radius: 5px;
      height: 23px;
      background: #faf8f2;
    }

    &:first-child {
      border-bottom: none;
      margin-bottom: 8px;

      span {
        color: #fff;
        border: 2px dashed #fac623;
        background: none;
      }
    }
  }
`;

export const Footer = styled.div`
  padding: 20px;
  margin-top: 35px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border: 2px dashed #fac623;
  border-radius: 8px;

  .text {
    padding: 0 15px;

    p {
      line-height: 10px;
      font-size: 8px;
      color: #fff;
      font-weight: 700;

      & + p {
        margin-top: 20px !important;
      }
    }
  }
`;

export const QuestionObservetions = styled.div`
  margin-top: 25px;
`;

export const QuestionObservetion = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;

  strong {
    text-align: center;
    width: 100%;
    background: #fac623;
    padding: 6px 16px 6px 16px;
    min-height: 23px;
    font-size: 10px;
    border-radius: 5px;
    margin-bottom: 5px;
  }

  div {
    font-size: 10px;
    padding: 6px 16px 6px 16px;
    background: #faf8f2;
    line-height: 26px;
    border-radius: 5px;

    span {
      svg {
        margin-right: 10px;
      }

      display: flex;
      align-items: center;
      width: 100%;
      border-top: 1px solid rgb(0 0 0 / 6%);
      font-size: 7px;
      font-weight: 700;
    }
  }
`;

export const Title = styled.div`
  margin-bottom: 20px;

  h3 {
    color: #fac623;
    font-size: 36px;
    text-align: center;
  }
`;

export const QuestionWithSubquestions = styled.div`
  margin-bottom: 25px;

  strong {
    display: block;
    text-align: center;
    width: 100%;
    background: #fac623;
    padding: 6px 16px 6px 16px;
    min-height: 23px;
    font-size: 10px;
    border-radius: 5px;
    margin-bottom: 10px;
  }

  .subquestions {
    display: flex;
    flex-direction: column;

    .subquestion {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;

      span {
        background: #fac623;
        padding: 6px 16px 6px 16px;
        font-size: 10px;
        border-radius: 5px;
        margin-bottom: 5px;
        min-height: 23px;
        display: inline;
        align-self: flex-start;
        margin-bottom: 5px;
      }

      div {
        font-size: 10px;
        padding: 6px 16px 6px 16px;
        background: #faf8f2;
        line-height: 26px;
        border-radius: 5px;

        span {
          display: inline-block;
          width: 100%;
          border-top: 1px solid rgb(0 0 0 / 6%);
          font-size: 7px;
          font-weight: 700;
        }
      }
    }
  }
`;
