import React from 'react';

import { Button, Dropdown } from 'antd';
import { MdKeyboardArrowDown } from 'react-icons/md';

import { BoxInput, Container } from './styles';

const userNames = {
  admin: 'Admin',
  master: 'Admin Master',
  proofreader: 'Especialista',
  coordinator: 'Coordenador',
};

export function Filters({ setUserType, userType, setSearchWord, searchUser }) {
  function changeUserType(type) {
    setUserType(type);
    searchUser(type);
  }

  const items = [
    {
      key: '1',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => changeUserType('')}
        >
          Todos
        </Button>
      ),
    },

    {
      key: '2',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => changeUserType('proofreader')}
        >
          Especialista
        </Button>
      ),
    },
    {
      key: '3',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => changeUserType('coordinator')}
        >
          Coordenador
        </Button>
      ),
    },
    {
      key: '4',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => changeUserType('admin')}
        >
          Admin
        </Button>
      ),
    },
  ];

  return (
    <Container>
      <BoxInput>
        <input
          id="search"
          type="text"
          name="search"
          placeholder="Buscar"
          onChange={(event) => setSearchWord(event.target.value)}
          onKeyDown={(event) => event.key === 'Enter' && searchUser()}
        />
      </BoxInput>

      <Dropdown menu={{ items }} placement="bottomLeft">
        <Button
          style={{
            width: 260,
            height: 38,
            display: 'flex',
            alignItems: 'center',
          }}
          icon={<MdKeyboardArrowDown size={20} />}
        >
          <strong style={{ marginRight: 5 }}>Tipo de Usuário: </strong>
          <p>{userType ? `  ${userNames[userType]}` : '  Todos'}</p>
        </Button>
      </Dropdown>
    </Container>
  );
}
