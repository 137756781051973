/* eslint-disable consistent-return */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-bind */
/* eslint-disable react/jsx-wrap-multilines */
/* eslint-disable react/jsx-closing-tag-location */
import React, { useState, useEffect, useMemo } from 'react';
import {
  Breadcrumb,
  Button,
  FloatButton,
  Modal,
  Popconfirm,
  Result,
  Steps,
  Timeline,
  Tooltip,
} from 'antd';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import {
  CloseSquareOutlined,
  HomeOutlined,
  InfoCircleOutlined,
  NotificationFilled,
  SendOutlined,
} from '@ant-design/icons';
import { MdChat, MdMessage } from 'react-icons/md';
import { toast } from 'react-toastify';
import { format, parseISO } from 'date-fns';
import {
  Container,
  MessageCenter,
  MessageCenterItem,
  QuestionsFooter,
  PageTitle,
  CorrectionLinks,
  BoxInput,
} from './styles';

import { api } from '../../services/api';
import { Loading } from '../../components/Loading';
import { useAuth } from '../../hooks/AuthContext';
import { QuestionForm } from './components/QuestionForm';
import { ModalCorrectionInfo } from './components/ModalCorrectionInfo';
import { ModalUnaffordableResource } from './components/ModalUnaffordableResource';
import { ModalQuestionOverScore } from './components/ModalQuestionOverScore';
import { CorrectionAlerts } from './components/CorrectionAlerts';
import { ModalQuestionOverLimitCharacters } from './components/ModalQuestionOverLimitCharacters';
import { ModalQuestionEmpty } from './components/ModalQuestionEmpty';
import { QuestionRevision } from './components/QuestionRevision';
import { CorrectionActions } from './components/CorrectionActions';

export function Correction() {
  const [order, setOrder] = useState();
  const [loading, setLoading] = useState(true);
  const { order_id } = useParams();
  const navigate = useNavigate();
  const [buttonLoading, setButtonLoading] = useState(false);
  const [questionContentLoading, setQuestionContentLoading] = useState(false);
  const [questionsAnswer, setQuestionsAnswer] = useState([]);

  const { user } = useAuth();

  // Mesangem de status
  const [isModalAddStatusMessage, setIsModalAddStatusMessage] = useState();
  const [isModalAddStatusMessageProofreader, setIsModalAddStatusMessageProofreader] = useState();
  const [statusMessage, setStatusMessage] = useState('');
  const [isModalCorrectionStatusMessages, setIsModalCorrectionStatusMessages] =
    useState(false);

  // const [questionsAnswer, setQuestionAnswer] = useState();

  // Steps
  const [current, setCurrent] = useState(0);

  // Modal recurso incabivel
  const [isModalUnaffordableOpen, setIsModalUnaffordableOpen] =
  useState(false);

  const [productQuestions, setProductQuestions] = useState([]);

  const isRevisionStep = useMemo(
    () => current === productQuestions.length - 1,
    [current, productQuestions],
  );

  const [currentQuestionAnswer, setCurrentQuestionAnswer] = useState();

  // Correction Info
  const [isModalCorrectionInfoOpen, setIsModalCorrectionInfoOpen] = useState();

  // Central de mensagens
  const [messagesCenter, setMessagesCenter] = useState([]);
  const [messageToUserSended, setMessageToUserSended] = useState(false);
  const [messageToUserSelected, setMessageToUserSelected] = useState();
  const [messageCenterLoading, setMessageCenterLoading] = useState(false);

  const [isModalOverScoreOpen, setIsModalOverScoreOpen] = useState(false);

  const [isModalLimiteCharactersOpen, setIsModalLimiteCharactersOpen] =
  useState(false);
  const [isModalQuestionEmpty, setIsModalQuestionEmpty] = useState(false);

  async function getMessagesCenter() {
    try {
      const response = await api.get('/message-center/admin');
      setMessagesCenter(response.data);
    } catch (error) {
      console.log('erro ao obter mensagens');
    }
  }

  async function sendMessageToClient() {
    try {
      setMessageCenterLoading(true);
      await api.post('/message-center/send-to-client', {
        order_id,
        message_id: messageToUserSelected,
      });
      setMessageToUserSended(true);
      setMessageCenterLoading(false);
    } catch (error) {
      setMessageCenterLoading(false);
      toast.error('Erro ao enviar mensagem');
    }
  }

  const isCurrentQuestionScoretOver = useMemo(() => {
    // Valida apenas questões
    if (!isRevisionStep && currentQuestionAnswer && !currentQuestionAnswer.subquestions.length) {
      return Number(currentQuestionAnswer.answer_score) > currentQuestionAnswer.question_score;
    }

    if (!isRevisionStep && currentQuestionAnswer && currentQuestionAnswer.subquestions.length) {
      return currentQuestionAnswer.subquestions.some(subquestionItem => (
        Number(subquestionItem.answer_score) > subquestionItem.question_score
      ));
    }

    return false;
  }, [current, currentQuestionAnswer, isRevisionStep]);

  const isCurrentQuestionClientScoretOver = useMemo(() => {
    if (!isRevisionStep && currentQuestionAnswer && !currentQuestionAnswer.subquestions.length) {
      return Number(currentQuestionAnswer.answer_client_score) >
      currentQuestionAnswer.question_score;
    }

    if (!isRevisionStep && currentQuestionAnswer && currentQuestionAnswer.subquestions.length) {
      return currentQuestionAnswer.subquestions.some(subquestionItem => (
        Number(subquestionItem.answer_client_score) >
        subquestionItem.question_score
      ));
    }

    return false;
  }, [current, isRevisionStep, currentQuestionAnswer]);

  async function loadQuestionAnswer(questionId, correctionId, orderId) {
    try {
      setQuestionContentLoading(true);
      const response = await api.get(`/corrections/product-question-answer/${questionId}?correction_id=${correctionId}&order_id=${orderId}`);

      setCurrentQuestionAnswer(response.data);
      setQuestionContentLoading(false);
    } catch (error) {
      setQuestionContentLoading(false);
      toast.error('Erro ao obter resposta da questão');
    }
  }

  async function getOrder() {
    try {
      const response = await api.get(`/orders/${order_id}`);
      setOrder(response.data);

      // Salva questões
      const questionItemsFormatted = response.data.product_questions.map((item) => ({
        key: item.id,
        title: item.title,
      }));

      questionItemsFormatted.push({
        id: 'revision',
        title: 'Revisão',
      });

      setProductQuestions(questionItemsFormatted);

      // Obtem resposta da primeira questão
      loadQuestionAnswer(
        response.data.product_questions[0].id,
        response.data.correction.id,
        response.data.id,
      );

      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error('Erro ao carregar pedido');
      setLoading(false);
    }
  }

  const isCorrectionBlocked = useMemo(() => {
    // Bloqueio coordenador
    if (user && user.permission_level === 'coordinator') {
      return order &&
      (order.correction_status === 'STARTED' ||
      order.correction_status === 'PROOFREADER_CORRECTION' ||
      order.correction_status === 'COMPLETED' ||
      order.correction_status === 'UNAFFORDABLE');
    }

    // Bloqueio especialista
    if (user && user.permission_level === 'proofreader') {
      return order &&
      (order.correction_status === 'COORDINATOR_REVISION' ||
        order.correction_status === 'COMPLETED' || order.correction_status === 'UNAFFORDABLE');
    }

    // Bloqueio usuário master
    return true;
  }, [order, user]);

  const isCorrectionInProgress = useMemo(() => {
    if (
      order &&
      (order.correction_status === 'STARTED' ||
      order.correction_status === 'IN_PROGRESS')
    ) {
      return true;
    }

    return false;
  }, [order, user]);

  const isCorrectionFinish = useMemo(() => {
    if (
      order &&
        order.correction_status === 'COMPLETED'
    ) {
      return true;
    }

    return false;
  }, [order]);

  const correctionMessages = useMemo(() => {
    if (order) {
      const response = order.correction_messages.map((messageItem) => ({
        color: 'green',
        children: (
          <>
            <strong>{messageItem.user_admin.name}</strong>
            <p>{messageItem.message}</p>
          </>
        ),
        label: format(parseISO(messageItem.created_at), 'dd/MM/yyyy - H:mm:ss'),
      }));

      return response;
    }

    return [];
  }, [order, user]);

  const isCorrectionUnaffordable = useMemo(() => {
    if (order && order.correction_status === 'UNAFFORDABLE') {
      return true;
    }

    return false;
  }, [order]);

  const isCorrectionInProgressByCoordinator = useMemo(() => {
    if (
      order &&
      order.correction_status === 'COORDINATOR_REVISION' && user.permission_level === 'coordinator'
    ) {
      return true;
    }

    return false;
  }, [order, user]);

  const isCorrectionInProgressByProofreader = useMemo(() => {
    if (
      order &&
      (order.correction_status === 'STARTED' ||
        order.correction_status === 'PROOFREADER_CORRECTION')
    ) {
      return true;
    }

    return false;
  }, [order]);

  const isAdminUserProofreader = useMemo(() => {
    if (
      user && user.permission_level === 'proofreader'
    ) {
      return true;
    }

    return false;
  }, [user]);

  /**
   * Salvamento automático das questões por minuto
   */
  const [automaticSaveDate, setAutomaticSaveDate] = useState();
  const [automaticSaveDateLoading, setAutomaticSaveDateLoading] =
    useState(false);

  async function automaticQuestionAnswerSave() {
    if ((isCorrectionInProgressByCoordinator || isCorrectionInProgressByProofreader) &&
    !isRevisionStep) {
      try {
        setAutomaticSaveDateLoading(true);
        await api.put(`/corrections/save-answer/${order.correction.id}`, {
          question_answer: currentQuestionAnswer,
        });

        setAutomaticSaveDateLoading(false);
        setAutomaticSaveDate(new Date());
      } catch (error) {
        setAutomaticSaveDateLoading(false);
        toast.error('Erro ao salvar automáticamente');
      }
    }
  }

  useEffect(() => {
    if (!isCorrectionBlocked && !isRevisionStep) {
      const intervalId = setInterval(automaticQuestionAnswerSave, 20000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [currentQuestionAnswer, isCorrectionBlocked, isRevisionStep]);

  useEffect(() => {
    getOrder();
    getMessagesCenter();
  }, []);

  async function sendCorrectionToProofreader() {
    try {
      await api.post(`/corrections/send-proofreader-revision/${order_id}`, {
        message: statusMessage,
        correction_id: order.correction && order.correction.id,
      });

      toast.success('Correção enviada com sucesso');
      navigate('/meus-recursos');
    } catch (error) {
      toast.error('Erro ao enviar correção para o especialista');
    }
  }

  async function sendCorrectionToCoordinator() {
    setButtonLoading(true);
    try {
      await api.post(`/corrections/send-coordinator-revision/${order_id}`, {
        message: statusMessage,
        correction_id: order.correction && order.correction.id,
      });

      toast.success('Correção enviada com sucesso');
      setButtonLoading(false);
      navigate('/meus-recursos');
    } catch (error) {
      toast.error('Erro ao enviar correção para o coordenador');
      setButtonLoading(false);
    }
  }

  async function finishCorrection() {
    setButtonLoading(true);
    try {
      await api.post(`/corrections/finish-correction/${order_id}`, {
        message: statusMessage,
        correction_id: order.correction.id,
      });

      toast.success('Correção finalizada com sucesso');
      setButtonLoading(false);
      navigate(-1);
    } catch (error) {
      setButtonLoading(false);
      toast.error('Erro ao finalizar correção');
    }
  }

  function questionResourceLimiteCharacterRevision({
    answer_resource,
    answer_score,
  }) {
    const introductionText = `Ante o exposto, requer seja atribuída nota ${answer_score}`;

    return answer_resource.length + introductionText.length;
  }

  const [isModalCenterMessage, setIsModalCenterMessage] = useState(false);

  const [removeCorrectionLoading, setRemoveCorrectionLoading] = useState(false);

  async function unbindCorrection() {
    setRemoveCorrectionLoading(true);
    try {
      await api.post(`/corrections/unbind/${order_id}`);
      toast.success('Correção desvilculada com sucesso');
      setRemoveCorrectionLoading(false);
      navigate(-1);
    } catch (error) {
      toast.error('Erro ao remover correção');
      setRemoveCorrectionLoading(false);
    }
  }

  const [buttonUnaffordableLoading, setButtonUnaffordableLoading] = useState(false);

  // Recurso incabível
  async function unaffordableCorrection() {
    setButtonUnaffordableLoading(true);
    try {
      await api.post(`/corrections/unaffordable/${order_id}`);
      toast.success('Recurso incabível enviado com sucesso');
      setButtonUnaffordableLoading(false);
      navigate(-1);
    } catch (error) {
      toast.error('Erro ao enviar recurso incabível');
      setButtonUnaffordableLoading(false);
    }
  }

  const [resendCorrectionToClientnLoading, setResendCorrectionToClientnLoading] = useState(false);

  async function resendCorrectionToClient() {
    setResendCorrectionToClientnLoading(true);
    try {
      await api.post(`/corrections/resend/${order_id}`);
      toast.success('Recurso reenviado com sucesso');
      setResendCorrectionToClientnLoading(false);
      navigate(-1);
    } catch (error) {
      toast.error('Erro ao reenviar recurso');
      setResendCorrectionToClientnLoading(false);
    }
  }

  function handleQuestionResourceChange(event, subquestionIndex) {
    const newCurrentQuestionAnswer = { ...currentQuestionAnswer };

    // Atualiza subquestão da questão
    if (subquestionIndex !== undefined) {
      newCurrentQuestionAnswer.subquestions[subquestionIndex].answer_resource =
        event.target.value;

      setCurrentQuestionAnswer(newCurrentQuestionAnswer);

      return;
    }

    // Atualiza  a questão
    setCurrentQuestionAnswer(prevState => ({
      ...prevState,
      answer_resource: event.target.value,
    }));
  }

  function handleQuestionObservationChange(event, subquestionIndex) {
    const newCurrentQuestionAnswer = { ...currentQuestionAnswer };

    // Atualiza subquestão da questão
    if (subquestionIndex !== undefined) {
      newCurrentQuestionAnswer.subquestions[subquestionIndex].answer_observation =
            event.target.value;

      setCurrentQuestionAnswer(newCurrentQuestionAnswer);

      return;
    }

    // Atualiza questão
    setCurrentQuestionAnswer(prevState => ({
      ...prevState,
      answer_observation: event.target.value,
    }));
  }

  function handleQuestionScoreChange(event, subquestionIndex) {
    const newCurrentQuestionAnswer = { ...currentQuestionAnswer };

    // Atualiza subquestão da questão
    if (subquestionIndex !== undefined) {
      newCurrentQuestionAnswer.subquestions[subquestionIndex].answer_score =
                event.target.value;

      setCurrentQuestionAnswer(newCurrentQuestionAnswer);

      return;
    }

    setCurrentQuestionAnswer(prevState => ({
      ...prevState,
      answer_score: event.target.value,
    }));
  }

  function handleQuestionClientScoreChange(event, subquestionIndex) {
    const newCurrentQuestionAnswer = { ...currentQuestionAnswer };

    if (subquestionIndex !== undefined) {
      newCurrentQuestionAnswer.subquestions[subquestionIndex].answer_client_score =
                event.target.value;

      setCurrentQuestionAnswer(newCurrentQuestionAnswer);

      return;
    }

    setCurrentQuestionAnswer(prevState => ({
      ...prevState,
      answer_client_score: event.target.value,
    }));
  }

  function isScoreInvalid(questionItem) {
    if (questionItem) {
      return Number(questionItem.answer_score) > questionItem.question_score ||
      Number(questionItem.answer_client_score) > questionItem.question_score ||
      !questionItem.answer_score ||
       !questionItem.answer_client_score ||
       Number(questionItem.answer_score) < Number(questionItem.answer_client_score);
    }
    return false;
  }

  function validateCorrection() {
    const hasSubquestions = questionsAnswer.some(
      (questionFormItem) => questionFormItem.subquestions.length,
    );

    // Validação de sub questões
    if (hasSubquestions) {
      // Validação limite de caracteries da correção [-]
      const findQuestionIsCharacterLimitOver = questionsAnswer.findIndex(
        (questionItem) =>
          questionItem.subquestions.some((questionFormItem) => {
            const introductionText = `Ante o exposto, requer seja atribuída nota ${questionFormItem.answer_score}`;

            const fullTextLength =
            questionFormItem.answer_resource.length + introductionText.length;

            return fullTextLength > questionFormItem.question_character_limit;
          }),
      );

      if (findQuestionIsCharacterLimitOver !== -1) {
        setCurrent(findQuestionIsCharacterLimitOver);
        setIsModalLimiteCharactersOpen(true);

        return;
      }

      // Validação questão em branco [-]
      const findQuestionItemEmpty = questionsAnswer.findIndex(
        (questionItem) => questionItem.subquestions.some((questionFormItem) =>
          !questionFormItem.answer_resource),
      );

      if (findQuestionItemEmpty !== -1) {
        setCurrent(findQuestionItemEmpty);

        setIsModalQuestionEmpty(true);

        return;
      }

      // Validação nota requerida ou nota atribuida > nota da questão [-]
      const hasSomeAnswerScoreOverScore = questionsAnswer.find(
        (questionItem) =>
          questionItem.subquestions.some((questionFormItem) => isScoreInvalid(questionFormItem)),
      );

      if (hasSomeAnswerScoreOverScore) {
        setIsModalOverScoreOpen(true);

        return;
      }

      finishCorrection();

      return;
    }

    // Validação limite de caracteries da correção
    const findQuestionIsCharacterLimitOver = questionsAnswer.findIndex(
      (questionItem) => {
        const introductionText = `Ante o exposto, requer seja atribuída nota ${questionItem.answer_score}`;

        const fullTextLength =
        questionItem.answer_resource.length + introductionText.length;

        return fullTextLength > questionItem.question_character_limit;
      },

    );

    if (findQuestionIsCharacterLimitOver !== -1) {
      setCurrent(findQuestionIsCharacterLimitOver);
      setIsModalLimiteCharactersOpen(true);

      return;
    }

    // Validação questão em branco
    const findQuestionItemEmpty = questionsAnswer.findIndex(
      (questionItem) => !questionItem.answer_resource,
    );

    if (findQuestionItemEmpty !== -1) {
      setCurrent(findQuestionItemEmpty);

      setIsModalQuestionEmpty(true);

      return;
    }

    // Validação nota requerida ou nota atribuida > nota da questão
    const hasSomeAnswerScoreOverScore = questionsAnswer.find(
      (questionItem) => isScoreInvalid(questionItem),

    );

    if (hasSomeAnswerScoreOverScore) {
      setIsModalOverScoreOpen(true);

      return;
    }

    finishCorrection();
  }

  // Carrega resposta da questão ao mudar de questão
  useEffect(() => {
    if (order && !isRevisionStep) {
      const currentQuestionId = productQuestions[current].key;

      loadQuestionAnswer(currentQuestionId, order.correction.id, order.id);
    }
  }, [current, order, isRevisionStep]);

  /**
   * Salva resposta da questão atual
   */
  async function handleSaveQuestionAnswer() {
    try {
      setQuestionContentLoading(true);
      await api.put(`/corrections/save-answer/${order.correction.id}`, {
        question_answer: currentQuestionAnswer,
      });

      setQuestionContentLoading(false);
      setAutomaticSaveDate(new Date());
    } catch (error) {
      setQuestionContentLoading(false);
      toast.error('Erro ao salvar questão atual');
    }
  }

  /**
   * Salva  respota da questão atual
   * Altera step
   */
  async function handleChangeStep(stepValue) {
    if (!isRevisionStep) {
      await handleSaveQuestionAnswer();
      setCurrent(stepValue);
    }

    setCurrent(stepValue);
  }

  /**
   * Carrega todas as questões com respostas
   */
  const [loadingContentRevision, setloadingContentRevision] = useState(false);

  async function loadQuestionsAnswer() {
    try {
      setloadingContentRevision(true);
      const response = await api.get(
        `/corrections/questions-answer/${order.correction.id}`,
      );

      setQuestionsAnswer(response.data.questions_answer);
      setloadingContentRevision(false);
    } catch (error) {
      setloadingContentRevision(false);
      toast.error('Erro ao obter revisão');
    }
  }

  useEffect(() => {
    if (isRevisionStep && order) {
      loadQuestionsAnswer();
    }
  }, [isRevisionStep]);

  const next = async () => {
    if (!isCurrentQuestionScoretOver && !isCurrentQuestionClientScoretOver) {
      const scrollTarget = document.querySelector('.ant-steps').offsetTop - 40;
      window.scrollTo(0, scrollTarget);

      await handleSaveQuestionAnswer();

      setCurrent(current + 1);

      return;
    }

    if (isCurrentQuestionScoretOver || isCurrentQuestionClientScoretOver) {
      setIsModalOverScoreOpen(true);
    }
  };

  const prev = async () => {
    await handleSaveQuestionAnswer();

    setCurrent(current - 1);
  };

  function validateCorrectionToSendRevision() {
    const hasSubquestions = questionsAnswer.some(
      (questionFormItem) => questionFormItem.subquestions.length,
    );

    // Validação de sub questões [-]
    if (hasSubquestions) {
      // Validação limite de caracteries da correção [-]
      const findQuestionIsCharacterLimitOver = questionsAnswer.findIndex(
        (questionItem) =>
          questionItem.subquestions.some((questionFormItem) => {
            const introductionText = `Ante o exposto, requer seja atribuída nota ${questionFormItem.answer_score}`;

            const fullTextLength =
            questionFormItem.answer_resource.length + introductionText.length;

            return fullTextLength > questionFormItem.question_character_limit;
          }),
      );

      if (findQuestionIsCharacterLimitOver !== -1) {
        setCurrent(findQuestionIsCharacterLimitOver);
        setIsModalLimiteCharactersOpen(true);

        return;
      }

      // Validação nota requerida ou nota atribuida > nota da questão [-]
      const hasSomeAnswerScoreOverScore = questionsAnswer.find(
        (questionItem) =>
          questionItem.subquestions.some((questionFormItem) =>
            isScoreInvalid(questionFormItem)),
      );

      if (hasSomeAnswerScoreOverScore) {
        setIsModalOverScoreOpen(true);

        return;
      }

      if (user && user.permission_level === 'proofreader') {
        return setIsModalAddStatusMessageProofreader(true);
      }

      setIsModalAddStatusMessage(true);

      return;
    }

    // Validação limite de caracteries da correção [-]
    const findQuestionIsCharacterLimitOver = questionsAnswer.findIndex(
      (questionItem) => {
        const introductionText = `Ante o exposto, requer seja atribuída nota ${questionItem.answer_score}`;

        const fullTextLength =
        questionItem.answer_resource.length + introductionText.length;

        return fullTextLength > questionItem.question_character_limit;
      },
    );

    if (findQuestionIsCharacterLimitOver !== -1) {
      setCurrent(findQuestionIsCharacterLimitOver);
      setIsModalLimiteCharactersOpen(true);

      return;
    }

    // Validação questão em branco [-]
    const findQuestionItemEmpty = questionsAnswer.findIndex(
      (questionItem) => !questionItem.answer_resource,
    );

    if (findQuestionItemEmpty !== -1) {
      setCurrent(findQuestionItemEmpty);

      setIsModalQuestionEmpty(true);
    }

    // Validação nota requerida ou nota atribuida > nota da questão [-]
    const hasSomeAnswerScoreOverScore = questionsAnswer.find(
      (questionItem) => isScoreInvalid(questionItem),

    );

    if (hasSomeAnswerScoreOverScore) {
      setIsModalOverScoreOpen(true);

      return;
    }

    if (user && user.permission_level === 'proofreader') {
      return setIsModalAddStatusMessageProofreader(true);
    }

    setIsModalAddStatusMessage(true);
  }

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>

        <Breadcrumb.Item key="/">
          <NavLink to="/minhas-correcoes">Minhas correções</NavLink>
        </Breadcrumb.Item>

        <Breadcrumb.Item key="/">Correção</Breadcrumb.Item>
      </Breadcrumb>

      <CorrectionAlerts order={order} />

      <PageTitle>
        <h2>
          {
            isAdminUserProofreader ? 'Produção do Recurso' : 'Correção de Pedido - Coordenador'
          }

        </h2>

        <div>
          {
           !isAdminUserProofreader && isCorrectionFinish && (
           <Popconfirm
             title="Reenviar recurso para o candidato"
             description="Tem certeza que deseja reenviar ?"
             onConfirm={resendCorrectionToClient}
             okText="Confirmar"
             cancelText="Não"
             loading={resendCorrectionToClientnLoading}
           >
             <Button
               style={{ marginTop: 20, marginBottom: 20, background: 'orange' }}
               type="primary"
               loading={buttonLoading}
             >
               Reeenviar
             </Button>
           </Popconfirm>
           )
          }
        </div>

      </PageTitle>

      {!loading && order && (
        <>
          <CorrectionLinks>
            <h3>Informação do Pedido</h3>
            <Button
              type="default"
              shape="round"
              icon={<InfoCircleOutlined />}
              size={22}
              onClick={setIsModalCorrectionInfoOpen}
            >
              Clique aqui
            </Button>
          </CorrectionLinks>

          <Steps
            current={current}
            items={productQuestions}
            onChange={handleChangeStep}
          />

          {/* Formulários da questão */}
          {!isRevisionStep && !questionContentLoading && (
            <QuestionForm
              automaticSaveDate={automaticSaveDate}
              automaticSaveDateLoading={automaticSaveDateLoading}
              currentQuestionAnswer={currentQuestionAnswer}
              current={current}
              isRevisionStep={isRevisionStep}
              order={order}
              isCorrectionBlocked={isCorrectionBlocked}
              handleQuestionClientScoreChange={handleQuestionClientScoreChange}
              handleQuestionResourceChange={handleQuestionResourceChange}
              handleQuestionObservationChange={handleQuestionObservationChange}
              handleQuestionScoreChange={handleQuestionScoreChange}
              handleSaveQuestionAnswer={handleSaveQuestionAnswer}
            />
          )}

          {
            questionContentLoading && (
              <Loading />
            )
          }

          {/* Revisão */}
          {!!productQuestions.length && isRevisionStep && order && (
            <QuestionRevision
              loadingContentRevision={loadingContentRevision}
              questionsAnswer={questionsAnswer}
              questionResourceLimiteCharacterRevision={questionResourceLimiteCharacterRevision}
            />
          )}

          <QuestionsFooter>
            <div style={{ marginTop: 24 }}>
              {current > 0 && current !== productQuestions.length - 1 && (
                <Button style={{ margin: '0 8px' }} onClick={() => prev()}>
                  Voltar
                </Button>
              )}

              {current < productQuestions.length - 1 && (
                <Button type="primary" onClick={() => next()}>
                  Avançar
                </Button>
              )}
            </div>
          </QuestionsFooter>

          {/* Step de revisão */}
          {isRevisionStep && (
          <CorrectionActions
            isAdminUserProofreader={isAdminUserProofreader}
            isCorrectionFinish={isCorrectionFinish}
            isCorrectionInProgress={isCorrectionInProgress}
            isCorrectionUnaffordable={isCorrectionUnaffordable}
            validateCorrection={validateCorrection}
            buttonLoading={buttonLoading}
            validateCorrectionToSendRevision={validateCorrectionToSendRevision}
            unbindCorrection={unbindCorrection}
            removeCorrectionLoading={removeCorrectionLoading}
            questionsAnswer={questionsAnswer}
            order={order}
            isCorrectionBlocked={isCorrectionBlocked}
          />
          )}
        </>
      )}

      {loading && <Loading />}

      <FloatButton.Group
        trigger="hover"
        type="primary"
        style={{ right: 24 }}
        icon={<MdChat />}
      >
        {
          !isCorrectionUnaffordable && (
            <Tooltip title="Recurso incabível" placement="left">
              <FloatButton
                onClick={() => setIsModalUnaffordableOpen(true)}
                icon={<CloseSquareOutlined />}
              />
            </Tooltip>
          )
        }

        <Tooltip title="Mensagens ao candidato" placement="left">
          <FloatButton
            onClick={() => setIsModalCenterMessage(true)}
            icon={<SendOutlined />}
          />
        </Tooltip>

        <Tooltip title="Mensagens para Equipe" placement="left">
          <FloatButton
            onClick={() => setIsModalCorrectionStatusMessages(true)}
            icon={<MdMessage />}
          />
        </Tooltip>
      </FloatButton.Group>

      {/* Modal Devolver para especialista */}
      <Modal
        title="Adicionar mensagem na correção"
        open={isModalAddStatusMessage}
        footer={null}
        onCancel={() => setIsModalAddStatusMessage(false)}
        width={700}
      >
        <BoxInput style={{ flex: 2 }}>
          <label htmlFor="message">Deixe aqui eventual observação sobre a prova ao especialista</label>
          <textarea
            value={statusMessage}
            onChange={(event) => setStatusMessage(event.target.value)}
          />
        </BoxInput>

        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Button type="primary" onClick={sendCorrectionToProofreader}>
            Devolver para o especialista
          </Button>
        </div>
      </Modal>

      {/* Modal Devolver para coordenador */}
      <Modal
        title="Adicionar mensagem na correção"
        open={isModalAddStatusMessageProofreader}
        footer={null}
        onCancel={() => setIsModalAddStatusMessageProofreader(false)}
        width={700}
      >
        <BoxInput style={{ flex: 2, width: '100%' }}>
          <label htmlFor="message">
            Deixe aqui eventual observação sobre a prova ao coordenador
          </label>
          <textarea
            value={statusMessage}
            onChange={(event) => setStatusMessage(event.target.value)}
          />
        </BoxInput>

        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Button
            type="primary"
            loading={buttonLoading}
            onClick={sendCorrectionToCoordinator}
          >
            Enviar para revisão do coordenador
          </Button>
        </div>
      </Modal>

      {/* Modal mostrando mensagens de status */}
      <Modal
        title="Mensagens de status da correção"
        open={isModalCorrectionStatusMessages}
        footer={null}
        onCancel={() => setIsModalCorrectionStatusMessages(false)}
        width={700}
      >
        <Timeline mode="left" style={{ marginTop: 40 }} items={correctionMessages} />
      </Modal>

      {/* Central de mensagens */}
      <Modal
        title="Central de mensagens"
        open={isModalCenterMessage}
        footer={null}
        onCancel={() => {
          setIsModalCenterMessage(false);
          setMessageToUserSended(false);
          setMessageToUserSelected();
        }}
        width={900}
      >
        {messageToUserSended && (
          <Result status="success" title="Mensagem enviada com sucesso" />
        )}

        {!messageToUserSended && (
          <MessageCenter>
            {messagesCenter.map((messageToUser) => (
              <MessageCenterItem
                selected={messageToUser.id === messageToUserSelected}
                onClick={() => setMessageToUserSelected(messageToUser.id)}
              >
                <div className="icon">
                  <NotificationFilled color="#EA6D01" />
                </div>
                <div className="text">
                  <strong>{messageToUser.title}</strong>
                  <p>{messageToUser.message}</p>
                </div>
              </MessageCenterItem>
            ))}
          </MessageCenter>
        )}

        {!messageToUserSended && (
          <div style={{ textAlign: 'center', marginTop: 50 }}>
            <Button
              type="primary"
              onClick={sendMessageToClient}
              loading={messageCenterLoading}
            >
              Enviar mensagem para o candidato
            </Button>
          </div>
        )}
      </Modal>

      {/* Modal informação do pedido */}
      <ModalCorrectionInfo
        isModalCorrectionInfoOpen={isModalCorrectionInfoOpen}
        setIsModalCorrectionInfoOpen={setIsModalCorrectionInfoOpen}
        order={order}
      />

      {/* Modal recruso incabível */}
      <ModalUnaffordableResource
        isModalUnaffordableOpen={isModalUnaffordableOpen}
        setIsModalUnaffordableOpen={setIsModalUnaffordableOpen}
        unaffordableCorrection={unaffordableCorrection}
        buttonUnaffordableLoading={buttonUnaffordableLoading}
      />

      {/* Modal aviso limte de nota ultrapassado */}
      <ModalQuestionOverScore
        isModalOpen={isModalOverScoreOpen}
        setIsModalOpen={setIsModalOverScoreOpen}
      />

      {/* Modal aviso limte de caracteries ultrapassado */}
      <ModalQuestionOverLimitCharacters
        isModalOpen={isModalLimiteCharactersOpen}
        setIsModalOpen={setIsModalLimiteCharactersOpen}
      />

      {/* Modal aviso quesões em branco */}
      <ModalQuestionEmpty
        isModalQuestionEmpty={isModalQuestionEmpty}
        setIsModalQuestionEmpty={setIsModalQuestionEmpty}
        setIsModalAddStatusMessage={setIsModalAddStatusMessage}
      />
    </Container>
  );
}
