import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  padding: 20px 50px;
  width: 100%;
  max-width: 1600px;
`;

export const ProductList = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

export const Product = styled.div`
  display: flex;
  flex-direction: column;
  width: 250px;
  margin-right: 20px;
  margin-bottom: 30px;
  background: #fff;
  border-radius: 5px;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
  position: relative;

  &:nth-child(4n) {
    margin-right: 0;
  }

  > div {
    padding: 5px 15px;
  }

  strong {
    font-size: 16px;
    margin-top: 10px;
    margin-bottom: 10px;
    display: inline-block;
  }

  p {
    font-size: 13px;
    color: #1c1d1f;
    margin-bottom: 10px !important;
    display: inline-block;
  }

  /* Ant Tag */
  .ant-tag-green {
    position: absolute;
    top: 10px;
  }
`;
