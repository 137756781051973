import { Avatar, Dropdown } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';
import { Container, UserAvatar } from './styles';
import { useAuth } from '../../hooks/AuthContext';

const userNames = {
  admin: 'Admin',
  master: 'Admin Master',
  proofreader: 'Especialista',
  coordinator: 'Coordenador',
};

const items = [
  {
    key: '1',
    label: <NavLink to="/logout">Sair</NavLink>,
  },
];

export function Header() {
  const { user } = useAuth();

  return (
    <Container>
      <Dropdown menu={{ items }} placement="bottom" arrow>
        <UserAvatar useType={user.permission_level}>
          <div>
            <strong>{user.name}</strong>
            <span>{userNames[user.permission_level]}</span>
          </div>
          <Avatar
            size={40}
            style={{ backgroundColor: '#fde3cf', color: '#f56a00' }}
          >
            {user.name[0]}
          </Avatar>
        </UserAvatar>
      </Dropdown>
    </Container>
  );
}
