import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Table, Tooltip, Button, Modal, Result } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdDeleteOutline, MdModeEditOutline } from 'react-icons/md';
import { Container } from './styles';
import { PageTitle } from '../../components/PageTitle';

import { api } from '../../services/api';

export function Categories() {
  const [categories, setCategories] = useState([]);

  const [categoryToRemove, setCategoryToRemove] = useState();
  const [isOpenModalDeleteCategory, setIsOpenModalDeleteCategory] =
    useState(false);

  async function loadCategories() {
    try {
      const response = await api.get('/products/categories');
      const usersFormmated = response.data.map((user, index) => ({
        ...user,
        key: index,
      }));

      setCategories(usersFormmated);
    } catch (error) {
      toast.error('Erro ao carregar categorias');
    }
  }

  useEffect(() => {
    loadCategories();
  }, []);

  const deleteCategory = useCallback(async () => {
    try {
      await api.delete(`/products/categories/${categoryToRemove.id}`);
      toast.success('Categoria removido com sucesso');
      loadCategories();
      setIsOpenModalDeleteCategory(false);
    } catch (error) {
      toast.error('Erro ao remover categoria');
    }
  }, [categoryToRemove]);

  const columns = [
    {
      title: 'Código',
      dataIndex: 'code',
      key: 'code',
      render: (value) => <span>{value.toUpperCase()}</span>,
    },
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Ações',
      key: 'action',
      render: (categorie) => (
        <div>
          <Tooltip title="Editar produto">
            <NavLink to={`/categorias/editar/${categorie.id}`}>
              <Button
                icon={<MdModeEditOutline size={20} color="#fff" />}
                style={{ background: '#1677ff' }}
              />
            </NavLink>
          </Tooltip>

          <Tooltip title="Remover produto">
            <Button
              icon={<MdDeleteOutline size={20} color="#fff" />}
              style={{ background: '#ff4d4f', marginLeft: 15 }}
              onClick={() => {
                setCategoryToRemove(categorie);
                setIsOpenModalDeleteCategory(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Categoria</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Gerenciamento de categorias"
        link="/categorias/criar"
        linkTitle="Cadastrar categoria"
      />

      <Table columns={columns} dataSource={categories} />

      <Modal
        title="Remover produto"
        open={isOpenModalDeleteCategory}
        footer={null}
        onCancel={() => setIsOpenModalDeleteCategory(false)}
        width={700}
        style={{ textAlign: 'center' }}
      >
        <Result
          status="warning"
          title={`Tem certeza que deseja remover a categoria ${
            categoryToRemove && categoryToRemove.name
          }?`}
        />

        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Button
            style={{ background: '#ff4d4f' }}
            color="green"
            type="primary"
            onClick={deleteCategory}
          >
            Remover categoria
          </Button>
        </div>
      </Modal>
    </Container>
  );
}
