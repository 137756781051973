/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-bind */
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Empty, Modal, Result } from 'antd';
import React, { useState, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import currencyFormatter from 'currency-formatter';

import { PageTitle } from '../../components/PageTitle';
import { Filters } from './components/Filters';

import { Container } from './styles';
import { api } from '../../services/api';

import { Loading } from '../../components/Loading';
import { ProductsTable } from './components/ProductsTable';

export function Products() {
  const [products, setProducts] = useState([]);
  const [productsTotal, setProductsTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  // Filters
  const [searchWord, setSearchWord] = useState('');
  const [isSearching, setIsSearching] = useState(false);

  // Arquiva produto
  const [showModalDeleteProduct, setShowModalDeleteProduct] = useState(false);
  const [productToRemove, setProductToRemove] = useState();

  const [paginationPage, setPaginationPage] = useState(1);

  async function loadProducts() {
    try {
      const response = await api.get(`/products?page=${paginationPage}`);
      const productFormatted = response.data.products.map((productItem) => ({
        ...productItem,
        price_formatted: currencyFormatter.format(productItem.price / 100, {
          code: 'BRL',
        }),
        product_type: [productItem.type],
        name_formated: productItem.name,
        product_overview_formmated:
          productItem.product_overview &&
            productItem.product_overview.length > 25
            ? `${productItem.product_overview.substring(0, 25)}...`
            : productItem.product_overview,
      }));
      setProducts(productFormatted);
      setProductsTotal(response.data.products_total);
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao carregar produtos');
      setLoading(false);
    }
  }

  useEffect(() => {
    loadProducts();
  }, []);

  useEffect(() => {
    loadProducts();
  }, [paginationPage]);

  async function searchProducts() {
    setLoading(true);
    setIsSearching(true);
    try {
      const response = await api.get(`/products?search=${searchWord}`);
      const productFormatted = response.data.map((productItem) => ({
        ...productItem,
        price_formatted: currencyFormatter.format(productItem.price / 100, {
          code: 'BRL',
        }),
        product_type: [productItem.type],
        name_formated: productItem.name,
        product_overview_formmated:
          productItem.product_overview &&
            productItem.product_overview.length > 25
            ? `${productItem.product_overview.substring(0, 25)}...`
            : productItem.product_overview,
      }));
      setProducts(productFormatted);
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao buscar produtos');
      setLoading(false);
      setIsSearching(false);
    }
  }

  const deleteProduct = useCallback(async () => {
    try {
      await api.delete(`/products/${productToRemove.id}`);
      toast.success('Produto removido com sucesso');
      loadProducts();
      setShowModalDeleteProduct(false);
    } catch (error) {
      toast.error('Erro ao remover produto');
    }
  }, [productToRemove]);

  async function clearSearch() {
    setIsSearching(false);
    setIsSearching('');
    setPaginationPage(1);
    loadProducts();
  }

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Produtos</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Gerenciamento de Produtos"
        link="/produtos/criar"
        linkTitle="Cadastrar produto"
      />

      <Filters searchProducts={searchProducts} setSearchWord={setSearchWord} clearSearch={clearSearch} />

      {!loading && (
        <ProductsTable
          products={products}
          loading={loading}
          paginationPage={paginationPage}
          setPaginationPage={setPaginationPage}
          isSearching={isSearching}
          productsTotal={productsTotal}
          setProductToRemove={setProductToRemove}
          setShowModalDeleteProduct={setShowModalDeleteProduct}
        />
      )}

      {!loading && !products.length && (
        <Empty
          description="Nenhum produto foi encontrado"
          style={{ marginTop: '150px' }}
        />
      )}

      {loading && <Loading />}

      <Modal
        title="Remover produto"
        open={showModalDeleteProduct}
        footer={null}
        onCancel={() => setShowModalDeleteProduct(false)}
        width={700}
        style={{ textAlign: 'center' }}
      >
        <Result
          status="warning"
          title={`Tem certeza que deseja remover o produto ${productToRemove && productToRemove.name}?`}
        />

        <div style={{ textAlign: 'center', marginTop: 50 }}>
          <Button
            style={{ background: '#ff4d4f' }}
            color="green"
            type="primary"
            onClick={deleteProduct}
          >
            Remover produto
          </Button>
        </div>
      </Modal>
    </Container>
  );
}
