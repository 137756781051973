/* eslint-disable react/jsx-no-bind */
import { Button, Modal, Result } from 'antd';
import React from 'react';

export function ModalQuestionOverScore({ isModalOpen, setIsModalOpen }) {
  return (
    <Modal
      title="Nota máxima ultrapassada"
      open={isModalOpen}
      footer={null}
      onCancel={() => setIsModalOpen(false)}
      width={700}
    >
      <Result
        status="warning"
        title="A nota máxima foi ultrapassada ou o os campos estão em branco, corriga o campo para continuar"
      />

      <div style={{ textAlign: 'center', marginTop: 50 }}>
        <Button type="primary" onClick={() => setIsModalOpen(false)}>
          Corrigir
        </Button>
      </div>
    </Modal>
  );
}
