/* eslint-disable react/jsx-no-bind */
import { Button, Modal, Result } from 'antd';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { BoxInput } from './styles';
import { api } from '../../../../services/api';

export function ModalOrderCancel({
  isOpenModalOrderCancel,
  setIsOpenModalOrderCancel,
  orderToCancel,
}) {
  const [orderCancelObservation, setOrderCancelObservation] = useState('');

  async function cancelOrder() {
    try {
      await api.post(`/orders/cancel/${orderToCancel.id}`);
      toast.success('Pedido cancelado com sucesso');
    } catch (error) {
      toast.error('Erro ao cancelar pedido');
    }
  }

  return (
    <Modal
      title="Cancelamento de Pedido"
      open={isOpenModalOrderCancel}
      footer={null}
      onCancel={() => setIsOpenModalOrderCancel(false)}
      width={700}
      style={{ textAlign: 'center' }}
    >
      <Result
        status="error"
        title="Tem certeza que deseja cancelar esse pedido ?"
      />

      <div style={{ fontSize: 22 }}>
        <strong>
          {`Pedido: ${orderToCancel && orderToCancel.order_number}`}
        </strong>
      </div>

      <BoxInput>
        <label>Observação</label>
        <textarea
          value={orderCancelObservation}
          onChange={(event) => setOrderCancelObservation(event.target.value)}
        />
      </BoxInput>

      <div style={{ textAlign: 'center', marginTop: 50 }}>
        <Button
          style={{ background: '#ff4d4f' }}
          color="green"
          type="primary"
          onClick={cancelOrder}
        >
          Cancelar pedido
        </Button>
      </div>
    </Modal>
  );
}
