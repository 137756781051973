import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  padding: 20px 50px;
  width: 100%;
  max-width: 1600px;
`;

export const Title = styled.div`
  margin-bottom: 30px;
  display: flex;
  justify-content: space-between;

  > div {
    display: flex;
    align-items: center;

    h2 {
      color: #334150;
      margin: 0 15px 0 0;
    }
  }

  > span {
    color: #48626f;
    font-size: 14px;
  }

  button {
    margin-left: 10px;
  }

  > button {
    span {
      color: #fff;
    }
  }
`;
