/* eslint-disable react/jsx-no-bind */
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import { BoxForm, BoxInput, ButtonContainer, Container } from './styles';
import { PageTitle } from '../../components/PageTitle';
import { api } from '../../services/api';

const { Option } = Select;

export function TestimonialCreate() {
  const [initialValues] = useState({
    name: '',
    text: '',
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [isShowHome, setIsShowHome] = useState(false);
  const [productCategoryId, setProductCategoryId] = useState('');
  const [testimonialToUpload, setTestimonialToUpload] = useState();
  const [testimonialPreview, setTestimonialPreview] = useState(null);

  const navigate = useNavigate();

  async function createTestimonials(values) {
    setButtonLoading(true);
    try {
      const formData = new FormData();
      const { name, text } = values;

      if (testimonialToUpload) {
        formData.append('file', testimonialToUpload, testimonialToUpload.name);
      }

      formData.append('name', name);
      formData.append('text', text);
      formData.append('is_show_home', isShowHome);
      formData.append('product_category_id', productCategoryId);

      await api.post('/testimonials', formData);
      toast.success('Depoimento cadastrado com sucesso');

      setButtonLoading(false);
      navigate(-1);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao cadastrar depoimento');
      setButtonLoading(false);
    }
  }

  const [productCategoryIdList, setProductCategoryIdList] = useState([]);

  async function loadProductCategories() {
    try {
      const response = await api.get('/products/categories');
      setProductCategoryIdList(response.data);
    } catch (error) {
      toast.error('Erro ao carregar categorias do produto');
    }
  }

  function handleProductCategoryId(type) {
    setProductCategoryId(type);
  }

  useEffect(() => {
    loadProductCategories();
  }, []);

  function handleTestimonialPhono(event) {
    if (event.target.files) {
      const file = event.target.files[0];
      setTestimonialToUpload(file);

      const reader = new FileReader();

      reader.onload = () => {
        setTestimonialPreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  }

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="/depoimentos">
          <NavLink to="/depoimentos">Depoimentos</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Criação</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Cadastro de usuário"
        description="Gerenciamento de coordenadores e especialistas"
      />

      <BoxForm>
        <Formik initialValues={initialValues} onSubmit={createTestimonials}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <img src={testimonialPreview} alt="" />
              <BoxInput style={{ marginTop: 30 }}>
                <label htmlFor="photo">Foto</label>
                <input
                  id="photo"
                  type="file"
                  name="photo"
                  onChange={handleTestimonialPhono}
                />
              </BoxInput>

              <BoxInput>
                <label htmlFor="name">Nome</label>
                <Field id="name" type="text" name="name" placeholder="Nome" />
              </BoxInput>

              <BoxInput>
                <label htmlFor="text">Texto</label>
                <Field name="text">
                  {({ field }) => (
                    <div>
                      <textarea {...field} value={field.value || ''} />
                    </div>
                  )}
                </Field>
              </BoxInput>

              <BoxInput>
                <label htmlFor="name">Tipo de usuário</label>
                <Select
                  defaultValue="Selecione o tipo de usuário"
                  style={{ width: 300, height: 38 }}
                  onChange={handleProductCategoryId}
                >
                  {productCategoryIdList.length &&
                    productCategoryIdList.map((categoryItem) => (
                      <Option key={categoryItem.id}>{categoryItem.name}</Option>
                    ))}
                </Select>
              </BoxInput>

              <BoxInput>
                <label htmlFor="is_show_home">Mostrar na home</label>
                <Switch
                  defaultChecked
                  checked={isShowHome}
                  onChange={() => setIsShowHome(!isShowHome)}
                />
              </BoxInput>

              <ButtonContainer>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={buttonLoading}
                >
                  Cadastrar depoimento
                </Button>
              </ButtonContainer>
            </form>
          )}
        </Formik>
      </BoxForm>
    </Container>
  );
}
