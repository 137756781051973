/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable array-callback-return */
import React, { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { QRCode } from 'antd';
import { MdOutlineError } from 'react-icons/md';

import {
  ClientInfo,
  Container,
  ContainerObservetion,
  Content,
  Footer,
  Info,
  Logo,
  Question,
  QuestionObservetion,
  QuestionObservetions,
  QuestionWithSubquestions,
  Questions,
  Scores,
  Title,
} from './styles';

import { api } from '../../services/api';

const DEFAULT_PDF_ANSWER_TEXT = 'Ante o exposto, requer seja atribuída nota';

export function CorrectionToPrint() {
  const [correction, setCorrection] = useState();
  const [order, setOrder] = useState();

  const { order_id } = useParams();

  useEffect(() => {
    const loadCorrection = async () => {
      try {
        const response = await api.get(`/orders/${order_id}`);

        const questionsAnswer = response.data.product_questions.map(
          (question) => {
            const productQuestionAnswer =
              response.data.product_questions_answer.find(
                (productQuestionAnswerItem) =>
                  productQuestionAnswerItem.product_question_id === question.id,
              );

            const subquestions = response.data.product_subquestions.filter(
              (productSubQuestionItem) =>
                productSubQuestionItem.productQuestionId === question.id,
            );

            const subquestionsWithAnswer = subquestions.map(
              (subquestionItem) => {
                const productSubquestionAnswer =
                  response.data.product_questions_answer.find(
                    (productSubquestionAnswerItem) =>
                      productSubquestionAnswerItem.product_subquestion_id ===
                      subquestionItem.id,
                  );

                const answer_resource_formatted =
                  productSubquestionAnswer &&
                  productSubquestionAnswer.answer_resource
                    ? `${productSubquestionAnswer.answer_resource} ${DEFAULT_PDF_ANSWER_TEXT} ${productSubquestionAnswer.answer_score}`
                    : 'Veja o comentário no campo de observações ao final';

                return {
                  ...subquestionItem,
                  answer_score: productSubquestionAnswer
                    ? productSubquestionAnswer.answer_score
                    : '',
                  answer_observation: productSubquestionAnswer
                    ? productSubquestionAnswer.answer_observation
                    : '',
                  answer_resource: productSubquestionAnswer
                    ? productSubquestionAnswer.answer_resource
                    : '',
                  client_score: productSubquestionAnswer
                    ? productSubquestionAnswer.client_score
                    : '',
                  answer_resource_formatted,
                };
              },
            );

            const answer_resource_formatted =
              productQuestionAnswer.answer_resource
                ? `${productQuestionAnswer.answer_resource} ${DEFAULT_PDF_ANSWER_TEXT} ${productQuestionAnswer.answer_score}`
                : 'Veja o comentário no campo de observações ao final';

            return {
              id: question.id,
              title: question.title,
              character_limit: question.character_limit,
              score: question.score,
              client_score: productQuestionAnswer.client_score,
              answer_score: productQuestionAnswer.answer_score,
              answer_observation: productQuestionAnswer.answer_observation,
              answer_resource: productQuestionAnswer.answer_resource,
              answer_resource_formatted,
              subquestions: subquestionsWithAnswer,
            };
          },
        );

        setCorrection(questionsAnswer);
        setOrder(response.data);
      } catch (error) {
        console.log(error);
        toast.error('Erro ao carregar correção');
      }
    };

    loadCorrection();
  }, []);

  const isQuestionsHasSubquestions = useMemo(() => {
    if (correction) {
      return correction.some(
        (correctionItem) => correctionItem.subquestions.length,
      );
    }

    return false;
  }, [correction]);

  const correctionScoreSum = useMemo(() => {
    if (correction) {
      if (isQuestionsHasSubquestions) {
        let totalScore = 0;

        correction.map((correctionItem) => {
          correctionItem.subquestions.map((subquestionItem) => {
            totalScore += Number(subquestionItem.client_score);
          });
        });

        return totalScore;
      }

      const correctionAnswerScoreSumCalc = correction.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.client_score),
        0,
      );

      return correctionAnswerScoreSumCalc;
    }

    return 0;
  }, [correction, isQuestionsHasSubquestions]);

  const correctionAnswerScoreSum = useMemo(() => {
    if (correction) {
      if (isQuestionsHasSubquestions) {
        let totalScore = 0;

        correction.map((correctionItem) => {
          correctionItem.subquestions.map((subquestionItem) => {
            totalScore += Number(subquestionItem.answer_score);
          });
        });

        return totalScore.toFixed(3);
      }

      const correctionAnswerScoreSumCalc = correction.reduce(
        (accumulator, currentValue) =>
          accumulator + Number(currentValue.answer_score),
        0,
      );

      return correctionAnswerScoreSumCalc.toFixed(3);
    }

    return 0;
  }, [correction, isQuestionsHasSubquestions]);

  return (
    <>
      <Container>
        <Content>
          {correction && order && (
            <>
              <Logo>
                <img
                  src="https://recursooficial.com.br/_next/image?url=%2Fimages%2Flogo.png&w=384&q=75"
                  alt="logo"
                />
              </Logo>

              <Info>
                <img
                  src="https://recursos-medicina-api.s3.amazonaws.com/assets/logo-mini.png"
                  alt="logo"
                />

                <div className="text">
                  <strong>{order.product.name}</strong>
                  <p>
                    Caro candidato, segue abaixo o seu recurso pronto para
                    copiar e colar no site da banca examinadora.
                  </p>
                </div>
              </Info>

              <ClientInfo>
                <div>
                  <strong>Nome:</strong>
                  <p>{order.client.name}</p>
                </div>

                <div>
                  <strong>Pedido:</strong>
                  <p>{`#${order.order_number}`}</p>
                </div>

                <div>
                  <strong>CPF:</strong>
                  <p>{order.client.cpf}</p>
                </div>

                <div>
                  <strong>Email:</strong>
                  <p>{order.client.email}</p>
                </div>
              </ClientInfo>

              <Questions>
                {correction.map((questionItem) =>
                  questionItem.subquestions.length ? (
                    <QuestionWithSubquestions>
                      <strong>{questionItem.title}</strong>

                      <div className="subquestions">
                        {questionItem.subquestions.map((subquestionItem) => (
                          <div className="subquestion">
                            <span>{subquestionItem.title}</span>
                            <div>
                              <p>{subquestionItem.answer_resource_formatted}</p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </QuestionWithSubquestions>
                  ) : (
                    <Question>
                      <strong>{questionItem.title}</strong>
                      <div>
                        <p>{questionItem.answer_resource_formatted}</p>
                      </div>
                    </Question>
                  ),
                )}
              </Questions>

              <Scores>
                <div>
                  <span>Nota atribuída pela banca</span>
                  <p>{correctionScoreSum}</p>
                </div>

                <div>
                  <span>Nota requerida com o recurso</span>
                  <p>{correctionAnswerScoreSum}</p>
                </div>
              </Scores>

              <Footer>
                <div>
                  <MdOutlineError color="#FAC623" size={60} />
                </div>

                <div className="text">
                  <p>
                    LEMBRE-SE! Como regra, a Recurso Oficial NÃO faz a
                    apresentação/interposição de recursos junto a Banca
                    Examinadora. Cabe ao candidato fazê-lo, nos termos do edital
                    do certame.
                  </p>

                  <p>
                    ATENÇÃO! Apenas quando disponível e contratado pelo
                    candidato, a partir de uma 2ª compra, o serviço de
                    Despachante Virtual, caberá a Recurso Oficial realizar a
                    apresentação/interposição de recursos junto a Banca
                    Examinadora, em nome dos respectivos contratantes.
                  </p>

                  <p>
                    Veja o passo a passo para interpor o seu recurso perante a
                    banca.
                  </p>
                </div>
                <div>
                  <QRCode
                    size={90}
                    value="https://blog.recursooficial.com.br/revalida-inep"
                    color="#FAC623"
                  />
                </div>
              </Footer>
            </>
          )}
        </Content>
      </Container>

      <ContainerObservetion>
        <Content>
          {correction && order && (
            <>
              <Logo>
                <img
                  src="https://recursooficial.com.br/_next/image?url=%2Fimages%2Flogo.png&w=384&q=75"
                  alt="logo"
                />
              </Logo>

              <Title>
                <h3>Anexo</h3>
              </Title>

              <Info className="observation">
                <div>
                  <MdOutlineError color="#FAC623" size={60} />
                </div>

                <div className="text">
                  <p>
                    As informações a seguir não são parte integrante do seu
                    recurso, tendo caráter meramente informativo.
                  </p>
                </div>
              </Info>

              <QuestionObservetions>
                {correction.map((questionItem) => (
                  <QuestionObservetion>
                    <strong>{`Observações ${questionItem.title}`}</strong>
                    <div>
                      <p>{questionItem.answer_observation}</p>

                      <span>
                        <MdOutlineError color="#FAC623" size={14} />
                        Atenção! Esse campo é meramente informativo e não faz
                        parte do recurso.
                      </span>
                    </div>
                  </QuestionObservetion>
                ))}
              </QuestionObservetions>
            </>
          )}
        </Content>
      </ContainerObservetion>
    </>
  );
}
