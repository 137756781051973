/* eslint-disable react/jsx-no-bind */
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Select } from 'antd';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import { BoxForm, BoxInput, ButtonContainer, Container } from './styles';
import { PageTitle } from '../../components/PageTitle';
import { api } from '../../services/api';

const { Option } = Select;
export function UsersCreate() {
  const [initialValues] = useState({
    name: '',
    description: '',
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [userType, setUserType] = useState('admin');
  const navigate = useNavigate();

  async function createUser(values) {
    setButtonLoading(true);
    try {
      const { name, email } = values;

      await api.post('/admin-users', {
        name,
        email,
        permission_level: userType,
      });
      toast.success('Admin cadastrado com sucesso');

      setButtonLoading(false);
      navigate(-1);
    } catch (err) {
      toast.error('Erro ao cadastrar usuário');
      setButtonLoading(false);
    }
  }

  function handleUserType(type) {
    setUserType(type);
  }

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="/usuarios">
          <NavLink to="/usuarios">Usuários</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Criação</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Cadastro de usuário"
        description="Gerenciamento de coordenadores e especialistas"
      />

      <BoxForm>
        <Formik initialValues={initialValues} onSubmit={createUser}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <BoxInput>
                <label htmlFor="name">Tipo de usuário</label>
                <Select
                  defaultValue="Selecione o tipo de usuário"
                  style={{ width: 300, height: 38 }}
                  onChange={handleUserType}
                >
                  <Option key="admin">Admin</Option>
                  <Option key="coordinator">Coordenador</Option>
                  <Option key="proofreader">Especialista</Option>
                </Select>
              </BoxInput>
              <BoxInput>
                <label htmlFor="name">Nome</label>
                <Field id="name" type="text" name="name" placeholder="Nome" />
              </BoxInput>

              <BoxInput>
                <label htmlFor="email">E-mail</label>
                <Field
                  id="email"
                  type="text"
                  name="email"
                  placeholder="e-mail"
                />
              </BoxInput>

              <ButtonContainer>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={buttonLoading}
                >
                  Cadastrar usuário
                </Button>
              </ButtonContainer>
            </form>
          )}
        </Formik>
      </BoxForm>
    </Container>
  );
}
