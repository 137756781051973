import styled from 'styled-components/macro';
import { Pagination as PaginationAnt } from 'antd';

export const Container = styled.div`
  height: 100%;
  padding: 20px 50px;
  width: 100%;
  max-width: 1600px;
  margin-bottom: 80px;
`;

export const TestimonialsList = styled.div`
  color: #000;
  display: flex;
`;

export const TestimonialsListContent = styled.div`
  padding: 10px;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
`;

export const Testimonial = styled.div`
  margin: 10px;
  width: 320px;
  border-radius: 5px;
  padding-top: 25px;
  color: #fff;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

export const TestimonialContent = styled.div`
  position: relative;
  border-radius: 5px;
  margin-top: 10px;
  width: 100%;
  transition: all 0.5s ease-out;
  padding: 15px;
  padding-top: 30px;
  background: #3a3a3a;
  color: #fff;
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  &:hover {
    transform: scale(1.05);
  }
`;

export const TestimonialActions = styled.div`
  position: absolute;
  top: 20px;
  right: 10px;
`;

export const Pagination = styled(PaginationAnt)`
  display: flex;
  justify-content: center;
  margin-top: 40px !important;
  margin-bottom: 40px !important;
  align-items: center;
`;
