import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  padding: 20px 50px;
  width: 100%;
  max-width: 1600px;

  /* Steps */
  .ant-steps {
    flex-wrap: wrap;

    .ant-steps-item {
      min-width: 150px;
      margin-bottom: 20px;
    }
  }
`;

export const QuestionFormBox = styled.div`
  margin-top: 40px;

  .info {
    padding: 12px 0;

    strong {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.88);
      margin-bottom: 8px;
      display: inline-block;
    }

    p {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      line-height: 25px;
    }
  }

  .forms {
    margin-top: 20px;
    display: flex;
    background: red;

    .left-content {
      width: 70%;
      display: flex;
      flex-direction: column;
      padding-right: 50px;

      > div {
        width: 100%;
      }
    }

    .rigth-content {
      width: 30%;
    }
  }
`;

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 15px;
    margin-right: 20px;
  }

  :global(#background) {
    display: none;
  }

  input {
    box-sizing: border-box;
    margin: 0;
    padding: 7px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
      helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
      segoe ui emoji, segoe ui symbol, noto color emoji;
    position: relative;
    display: inline-block;
    width: 300px;
    min-width: 0;
    background-color: #fff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
  }

  textarea {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
      helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
      segoe ui emoji, segoe ui symbol, noto color emoji;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 200px;
    min-width: 0;
    background-color: #fff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
  }

  .question-footer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.88);
    font-size: 15px;
  }
`;

export const QuestionsFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 60px;

  .buttons {
    display: flex;
    flex-direction: column;
  }
`;

export const QuestionFiles = styled.div`
  img {
    width: 100%;
  }

  ul {
    display: flex;
  }
`;
export const MessageCenter = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 50px;
`;

export const MessageCenterItem = styled.div`
  width: 400px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
  margin-bottom: 20px;
  cursor: pointer;

  ${({ selected }) =>
    selected &&
    css`
      border: 1px solid #ea6d01;
      background: #fef6f0;
    `}

  &:hover {
    background: #fef6f0;
  }

  &:nth-child(2n) {
    margin-left: 20px;
  }

  .icon {
    border-radius: 8px;
    background: #fef6f0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 43px;
    height: 43px;
    margin-right: 15px;

    svg {
      fill: #ea6d01;
    }
  }

  .text {
    max-width: 250px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    strong {
      color: #101012;
    }

    p {
      font-size: 13px;
      color: #a8aaac;
      max-width: 300px;
    }
  }
`;
export const PageTitle = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  h2 {
    color: #334150;
  }
`;

export const CorrectionLinks = styled.div`
  margin-bottom: 100px;

  button {
    margin-top: 15px;
  }
`;
