export function getOrderCorrectionStatus(type) {
  switch (type) {
    case 'NOT_STARTED':
      return 'Disponível';
    case 'STARTED':
      return 'Em produção';
    case 'IN_PROGRESS':
      return 'Em produção';
    case 'COORDINATOR_REVISION':
      return 'Revisão Coordenador';
    case 'PROOFREADER_CORRECTION':
      return 'Revisão Especialista';
    case 'COMPLETED':
      return 'Concluída';
    case 'UNAFFORDABLE':
      return 'Recurso incabível';
    case 'CANCELED':
      return 'Cancelada';
    case 'PRE_ANALYSIS_APPROVED':
      return 'Pré-análise aprovada';
    case 'PRE_ANALYSIS_UNAFFORDABLE':
      return 'Pré-análise incabível';
    case 'PRE_ANALYSIS_PENDING':
      return 'Pré-análise pendências';
    case 'SENDING_DOCUMENTS':
      return 'Envio de Documentos';
    case 'AWAITING_DOCUMENT_RELEASE':
      return 'Aguardando documentos';
    default:
      return '';
  }
}
