import { Button, Descriptions, Tag } from 'antd';
import React, { useState } from 'react';
import { getOrderCorrectionStatusColor } from '../../../../utils/getOrderCorrectionStatusColor';
import { getOrderCorrectionStatus } from '../../../../utils/getOrderCorrectionStatus';
import { ModalQuestionFiles } from '../../../OrderShow/ModalQuestionFiles';
import { getOrderPaymentStatusColor } from '../../../../utils/getOrderPaymentStatusColor';
import { getOrderPaymentStatus } from '../../../../utils/getOrderPaymentStatus';
import { ModalProcuramentTerm } from '../ModalProcuramentTerm';

export function CorrectionInfo({ order }) {
  const [isModalQuestionFilesOpen, setIsModalQuestionFilesOpen] = useState();

  const [isModalProcuramentTermOpen, setIsModalProcuramentTermOpen] =
    useState(false);

  return (
    order && (
      <>
        <Descriptions
          style={{ marginBottom: 50 }}
          title="Informações do Pedido"
          bordered
        >
          <Descriptions.Item label="Número do pedido">
            {order.order_number}
          </Descriptions.Item>
          <Descriptions.Item label="Status da correção" span={3}>
            <Tag color={getOrderCorrectionStatusColor(order.correction_status)}>
              {getOrderCorrectionStatus(order.correction_status)}
            </Tag>
          </Descriptions.Item>
          <Descriptions.Item label="Data de criação">
            {order.created_at}
          </Descriptions.Item>
          <Descriptions.Item label="Arquivo da prova">
            <Button
              type="link"
              onClick={() => setIsModalQuestionFilesOpen(true)}
            >
              Visualizar
            </Button>
          </Descriptions.Item>
          <Descriptions.Item label="Observação do candidato" span={2}>
            {order.client_observation}
          </Descriptions.Item>
        </Descriptions>

        {order.dispatcher_virtual && (
          <Descriptions
            style={{ marginBottom: 50 }}
            title="Contratação Despachante Virtual"
            bordered
          >
            <Descriptions.Item label="Id do Serviço" span={2}>
              {order.dispatcher_virtual.product.code}
            </Descriptions.Item>
            <Descriptions.Item label="Serviço" span={2}>
              {order.dispatcher_virtual.product.name}
            </Descriptions.Item>
            <Descriptions.Item label="N° pedido" span={2}>
              {order.dispatcher_virtual.order_number}
            </Descriptions.Item>
            <Descriptions.Item label="Status de Pagamento" span={2}>
              <Tag
                color={getOrderPaymentStatusColor(
                  order.dispatcher_virtual.payment_status,
                )}
                key="nice"
              >
                {getOrderPaymentStatus(order.dispatcher_virtual.payment_status)}
              </Tag>
            </Descriptions.Item>
          </Descriptions>
        )}

        <Descriptions
          style={{ marginBottom: 50 }}
          title="Trabalhando no recurso"
          bordered
        >
          <Descriptions.Item label="Coordenador">
            {order.correction.coordinator && order.correction.coordinator.name}
          </Descriptions.Item>
          <Descriptions.Item label="Especialista">
            {order.correction.proofreader && order.correction.proofreader.name}
          </Descriptions.Item>
        </Descriptions>

        {order.procurement_virtual && (
          <Descriptions
            style={{ marginBottom: 50 }}
            title="Procuração virtual"
            bordered
          >
            <Descriptions.Item label="Nome" span={2}>
              {order.procurement_virtual.name}
            </Descriptions.Item>
            <Descriptions.Item label="CPF" span={2}>
              {order.procurement_virtual.cpf}
            </Descriptions.Item>
            <Descriptions.Item label="Endereço" span={2}>
              {order.procurement_virtual.andress}
            </Descriptions.Item>
            <Descriptions.Item label="Login" span={2}>
              {order.procurement_virtual.login}
            </Descriptions.Item>
            <Descriptions.Item label="Senha" span={2}>
              {order.procurement_virtual.password}
            </Descriptions.Item>
            <Descriptions.Item label="Termo de uso" span={2}>
              <Button
                type="link"
                onClick={() => setIsModalProcuramentTermOpen(true)}
              >
                Visualizar
              </Button>
            </Descriptions.Item>
          </Descriptions>
        )}

        <ModalQuestionFiles
          isModalQuestionFilesOpen={isModalQuestionFilesOpen}
          setIsModalQuestionFilesOpen={setIsModalQuestionFilesOpen}
          productQuestions={order.product_questions}
          productQuestionFilesWithoutQuestion={
            order.product_question_files_without_question
          }
        />

        {order.procurement_virtual && (
          <ModalProcuramentTerm
            isModalProcuramentTermOpen={isModalProcuramentTermOpen}
            setIsModalProcuramentTermOpen={setIsModalProcuramentTermOpen}
            procuramentVirtual={order.procurement_virtual}
          />
        )}
      </>
    )
  );
}
