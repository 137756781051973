/* eslint-disable react/jsx-no-bind */
import React, { useState, useEffect } from 'react';
import { Breadcrumb, Button, Popconfirm, Tooltip } from 'antd';
import { NavLink } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { MdDeleteOutline, MdModeEditOutline } from 'react-icons/md';
import {
  Container,
  Testimonial,
  TestimonialsList,
  TestimonialsListContent,
  Pagination,
  TestimonialActions,
  TestimonialContent,
} from './styles';
import { PageTitle } from '../../components/PageTitle';
import styles from './Depoimentos.module.css';
import { api } from '../../services/api';
import { Loading } from '../../components/Loading';

export function TechnicalTeam() {
  const [technicalTeam, setTechnicalTeam] = useState([]);
  const [testimonialsTotal, setTestimonialsTotal] = useState(0);
  const [loading, setLoading] = useState(true);

  function stringAvatar(name) {
    const nameParts = name.split(' ');

    if (nameParts.length === 1) {
      return `${nameParts[0][0]}${nameParts[0][0]}`.toUpperCase();
    }

    return `${nameParts[0][0]}${nameParts[1][0]}`.toUpperCase();
  }

  function stringToColor(string) {
    let hash = 0;
    let i;

    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }

    let color = '#';

    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */

    return color;
  }

  const [paginationPage, setPaginationPage] = useState(1);

  async function loadTechnicalTeam() {
    try {
      setLoading(true);
      const response = await api.get(
        `/technical-team/all?page=${paginationPage}`,
      );
      setTestimonialsTotal(response.data.total);
      setTechnicalTeam(response.data.technical_team);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao buscar depoimentos');
    }
  }

  async function deleteTestimonial(testimonailId) {
    try {
      await api.delete(`/technical-team/${testimonailId}`);
      loadTechnicalTeam();
      toast.success('Corpo técnico removido com sucesso');
    } catch (error) {
      setLoading(false);
      toast.error('Erro remover corpo técnico');
    }
  }

  useEffect(() => {
    loadTechnicalTeam();
  }, []);

  useEffect(() => {
    loadTechnicalTeam();
  }, [paginationPage]);

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Corpo técnico</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Corpo técnico"
        description="Gerenciamento do corpo técnico"
        link="/corpo-tecnico/criar"
        linkTitle="Cadastrar corpo técnico"
      />

      {!loading && technicalTeam.length && (
        <>
          <TestimonialsList>
            <TestimonialsListContent>
              {technicalTeam.map((item) => (
                <Testimonial key={item.name}>
                  <TestimonialContent>
                    <p className={styles.name}>{item.name}</p>
                    {item.photo_url ? (
                      <img
                        className={styles.avatar}
                        src={item.photo_url}
                        style={{ width: 50, height: 50 }}
                        alt=""
                      />
                    ) : (
                      <div
                        className={styles.avatarName}
                        style={{ background: stringToColor(item.name) }}
                      >
                        {stringAvatar(item.name)}
                      </div>
                    )}

                    <p className={styles.textDepoiment}>{item.text}</p>
                  </TestimonialContent>

                  <TestimonialActions>
                    <Tooltip title="Editar corpo técnico">
                      <NavLink to={`/corpo-tecnico/atualizar/${item.id}`}>
                        <Button
                          icon={<MdModeEditOutline size={20} color="#fff" />}
                          style={{ background: '#1677ff' }}
                        />
                      </NavLink>
                    </Tooltip>

                    <Tooltip title="Remover corpo técnico">
                      <Popconfirm
                        title="Remover corpo técnico"
                        description="Tem certeza que deseja remover corpo técnico ?"
                        onConfirm={() => deleteTestimonial(item.id)}
                        okText="Confirmar"
                        cancelText="Não"
                      >
                        <Button
                          icon={<MdDeleteOutline size={20} color="#fff" />}
                          style={{ background: '#ff4d4f', marginLeft: 15 }}
                        />
                      </Popconfirm>
                    </Tooltip>
                  </TestimonialActions>
                </Testimonial>
              ))}
            </TestimonialsListContent>
          </TestimonialsList>

          <Pagination
            onChange={setPaginationPage}
            current={paginationPage}
            total={testimonialsTotal}
            showSizeChanger={false}
            defaultPageSize={6}
          />
        </>
      )}

      {loading && <Loading />}
    </Container>
  );
}
