/* eslint-disable function-paren-newline */
/* eslint-disable no-confusing-arrow */
/* eslint-disable comma-dangle */
/* eslint-disable array-callback-return */
import React, { forwardRef, useCallback, useMemo } from 'react';
import { MdOutlineError } from 'react-icons/md';
import { QRCode } from 'antd';
import {
  ClientInfo,
  Container,
  ContainerObservetion,
  Content,
  Footer,
  Info,
  Logo,
  Question,
  QuestionObservetion,
  QuestionObservetions,
  QuestionWithSubquestions,
  Questions,
  Scores,
  Title,
} from './styles';

export const ComponentToPrint = forwardRef((props, ref) => {
  const { correction, order } = props;

  const DEFAULT_PDF_ANSWER_TEXT = 'Ante o exposto, requer seja atribuída nota';

  const correctionFormatted = useMemo(
    () =>
      correction.map((answerItem) => {
        const answer_resource_formatted = answerItem.answer_resource
          ? `${answerItem.answer_resource} ${DEFAULT_PDF_ANSWER_TEXT} ${answerItem.answer_score}`
          : 'Veja o comentário no campo de observações ao final';

        return {
          ...answerItem,
          answer_resource_formatted,
        };
      }),
    [correction],
  );

  const isQuestionsHasSubquestions = useMemo(
    () =>
      correction.some((correctionItem) => correctionItem.subquestions.length),
    [correction],
  );

  const correctionScoreSum = useMemo(() => {
    if (isQuestionsHasSubquestions) {
      let totalScore = 0;

      correction.map((correctionItem) => {
        correctionItem.subquestions.map((subquestionItem) => {
          totalScore += Number(subquestionItem.answer_client_score);
        });
      });

      return totalScore;
    }

    const correctionAnswerScoreSumCalc = correction.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue.answer_client_score),
      0,
    );

    return correctionAnswerScoreSumCalc;
  }, [correction, isQuestionsHasSubquestions]);

  const correctionAnswerScoreSum = useMemo(() => {
    if (isQuestionsHasSubquestions) {
      let totalScore = 0;

      correction.map((correctionItem) => {
        correctionItem.subquestions.map((subquestionItem) => {
          totalScore += Number(subquestionItem.answer_score);
        });
      });

      return totalScore.toFixed(3);
    }

    const correctionAnswerScoreSumCalc = correction.reduce(
      (accumulator, currentValue) =>
        accumulator + Number(currentValue.answer_score),
      0,
    );

    return correctionAnswerScoreSumCalc.toFixed(3);
  }, [correction, isQuestionsHasSubquestions]);

  const correctionSubquestionFormatted = useCallback((subquestion) =>
    subquestion.answer_resource
      ? `${subquestion.answer_resource} ${DEFAULT_PDF_ANSWER_TEXT} ${subquestion.answer_score}`
      : 'Veja o comentário no campo de observações ao final'[correction],
  );

  return (
    <div ref={ref}>
      <Container>
        <Content>
          {correction && order && (
            <>
              <Logo>
                <img
                  src="https://recursooficial.com.br/_next/image?url=%2Fimages%2Flogo.png&w=384&q=75"
                  alt="logo"
                />
              </Logo>

              <Info>
                <img
                  src="https://recursos-medicina-api.s3.amazonaws.com/assets/logo-mini.png"
                  alt="logo"
                />

                <div className="text">
                  <strong>{order.product.name}</strong>
                  <p>
                    Caro candidato, segue abaixo o seu recurso pronto para
                    copiar e colar no site da banca examinadora.
                  </p>
                </div>
              </Info>

              <ClientInfo>
                <div>
                  <strong>Nome:</strong>
                  <p>{order.client.name}</p>
                </div>

                <div>
                  <strong>Pedido:</strong>
                  <p>{`#${order.order_number}`}</p>
                </div>

                <div>
                  <strong>CPF:</strong>
                  <p>{order.client.cpf}</p>
                </div>

                <div>
                  <strong>Email:</strong>
                  <p>{order.client.email}</p>
                </div>
              </ClientInfo>

              <Questions>
                {correctionFormatted.map((questionItem) =>
                  questionItem.subquestions.length ? (
                    <QuestionWithSubquestions>
                      <strong>{questionItem.question_title}</strong>

                      <div className="subquestions">
                        {questionItem.subquestions.map((subquestionItem) => (
                          <div className="subquestion">
                            <span>{subquestionItem.question_title}</span>
                            <div>
                              <p>
                                {correctionSubquestionFormatted(
                                  subquestionItem,
                                )}
                              </p>
                            </div>
                          </div>
                        ))}
                      </div>
                    </QuestionWithSubquestions>
                  ) : (
                    <Question>
                      <strong>{questionItem.question_title}</strong>
                      <div>
                        <p>{questionItem.answer_resource_formatted}</p>
                      </div>
                    </Question>
                  ),
                )}
              </Questions>

              <Scores>
                <div>
                  <span>Nota atribuída pela banca</span>
                  <p>{correctionScoreSum}</p>
                </div>

                <div>
                  <span>Nota requerida com o recurso</span>
                  <p>{correctionAnswerScoreSum}</p>
                </div>
              </Scores>

              <Footer>
                <div>
                  <MdOutlineError color="#FAC623" size={60} />
                </div>

                <div className="text">
                  <p>
                    LEMBRE-SE! Como regra, a Recurso Oficial NÃO faz a
                    apresentação/interposição de recursos junto a Banca
                    Examinadora. Cabe ao candidato fazê-lo, nos termos do edital
                    do certame.
                  </p>

                  <p>
                    ATENÇÃO! Apenas quando disponível e contratado pelo
                    candidato, a partir de uma 2ª compra, o serviço de
                    Despachante Virtual, caberá a Recurso Oficial realizar a
                    apresentação/interposição de recursos junto a Banca
                    Examinadora, em nome dos respectivos contratantes.
                  </p>

                  <p>
                    Veja o passo a passo para interpor o seu recurso perante a
                    banca.
                  </p>
                </div>
                <div>
                  <QRCode
                    size={90}
                    value="https://blog.recursooficial.com.br/revalida-inep"
                    color="#FAC623"
                  />
                </div>
              </Footer>
            </>
          )}
        </Content>
      </Container>

      <ContainerObservetion>
        <Content>
          {correction && order && (
            <>
              <Logo>
                <img
                  src="https://recursooficial.com.br/_next/image?url=%2Fimages%2Flogo.png&w=384&q=75"
                  alt="logo"
                />
              </Logo>

              <Title>
                <h3>Anexo</h3>
              </Title>

              <Info className="observation">
                <div>
                  <MdOutlineError color="#FAC623" size={60} />
                </div>

                <div className="text">
                  <p>
                    As informações a seguir não são parte integrante do seu
                    recurso, tendo caráter meramente informativo.
                  </p>
                </div>
              </Info>

              <QuestionObservetions>
                {correction.map((questionItem) => (
                  <QuestionObservetion>
                    <strong>{`Observações ${questionItem.question_title}`}</strong>
                    <div>
                      <p>{questionItem.answer_observation}</p>

                      <span>
                        <MdOutlineError color="#FAC623" size={14} />
                        Atenção! Esse campo é meramente informativo e não faz
                        parte do recurso.
                      </span>
                    </div>
                  </QuestionObservetion>
                ))}
              </QuestionObservetions>
            </>
          )}
        </Content>
      </ContainerObservetion>
    </div>
  );
});
