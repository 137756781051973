import styled, { css } from 'styled-components/macro';

export const Container = styled.div`
  background: #000;
  min-height: 100vh;
  width: 300px;
  transition: all 0.3s ease;

  ${({ isMenuMinimized }) =>
    isMenuMinimized &&
    css`
      width: 80px;
    `}
`;

export const Logo = styled.div`
  text-align: center;
  padding: 20px 0;
  transition: all 0.15s ease;

  img {
    width: 150px;
  }

  h1 {
    color: #fff;
    font-size: 30px;
  }

  ${({ isMenuMinimized }) =>
    isMenuMinimized &&
    css`
      img {
        width: 40px;
      }
    `}
`;

export const Menu = styled.ul`
  margin-top: 50px !important;
  position: relative;
  transition: all 0.15s ease;

  li {
    a,
    button {
      height: 40px;
      padding: 0 10px 0 18px;
      display: flex;
      align-items: center;
      color: #fff;
      transition: all ease-in 0.2s;
      border-left: 3px solid #000;
      font-weight: 600;

      &:hover {
        border-left: 3px solid #fff;
      }
    }

    span {
      margin-left: 15px;
      font-size: 14px;
    }
  }

  ${({ isMenuMinimized }) =>
    isMenuMinimized &&
    css`
      li {
        display: flex;
        align-items: center;
        justify-content: center;

        a {
          width: 100%;
          height: 50px;
        }

        span {
          display: none;
        }

        svg {
          width: 30px;
          height: 30px;
        }
      }
    `}
`;

export const ToggleMenu = styled.div`
  position: absolute;
  top: -50px;
  right: -15px;
  cursor: pointer;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px,
    rgba(0, 0, 0, 0.22) 0px 10px 10px;
`;
