/* eslint-disable prettier/prettier */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-no-bind */
import { Button, Image, Modal } from 'antd';
import React from 'react';
import { ProductQuestion } from './styles';

export function ModalQuestionFiles({
  setIsModalQuestionFilesOpen,
  isModalQuestionFilesOpen,
  productQuestions,
  productQuestionFilesWithoutQuestion,
}) {
  function isFilePDF(fileURL) {
    return fileURL.split('.').pop() === 'pdf';
  }

  return (
    <Modal
      open={isModalQuestionFilesOpen}
      footer={null}
      onCancel={() => setIsModalQuestionFilesOpen(false)}
      width={900}
    >
      <ProductQuestion>
        <strong>Espelho</strong>

        <div>
          {productQuestionFilesWithoutQuestion && productQuestionFilesWithoutQuestion.map((file) =>
            isFilePDF(file.file_url) ? (
              <Button type="link"><a href={file.file_url} target="_blank" rel="noreferrer">Link</a></Button>
            ) : (
              <Image width={200} src={file.file_url} />
            ))}
        </div>
      </ProductQuestion>

      {productQuestions.map((question) => (
        <ProductQuestion>
          <strong>{question.title}</strong>

          <div>
            {question.product_question_files.map((file) =>
              isFilePDF(file.file_url) ? (
                <Button type="link"><a href={file.file_url} target="_blank" rel="noreferrer">Link</a></Button>
              ) : (
                <Image width={200} src={file.file_url} />
              ))}
          </div>
        </ProductQuestion>
      ))}
    </Modal>
  );
}
