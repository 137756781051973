import styled from 'styled-components/macro';

export const ProductQuestion = styled.div`
  margin-bottom: 30px;

  strong {
    margin-bottom: 10px;
    display: block;
  }

  > div {
    div {
      margin-right: 30px;
    }
  }
`;
