/* eslint-disable react/jsx-no-bind */
import { Button, Modal, Result } from 'antd';
import React from 'react';

export function ModalQuestionOverLimitCharacters({
  isModalOpen,
  setIsModalOpen,
}) {
  return (
    <Modal
      title="Limite de caracteries ultrapassado"
      open={isModalOpen}
      footer={null}
      onCancel={() => setIsModalOpen(false)}
      width={700}
    >
      <Result
        status="warning"
        title="Limite de caracteres ultrapassado, corriga o campo para continuar"
      />

      <div style={{ textAlign: 'center', marginTop: 50 }}>
        <Button type="primary" onClick={() => setIsModalOpen(false)}>
          Corrigir
        </Button>
      </div>
    </Modal>
  );
}
