/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { Field, Form, Formik } from 'formik';

import { toast } from 'react-toastify';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { api } from '../../services/api';

function PasswordForgot() {
  const [initialValues] = useState({
    email: '',
    password: '',
  });
  const [initialValuesCode] = useState({
    code: '',
    password: '',
  });
  const [loading, setLoading] = useState(false);

  const [isSendCode, setIsSendCode] = useState(false);
  const [emailToSend, setEmailToSend] = useState('');

  const navigate = useNavigate();

  async function handleSubmit(values) {
    try {
      setLoading(true);
      const { email } = values;
      setEmailToSend(email);
      await api.post('/admin/passwords/forgot', {
        email,
      });

      setIsSendCode(true);
    } catch (err) {
      setLoading(false);
      const error = err.response.data.message;
      toast.error(error);
    } finally {
      setLoading(false);
    }
  }

  async function handleSubmitCode(values) {
    try {
      setLoading(true);
      const { code, password } = values;

      await api.post('/admin/passwords/reset', {
        code,
        password,
        email: emailToSend,
      });

      setIsSendCode(false);
      toast.success('Senha recuperada com sucesso!');
      navigate('/login');
    } catch (err) {
      setLoading(false);
      const error = err.response.data.message;
      toast.error(error);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        {!isSendCode && (
          <Formik initialValues={initialValues} onSubmit={handleSubmit}>
            {() => (
              <Form>
                <h3>Recuperação de senha</h3>
                <div className={styles.boxInput}>
                  <label htmlFor="email">E-mail</label>
                  <Field
                    id="email"
                    type="email"
                    name="email"
                    placeholder="E-mail"
                  />
                </div>

                <div className={styles.boxEditFormButton}>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    style={{ height: 40, marginTop: 10 }}
                    loading={loading}
                  >
                    Enviar código
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}

        {isSendCode && (
          <Formik initialValues={initialValuesCode} onSubmit={handleSubmitCode}>
            {() => (
              <Form>
                <h3>Recuperação de senha</h3>
                <div className={styles.boxInput}>
                  <label htmlFor="email">Código</label>
                  <Field id="code" name="code" placeholder="código" />
                </div>

                <div className={styles.boxInput}>
                  <label htmlFor="email">Senha</label>
                  <Field
                    id="password"
                    name="password"
                    type="password"
                    placeholder="******"
                  />
                </div>

                <div className={styles.boxEditFormButton}>
                  <Button
                    block
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    style={{ height: 40, marginTop: 10 }}
                    loading={loading}
                  >
                    Alterar senha
                  </Button>
                </div>
              </Form>
            )}
          </Formik>
        )}
      </div>
    </div>
  );
}

export default PasswordForgot;
