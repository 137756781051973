import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../hooks/AuthContext';
import Main from '../components/Main';
import Sidebar from '../components/Sidebar';
import { Header } from '../components/Header';
import Content from '../components/Content';
import { NotFound } from '../pages/NotFound';

const adminRoutes = ['/usuarios', '/produtos', '/categorias'];

export function PrivateRoute({ children }) {
  const { user } = useAuth();

  if (!user) {
    return <Navigate to="/login" />;
  }
  const location = useLocation();
  if (
    user.permission_level === 'proofreader' &&
    adminRoutes.some((route) => location.pathname === route)
  ) {
    return <NotFound />;
  }

  if (
    user.permission_level === 'coordinator' &&
    adminRoutes.some((route) => location.pathname === route)
  ) {
    return <NotFound />;
  }

  return (
    <Main>
      <Sidebar />
      <Content>
        <>
          <Header />
          {children}
        </>
      </Content>
    </Main>
  );
}
