/* eslint-disable prettier/prettier */
import styled, { css } from 'styled-components/macro';

export const Container = styled.header`
  width: 100%;
  height: 70px;
  background: #fff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 30px;
`;

export const UserAvatar = styled.div`
  display: flex;
  align-items: center;

  @media (max-width: 1500px) {
margin-right: 30px;
  }

  > div {
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    text-align: right;

    span {
      font-size: 13px;

      ${({ useType }) =>
    (useType === 'admin' || useType === 'master') &&
        css`
          color: #d4380d;
        `}

        ${({ useType }) =>
    useType === 'proofreader' &&
        css`
          color: #08979c;
        `}

        ${({ useType }) =>
    useType === 'coordinator' &&
        css`
          color: #389e0d;
        `}
    }
  }
`;
