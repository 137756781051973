import { Button, Popconfirm } from 'antd';
import React from 'react';
import { NavLink } from 'react-router-dom';

import styles from './PageTitle.module.scss';

export function PageTitle({
  title,
  description,
  buttonAction,
  buttonTitle,
  link,
  linkTitle,
  buttonSortAction,
  confirm,
}) {
  return (
    <div className={styles.container}>
      <div>
        <h2>{title}</h2>
        {description && <span>{description}</span>}
      </div>
      <div className={styles.actionButtons}>
        {buttonSortAction && (
          <Popconfirm
            title="Mudar ordenação dos items"
            description="Tem certeza que deseja mudar a ordenação ?"
            onConfirm={confirm}
            okText="Confirmar"
            cancelText="Não"
          >
            <Button>Salvar ordenação</Button>
          </Popconfirm>
        )}

        {buttonAction && (
          <Button type="primary" onClick={buttonAction}>
            {buttonTitle}
          </Button>
        )}

        {link && (
          <NavLink to={link}>
            <Button type="primary" onClick={buttonAction}>
              {linkTitle}
            </Button>
          </NavLink>
        )}
      </div>
    </div>
  );
}
