/* eslint-disable react/jsx-no-bind */
import React, { useState } from 'react';
import { Field, Formik } from 'formik';

import { toast } from 'react-toastify';
import { Button } from 'antd';
import { NavLink, useNavigate } from 'react-router-dom';
import styles from './styles.module.scss';
import { useAuth } from '../../hooks/AuthContext';
import logo from '../../assets/logo.png';
import { Logo } from './styles';

function Login() {
  const [initialValues] = useState({
    email: '',
    password: '',
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const history = useNavigate();

  const { signIn } = useAuth();

  async function handleSignIn(values) {
    try {
      setButtonLoading(true);
      const { email, password } = values;
      await signIn({ email, password });
      history('/');
    } catch (err) {
      toast.error('Error ao efeturar login');
      setButtonLoading(false);
    } finally {
      setButtonLoading(false);
    }
  }

  return (
    <div className={styles.container}>
      <div className={styles.content}>
        <Formik initialValues={initialValues} onSubmit={handleSignIn}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <h3>Recurso Oficial</h3>
              <div className={styles.boxInput}>
                <label htmlFor="email">E-mail</label>
                <Field
                  id="email"
                  type="email"
                  name="email"
                  placeholder="E-mail"
                />
              </div>

              <div className={styles.boxInput}>
                <label htmlFor="password">Senha</label>
                <Field
                  id="password"
                  type="password"
                  name="password"
                  placeholder="*******"
                />
              </div>

              <div className={styles.boxEditFormButton}>
                <Button
                  block
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  style={{ height: 40, marginTop: 10 }}
                  loading={buttonLoading}
                >
                  Entrar
                </Button>

                <NavLink to="/recuperacao-senha">Esqueci minha senha</NavLink>
              </div>
            </form>
          )}
        </Formik>
      </div>
    </div>
  );
}

export default Login;
