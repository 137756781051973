/* eslint-disable react/jsx-no-bind */
import { Button, Modal, Result } from 'antd';
import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { CaretRightOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { api } from '../../../../services/api';
import { ErrorMessage } from './styles';

export function ModalStartCorrection({
  isOpenModalStartCorrection,
  setIsOpenModalStartCorrection,
  orderToStartCorrection,
}) {
  const [startCorrectionLoading, setStartCorrectionLoading] = useState(false);
  const navigate = useNavigate();
  const [initCorrectionErrorMessage, setInitCorrectionErrorMessage] =
    useState('');

  async function startCorrection() {
    setStartCorrectionLoading(true);
    try {
      await api.post(`/corrections/start/${orderToStartCorrection.id}`);
      toast.success('Correção iniciada com sucesso');
      setStartCorrectionLoading(false);
      navigate(`/correcao/${orderToStartCorrection.id}`);
    } catch (error) {
      toast.error('Erro ao inicar correção');
      setInitCorrectionErrorMessage(error.response.data.message);
      setStartCorrectionLoading(false);
    }
  }

  return (
    <Modal
      title="Iniciar correção"
      open={isOpenModalStartCorrection}
      footer={null}
      onCancel={() => setIsOpenModalStartCorrection(false)}
      width={700}
      style={{ textAlign: 'center' }}
    >
      <Result
        status="warning"
        title="Tem certeza que deseja iniciar a correção desse pedido?"
      />

      <div style={{ fontSize: 22 }}>
        <strong>
          {`Pedido: ${
            orderToStartCorrection && orderToStartCorrection.order_number
          }`}
        </strong>
      </div>

      <div style={{ textAlign: 'center', marginTop: 50 }}>
        <Button
          style={{ background: '#4AD697' }}
          icon={<CaretRightOutlined />}
          type="primary"
          color="green"
          onClick={startCorrection}
          loading={startCorrectionLoading}
        >
          Iniciar correção
        </Button>

        <ErrorMessage>
          <span>{initCorrectionErrorMessage}</span>
        </ErrorMessage>
      </div>
    </Modal>
  );
}
