export function getOrderPaymentStatus(type) {
  switch (type) {
    case 'STARTED':
      return 'Iniciada';
    case 'STARTED_PIX':
      return 'Pix gerado';
    case 'AUTHORIZED':
      return 'Pré-autorizada';
    case 'PAID':
      return 'Paga';
    case 'IN_ANALYSIS':
      return 'Analisando o risco';
    case 'DECLINED':
      return 'Negada';
    case 'PRE_ANALYSIS':
      return 'Pré-análise';
    case 'CANCELED':
      return 'Cancelada';
    default:
      return '';
  }
}
