import React from 'react';
import { Divider } from 'antd';
import {
  BoxInputRevision,
  Container,
  QuestionFormRevision,
  QuestionItemRevision,
} from './styles';

export function QuestionRevision({
  questionsAnswer,
  questionResourceLimiteCharacterRevision,
}) {
  return (
    <Container>
      {/* Subquestões */}
      {!!questionsAnswer.length &&
        questionsAnswer.map((questionFormItem) => (
          <QuestionItemRevision>
            {!!questionFormItem.subquestions.length && (
              <div className="question-title">
                <strong>{questionFormItem.question_title}</strong>
              </div>
            )}

            {/* Questões */}
            {!questionFormItem.subquestions.length && (
              <QuestionFormRevision key={questionFormItem.question_title}>
                <div className="info">
                  <strong>{questionFormItem.question_title}</strong>
                  <p>
                    Limite de caracteries:
                    {` ${questionFormItem.question_character_limit}`}
                  </p>
                  <p>
                    Nota:
                    {` ${questionFormItem.question_score}`}
                  </p>
                </div>

                <div className="forms">
                  <div>
                    <BoxInputRevision style={{ flex: 2 }}>
                      <label htmlFor="description">Recurso da Questão</label>
                      <textarea
                        value={questionFormItem.answer_resource}
                        disabled
                      />
                      <div className="question-footer">
                        <p>{`Ante o exposto, requer seja atribuída nota ${questionFormItem.answer_score}`}</p>
                        <span>
                          {`${questionResourceLimiteCharacterRevision({
                            answer_score: questionFormItem.answer_score,
                            answer_resource: questionFormItem.answer_resource,
                          })}/${questionFormItem.question_character_limit}`}
                        </span>
                      </div>
                    </BoxInputRevision>

                    <BoxInputRevision style={{ flex: 1 }}>
                      <label htmlFor="description">
                        Observação para o candidato
                      </label>
                      <textarea
                        value={questionFormItem.answer_observation}
                        disabled
                      />
                    </BoxInputRevision>
                  </div>

                  <BoxInputRevision>
                    <label htmlFor="description">
                      Nota atribuída pela banca
                    </label>
                    <input
                      type="number"
                      value={questionFormItem.answer_client_score}
                      disabled
                    />
                  </BoxInputRevision>

                  <BoxInputRevision>
                    <label htmlFor="description">Nota Requerida</label>
                    <input
                      type="number"
                      value={questionFormItem.answer_score}
                      disabled
                    />
                  </BoxInputRevision>
                </div>
              </QuestionFormRevision>
            )}

            {/* Subquestões */}
            {!!questionFormItem.subquestions.length &&
              questionFormItem.subquestions.map((subQuestionItem) => (
                <>
                  <QuestionFormRevision key={subQuestionItem.question_title}>
                    <div className="info">
                      <strong>{subQuestionItem.question_title}</strong>
                      <p>
                        Limite de caracteries:
                        {` ${subQuestionItem.question_character_limit}`}
                      </p>
                      <p>
                        Nota:
                        {` ${subQuestionItem.question_score}`}
                      </p>
                    </div>

                    <div className="forms">
                      <div>
                        <BoxInputRevision style={{ flex: 2 }}>
                          <label htmlFor="description">
                            Recurso da Questão
                          </label>
                          <textarea
                            value={subQuestionItem.answer_resource}
                            disabled
                          />
                          <div className="question-footer">
                            <p>{`Ante o exposto, requer seja atribuída nota ${subQuestionItem.answer_score}`}</p>
                            <span>
                              {`${questionResourceLimiteCharacterRevision({
                                answer_score: subQuestionItem.answer_score,
                                answer_resource:
                                  subQuestionItem.answer_resource,
                              })}/${subQuestionItem.question_character_limit}`}
                            </span>
                          </div>
                        </BoxInputRevision>

                        <BoxInputRevision style={{ flex: 1 }}>
                          <label htmlFor="description">
                            Observação para o candidato
                          </label>
                          <textarea
                            value={subQuestionItem.answer_observation}
                            disabled
                          />
                        </BoxInputRevision>
                      </div>

                      <BoxInputRevision>
                        <label htmlFor="description">
                          Nota atribuída pela banca
                        </label>
                        <input
                          type="number"
                          value={subQuestionItem.answer_client_score}
                          disabled
                        />
                      </BoxInputRevision>

                      <BoxInputRevision>
                        <label htmlFor="description">Nota Requerida</label>
                        <input
                          type="number"
                          value={subQuestionItem.answer_score}
                          disabled
                        />
                      </BoxInputRevision>
                    </div>
                  </QuestionFormRevision>

                  <Divider style={{ marginTop: 50 }} />
                </>
              ))}
          </QuestionItemRevision>
        ))}
    </Container>
  );
}
