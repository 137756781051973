import React, { useEffect, useState } from 'react';
import { Breadcrumb } from 'antd';
import { NavLink } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import { MdOutlineMonetizationOn } from 'react-icons/md';
import Chart from 'react-apexcharts';
import { toast } from 'react-toastify';
import currencyFormatter from 'currency-formatter';
import { format } from 'date-fns';
import {
  Cards,
  Card,
  Container,
  BoxDoughnut,
  BoxLine,
  Row,
  BoxBar,
} from './styles';
import { PageTitle } from '../../components/PageTitle';
import { Loading } from '../../components/Loading';

import { api } from '../../services/api';
import { Filters } from './Filters';

export function Reports() {
  const [totalValues, setTotalValues] = useState({
    invoiced: '',
    captured: '',
    total_orders: '',
    total_corrections: '',
  });
  const [loading, setLoading] = useState(true);

  const [isSearching, setIsSearching] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const [filterProductSelected, setFilterProductSelected] = useState('');
  const [filterProductCategorySelected, setFilterProductCategorySelected] =
    useState('');

  const [checkedFilters, setCheckedFilters] = useState([]);

  const [series, setSeries] = useState([
    {
      name: 'Faturamento',
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: true,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
      ],
    },
    tooltip: {
      y: {
        formatter(val) {
          return currencyFormatter.format(val, {
            code: 'BRL',
          });
        },
      },
    },
  });

  const [seriesDonut, setSeriesDonut] = useState([10, 41]);

  const [optionsDunut] = useState({
    chart: {
      type: 'donut',
    },
    labels: ['Capturado', 'Faturado'],
    responsive: [
      {
        breakpoint: 480,
        options: {
          chart: {
            width: 200,
          },
          legend: {
            position: 'bottom',
          },
        },
      },
    ],
    legend: {
      show: false,
    },
    tooltip: {
      y: {
        formatter(val) {
          return `${val}%`;
        },
      },
    },
  });

  const [seriesBar, setSeriesBar] = useState([
    {
      name: 'Faturamento',
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
  ]);

  const [optionsBar, setOptionsBar] = useState({
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'], // takes an array which will be repeated on columns
        opacity: 0.5,
      },
    },
    xaxis: {
      categories: [
        'Recurso Médicina',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
      ],
    },
    tooltip: {
      y: {
        formatter(val) {
          return currencyFormatter.format(val, {
            code: 'BRL',
          });
        },
      },
    },
  });

  async function getReports() {
    setLoading(true);
    try {
      const response = await api.get('/reports');
      setTotalValues({
        invoiced: currencyFormatter.format(response.data.invoiced, {
          code: 'BRL',
        }),
        captured: currencyFormatter.format(response.data.captured, {
          code: 'BRL',
        }),
        total_orders: response.data.total_orders,
        total_corrections: response.data.total_corrections,
      });

      setSeriesDonut([
        response.data.captured_percent,
        response.data.invoiced_percent,
      ]);

      setSeries([
        {
          name: 'Faturamento',
          data: response.data.invoiced_per_month.map(
            (invoicedItem) => invoicedItem.total_value,
          ),
        },
      ]);

      setOptions({
        ...options,
        xaxis: {
          categories: response.data.invoiced_per_month.map(
            (invoicedItem) => invoicedItem.month,
          ),
        },
      });

      setSeriesBar([
        {
          name: 'Faturamento',
          data: response.data.invoiced_per_service.map(
            (invoicedItem) => invoicedItem.value,
          ),
        },
      ]);

      setOptionsBar({
        ...options,
        xaxis: {
          categories: response.data.invoiced_per_service.map(
            (invoicedItem) => invoicedItem.name,
          ),
        },
      });

      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao obter relatórios');
    }
  }

  useEffect(() => {
    getReports();
  }, []);

  async function searchReports() {
    try {
      setLoading(true);
      setIsSearching(true);
      const response = await api.get('/reports/search', {
        params: {
          product_id: filterProductSelected,
          start_date: startDate && format(startDate, 'yyyy-MM-dd'),
          end_date: endDate && format(endDate, 'yyyy-MM-dd'),
          correction_status: checkedFilters[0],
          category_id: filterProductCategorySelected,
        },
      });

      setTotalValues({
        invoiced: currencyFormatter.format(response.data.invoiced, {
          code: 'BRL',
        }),
        captured: currencyFormatter.format(response.data.captured, {
          code: 'BRL',
        }),
        total_orders: response.data.total_orders,
        total_corrections: response.data.total_corrections,
      });

      setSeriesDonut([
        response.data.captured_percent,
        response.data.invoiced_percent,
      ]);

      setSeries([
        {
          name: 'Faturamento',
          data: response.data.invoiced_per_month.map(
            (invoicedItem) => invoicedItem.total_value,
          ),
        },
      ]);

      setOptions({
        ...options,
        xaxis: {
          categories: response.data.invoiced_per_month.map(
            (invoicedItem) => invoicedItem.month,
          ),
        },
      });

      setSeriesBar([
        {
          name: 'Faturamento',
          data: response.data.invoiced_per_service.map(
            (invoicedItem) => invoicedItem.value,
          ),
        },
      ]);

      setOptionsBar({
        ...options,
        xaxis: {
          categories: response.data.invoiced_per_service.map(
            (invoicedItem) => invoicedItem.name,
          ),
        },
      });
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao obter relatório');

      console.log(error);
    }
  }

  function clearSearch() {
    setIsSearching(false);
    setStartDate();
    setEndDate();
    getReports();
  }
  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Relatórios</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Relatório"
        description="Relatório de vendas, correção e faturamento"
      />

      <Filters
        setFilterProductSelected={setFilterProductSelected}
        setFilterProductCategorySelected={setFilterProductCategorySelected}
        clearSearch={clearSearch}
        search={searchReports}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        checkedFilters={checkedFilters}
        setCheckedFilters={setCheckedFilters}
      />

      {!loading && (
        <>
          <Cards>
            <Card className="captured">
              <div>
                <div className="icon">
                  <MdOutlineMonetizationOn color="#FF9398" size={18} />
                </div>
                <span>Captado</span>
              </div>
              <strong>{totalValues.captured}</strong>
            </Card>

            <Card className="fatured">
              <div>
                <div className="icon ">
                  <MdOutlineMonetizationOn color="#8CBFAD" size={18} />
                </div>
                <span>Faturado</span>
              </div>
              <strong>{totalValues.invoiced}</strong>
            </Card>

            <Card className="orders">
              <div>
                <div className="icon">
                  <MdOutlineMonetizationOn color="#A59761" size={18} />
                </div>
                <span>Total de Pedidos</span>
              </div>
              <strong>{totalValues.total_orders}</strong>
            </Card>

            <Card className="corrections">
              <div>
                <div className="icon">
                  <MdOutlineMonetizationOn color="#95C1D9" size={18} />
                </div>
                <span>Total de Correções</span>
              </div>
              <strong>{totalValues.total_corrections}</strong>
            </Card>
          </Cards>
          <Row>
            {!!totalValues.total_orders && (
              <BoxDoughnut>
                <span>Gráfico capturado e faturado</span>

                <Chart
                  options={optionsDunut}
                  series={seriesDonut}
                  type="donut"
                  height={350}
                />
              </BoxDoughnut>
            )}

            {!startDate && !!totalValues.total_orders && (
              <BoxLine>
                <span>Gráfico de faturamento</span>
                <Chart
                  options={options}
                  series={series}
                  type="line"
                  height={350}
                />
              </BoxLine>
            )}
          </Row>

          {!filterProductSelected && !!totalValues.total_orders && (
            <Row>
              <BoxBar>
                <span>Gráfico faturamento por serviço</span>

                <Chart
                  options={optionsBar}
                  series={seriesBar}
                  type="bar"
                  height={350}
                />
              </BoxBar>
            </Row>
          )}
        </>
      )}

      {loading && <Loading />}
    </Container>
  );
}
