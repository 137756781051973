import React, { useState } from 'react';
import { Button, Modal, Result } from 'antd';
import { toast } from 'react-toastify';
import { api } from '../../../services/api';

export function ModalSendEmailToPresale({
  isModalSendEmailPreSale,
  setIsModalSendEmailPreSale,
  productId,
}) {
  const [sendEmailLoading, setSendEmailLoading] = useState(false);
  const [isSendEmailSuccess, setIsSendEmailSuccess] = useState(false);

  async function sendEmailToPresale() {
    setSendEmailLoading(true);
    try {
      await api.post(`/orders/presale/send-email/${productId}`);

      toast.success('e-mail enviado com sucesso para base de pré venda');
      setSendEmailLoading(false);
      setIsSendEmailSuccess(true);
    } catch (error) {
      toast.error('Erro ao enviar e-mail para base de pré venda');
      setSendEmailLoading(false);
    }
  }

  return (
    <Modal
      title="Envio de e-mail base de pre venda"
      open={isModalSendEmailPreSale}
      footer={null}
      onCancel={() => setIsModalSendEmailPreSale(false)}
      width={700}
    >
      {isSendEmailSuccess && (
        <Result status="success" title="E-mails disparados com sucesso" />
      )}

      {!isSendEmailSuccess && (
        <>
          <Result
            status="warning"
            title="Tem certeza que deseja enviar e-mail para os clientes informando para eles enviarem os arquivos ?"
          />

          <div style={{ textAlign: 'center', marginTop: 50 }}>
            <Button
              loading={sendEmailLoading}
              type="primary"
              onClick={sendEmailToPresale}
            >
              Disparar e-mails
            </Button>
          </div>
        </>
      )}
    </Modal>
  );
}
