/* eslint-disable consistent-return */
/* eslint-disable react/no-danger */
/* eslint-disable react/jsx-curly-newline */
/* eslint-disable react/jsx-no-bind */
import { HomeOutlined, PlusOutlined } from '@ant-design/icons';
import {
  Alert,
  Breadcrumb,
  Button,
  Divider,
  Modal,
  Select,
  Switch,
  Tooltip,
  message,
} from 'antd';
import React, { useEffect, useMemo, useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';
import { BsInfoCircleFill } from 'react-icons/bs';

import ReactDatePicker from 'react-datepicker';
import uuid from 'react-uuid';
import {
  BoxForm,
  BoxInput,
  ButtonContainer,
  Container,
  DateInputBox,
  ProductQuestions,
  TextEditorBox,
} from './styles';
import { PageTitle } from '../../components/PageTitle';
import { api } from '../../services/api';

const { Option } = Select;

const PRODUCT_TYPES = [
  {
    id: 'DEFAULT',
    name: 'Padrão',
  },
  {
    id: 'VIRTUAL_DISPATCHER',
    name: 'Despachante virtual',
  },
];

export function ProductCreate() {
  const [initialValues] = useState({
    name: '',
    price: '',
    price_discount: '',
    installments: '',
    product_overview: '',
    description: '',
    link_video: '',
    card_background: '',
    card_tag_bottom: '',
    card_tag_top: '',
    limit_time_to_buy_with_promotion: 0,
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();

  const [availableForSale, setAvailableForSale] = useState(false);
  const [showInStore, setShowInStore] = useState(false);
  const [preSale, setPreSale] = useState(false);
  const [price, setPrice] = useState(0);
  const [priceDiscount, setPriceDiscount] = useState(0);
  const [percentDiscountCash, setPercentDiscountCash] = useState(0);
  const [percentDiscountPix, setPercentDiscountPix] = useState(0);
  const [hasProcurement, setHasProcurement] = useState(false);
  const [isDefaultPurchase, setIsDefaultPurchase] = useState(true);
  const [isPreAnalysis, setIsPreAnalysis] = useState(false);

  // Questões
  const [isModalAddQuestionsOpen, setIsModalAddQuestionsOpen] = useState(false);
  const [isModalUpdateQuestionsOpen, setIsModalUpdateQuestionsOpen] =
    useState(false);
  const [questionId, setQuestionId] = useState('');
  const [questionName, setQuestionName] = useState('');
  const [questionObservation, setQuestionObservation] = useState('');
  const [questionLimiteCaracteries, setQuestionLimiteCaracteries] =
    useState('');
  const [questionScore, setQuestionScore] = useState('');
  const [productQuestions, setProductQuestions] = useState([]);
  const [messageApi, contextHolder] = message.useMessage();
  const [createProductErrorMessage, setCreateProductErrorMessage] =
    useState('');

  const [editorTextValue, setEditorTextValue] = useState('');

  const [productCategorySelected, setProductCategorySelected] = useState('');
  const [productCategories, setProductCategories] = useState([]);
  const [productType, setproductType] = useState('DEFAULT');
  const [
    dateLimiteToBuyProductDispatcher,
    setDateLimiteToBuyProductDispatcher,
  ] = useState(new Date());

  const [useQuestionsToUpload, setUseQuestionsToUpload] = useState(true);

  function clearPrice(priceInput) {
    return Number(priceInput.replace('.', ''));
  }

  async function createProduct(values) {
    setButtonLoading(true);
    try {
      const {
        name,
        installments,
        product_overview,
        link_video,
        card_background,
        card_tag_bottom,
        card_tag_top,
        limit_time_to_buy_with_promotion,
      } = values;

      const product_questions = productQuestions.map((question) => ({
        title: question.questionName,
        character_limit: Number(question.questionLimiteCaracteries),
        score: Number(question.questionScore),
        observation: question.observation,
      }));

      await api.post('/products', {
        name,
        price: price && clearPrice(price),
        installments,
        available_for_sale: availableForSale,
        show_in_store: showInStore,
        pre_sale: preSale,
        product_questions,
        description: editorTextValue,
        product_overview,
        link_video,
        card_background,
        card_tag_bottom,
        card_tag_top,
        percent_discount_cash: percentDiscountCash,
        percent_discount_pix: percentDiscountPix,
        category_id:
          productCategorySelected === 'null' ? null : productCategorySelected,
        has_procurement: hasProcurement,
        price_discount: priceDiscount && clearPrice(priceDiscount),
        type: productType,
        limit_date_to_buy: dateLimiteToBuyProductDispatcher,
        limit_time_to_buy_with_promotion,
        use_questions_to_upload: useQuestionsToUpload,
        is_default_purchase: isDefaultPurchase,
        is_pre_analysis: isPreAnalysis,
      });
      toast.success('Producto cadasttrado com sucesso');

      setButtonLoading(false);
      navigate(-1);
    } catch (err) {
      console.log(err);
      toast.error('Erro ao cadastrar produto');
      setCreateProductErrorMessage(err.response.data.message);
      window.scrollTo(0, 0);
      setButtonLoading(false);
    } finally {
      setButtonLoading(false);
    }
  }

  function productQuestionActions(props) {
    const { action } = props;

    if (!questionScore || !questionName || !questionLimiteCaracteries) {
      messageApi.open({
        type: 'error',
        content: 'Todos os campos são obrigatórios',
      });

      return;
    }

    if (action === 'add') {
      setProductQuestions([
        ...productQuestions,
        {
          id: uuid(),
          questionName,
          questionLimiteCaracteries,
          questionScore,
          observation: questionObservation,
        },
      ]);
      setQuestionName('');
      setQuestionLimiteCaracteries('');
      setQuestionScore('');
      setQuestionObservation('');
      toast.success('Questão adicionada com sucesso');
    }

    if (action === 'update') {
      const questionIndex = productQuestions.findIndex(
        (question) => question.id === questionId,
      );

      const newProductQuestions = [...productQuestions];
      newProductQuestions[questionIndex].questionName = questionName;
      newProductQuestions[questionIndex].questionLimiteCaracteries =
        questionLimiteCaracteries;
      newProductQuestions[questionIndex].questionScore = questionScore;
      setProductQuestions(newProductQuestions);

      setQuestionName('');
      setQuestionLimiteCaracteries('');
      setQuestionScore('');
      toast.success('Questão atualizada com sucesso com sucesso');
      setIsModalUpdateQuestionsOpen(false);
    }
  }

  function closeModalAddProductQuestion() {
    setIsModalAddQuestionsOpen(false);
    setQuestionName('');
    setQuestionLimiteCaracteries('');
    setQuestionScore('');
    setIsModalUpdateQuestionsOpen(false);
  }

  function openModalToUpdateProductQuestion(productQuestion) {
    const {
      question_name,
      question_limit_caracteries,
      question_score,
      question_id,
    } = productQuestion;
    setQuestionName(question_name);
    setQuestionLimiteCaracteries(question_limit_caracteries);
    setQuestionScore(question_score);
    setIsModalUpdateQuestionsOpen(true);
    setQuestionId(question_id);
  }

  function excluseProductQuestion(id) {
    const productQuestionsNew = productQuestions.filter(
      (question) => question.id !== id,
    );
    setProductQuestions(productQuestionsNew);
  }

  function formatPrice(priceInput) {
    return (Number(clearPrice(priceInput)) / 100).toFixed(2);
  }

  function handleProductCategory(type) {
    setProductCategorySelected(type);
  }

  async function getProductCategories() {
    try {
      const response = await api.get('/store/product-categories');
      setProductCategories(response.data);
    } catch (error) {
      toast.error('Erro ao obter categorias');
    }
  }

  useEffect(() => {
    getProductCategories();
  }, []);

  const isProductTypeDefault = useMemo(
    () => productType === 'DEFAULT',
    [productType],
  );

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(productQuestions);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setProductQuestions(items);
  }
  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="/produtos">
          <NavLink to="/produtos">Produtos</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Criação</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Cadastro de produto"
        description="Cadastro completo de produto"
      />

      <BoxForm>
        {createProductErrorMessage && (
          <Alert
            message="Erro ao cadastrar"
            description={createProductErrorMessage}
            type="error"
            showIcon
            style={{ marginBottom: 30 }}
          />
        )}

        <Formik initialValues={initialValues} onSubmit={createProduct}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <BoxInput>
                <label htmlFor="category">Tipo de produto</label>
                <Select
                  defaultValue={productType}
                  style={{ width: 300, height: 38 }}
                  onChange={(type) => setproductType(type)}
                >
                  {PRODUCT_TYPES.map((productTypeItemItem) => (
                    <Option key={productTypeItemItem.id}>
                      {productTypeItemItem.name}
                    </Option>
                  ))}
                </Select>
              </BoxInput>

              <BoxInput>
                <label htmlFor="name">Nome</label>
                <Field id="name" type="text" name="name" placeholder="Nome" />
              </BoxInput>

              <BoxInput>
                <label htmlFor="price">Preço de</label>
                <input
                  id="price"
                  type="text"
                  name="price"
                  placeholder="Preço de"
                  value={price}
                  onChange={(event) =>
                    setPrice(formatPrice(event.target.value))
                  }
                />
              </BoxInput>

              <BoxInput>
                <label htmlFor="price_discount">Preço por</label>
                <input
                  id="price_discount"
                  type="text"
                  name="price_discount"
                  placeholder="Preço por"
                  value={priceDiscount}
                  onChange={(event) =>
                    setPriceDiscount(formatPrice(event.target.value))
                  }
                />
              </BoxInput>

              <BoxInput>
                <label htmlFor="discount_cash">Desconto a vista %</label>
                <input
                  id="discount_cash"
                  type="number"
                  name="discount_cash"
                  placeholder="%"
                  value={percentDiscountCash}
                  onChange={(event) =>
                    setPercentDiscountCash(formatPrice(event.target.value))
                  }
                />
              </BoxInput>

              <BoxInput>
                <label htmlFor="discount_pix">Desconto no PIX %</label>
                <input
                  id="discount_pix"
                  type="number"
                  name="discount_pix"
                  placeholder="%"
                  value={percentDiscountPix}
                  onChange={(event) =>
                    setPercentDiscountPix(formatPrice(event.target.value))
                  }
                />
              </BoxInput>

              <BoxInput>
                <label htmlFor="installments">Número de parcelas</label>
                <Field
                  id="installments"
                  type="number"
                  name="installments"
                  placeholder="Número de parcelas"
                />
              </BoxInput>

              <BoxInput>
                <label htmlFor="category">Categoria</label>
                <Select
                  defaultValue="Selecione a categoria"
                  style={{ width: 300, height: 38 }}
                  onChange={handleProductCategory}
                >
                  <Option key="null">Sem categoria</Option>
                  {productCategories.map((categoryItem) => (
                    <Option key={categoryItem.id}>{categoryItem.name}</Option>
                  ))}
                </Select>
              </BoxInput>

              <BoxInput>
                <label htmlFor="available_for_sale">
                  Disponível para venda ?
                </label>
                <Switch
                  defaultChecked
                  checked={availableForSale}
                  onChange={() => {
                    setAvailableForSale(!availableForSale);
                    setShowInStore(!availableForSale);
                  }}
                />
              </BoxInput>

              {isProductTypeDefault && (
                <BoxInput>
                  <label htmlFor="show_in_store">Mostrar na loja ?</label>
                  <Switch
                    defaultChecked
                    checked={showInStore}
                    onChange={() => setShowInStore(!showInStore)}
                  />
                </BoxInput>
              )}

              {isProductTypeDefault && (
                <BoxInput>
                  <label htmlFor="setPreSale">É pré-venda ?</label>
                  <Switch
                    defaultChecked
                    checked={preSale}
                    onChange={() => setPreSale(!preSale)}
                  />
                </BoxInput>
              )}

              <BoxInput>
                <label htmlFor="isDefaultPurchase">
                  <span>É compra padrão ?</span>
                  <Tooltip title="Quando marcado o cliente faz a compra normalmente">
                    <BsInfoCircleFill size={18} />
                  </Tooltip>
                </label>
                <Switch
                  defaultChecked
                  checked={isDefaultPurchase}
                  onChange={() => setIsDefaultPurchase(!isDefaultPurchase)}
                />
              </BoxInput>

              <BoxInput>
                <label htmlFor="setPreSale">
                  <span>É compra pré análise ?</span>
                  <Tooltip title="Quando marcado o cliente faz a pré analise da prova, sem precisar pagar">
                    <BsInfoCircleFill size={22} />
                  </Tooltip>
                </label>

                <Switch
                  defaultChecked
                  checked={isPreAnalysis}
                  onChange={() => setIsPreAnalysis(!isPreAnalysis)}
                />
              </BoxInput>

              {!isProductTypeDefault && (
                <DateInputBox>
                  <label>Data limite para compra</label>
                  <ReactDatePicker
                    selected={dateLimiteToBuyProductDispatcher}
                    onChange={(date) =>
                      setDateLimiteToBuyProductDispatcher(date)
                    }
                    locale="pt"
                    dateFormat="dd/MM/yyyy - hh:mm"
                    showTimeSelect
                  />
                </DateInputBox>
              )}

              {!isProductTypeDefault && (
                <BoxInput>
                  <label>Tempo limite para compra com promoção (minutos)</label>
                  <Field
                    id="limit_time_to_buy_with_promotion"
                    type="number"
                    name="limit_time_to_buy_with_promotion"
                    placeholder="minutos"
                  />
                </BoxInput>
              )}

              {isProductTypeDefault && (
                <BoxInput>
                  <label htmlFor="procurement">
                    Tem procuração eletronica ?
                  </label>
                  <Switch
                    defaultChecked
                    checked={hasProcurement}
                    onChange={() => setHasProcurement(!hasProcurement)}
                  />
                </BoxInput>
              )}

              {isProductTypeDefault && (
                <BoxInput>
                  <label htmlFor="useQuestionsToUpload">
                    Associar documentos por questão ?
                  </label>
                  <Switch
                    defaultChecked
                    checked={useQuestionsToUpload}
                    onChange={() =>
                      setUseQuestionsToUpload(!useQuestionsToUpload)
                    }
                  />
                </BoxInput>
              )}

              <Divider style={{ marginBottom: 40, marginTop: 40 }}>
                Conteúdo do Produto
              </Divider>

              <BoxInput>
                <label htmlFor="description">Descrição</label>
                <TextEditorBox>
                  <ReactQuill
                    theme="snow"
                    value={editorTextValue}
                    onChange={setEditorTextValue}
                  />
                </TextEditorBox>
              </BoxInput>

              <BoxInput>
                <label htmlFor="product_overview">Resumo do produto</label>
                <Field name="product_overview">
                  {({ field }) => (
                    <div>
                      <textarea {...field} value={field.value || ''} />
                    </div>
                  )}
                </Field>
              </BoxInput>

              <BoxInput>
                <label htmlFor="link_video">Link do Vídeo</label>
                <Field
                  id="link_video"
                  type="text"
                  name="link_video"
                  placeholder="Link do Vídeo"
                />
              </BoxInput>

              <BoxInput>
                <label htmlFor="card_background">Background do card</label>
                <Field
                  id="card_background"
                  type="text"
                  name="card_background"
                  placeholder="#021542"
                />
              </BoxInput>

              <BoxInput>
                <label htmlFor="card_tag_top">Card Tag superior</label>
                <Field
                  id="card_tag_top"
                  type="text"
                  name="card_tag_top"
                  placeholder="Nome da tag"
                />
              </BoxInput>

              <BoxInput>
                <label htmlFor="card_tag_bottom">Card Tag inferior</label>
                <Field
                  id="card_tag_bottom"
                  type="text"
                  name="card_tag_bottom"
                  placeholder="Nome da tag"
                />
              </BoxInput>

              {isProductTypeDefault && (
                <>
                  <Divider style={{ marginBottom: 40, marginTop: 40 }}>
                    Dados para correção
                  </Divider>

                  <Tooltip title="Adicionar questão">
                    <Button
                      style={{ marginBottom: 50 }}
                      type="primary"
                      shape="circle"
                      icon={<PlusOutlined />}
                      onClick={() => setIsModalAddQuestionsOpen(true)}
                    />
                  </Tooltip>

                  {/* Product Questions */}
                  <DragDropContext onDragEnd={handleOnDragEnd}>
                    <Droppable droppableId="characters">
                      {(provided) => (
                        <ProductQuestions
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {productQuestions.map(
                            (productQuestionItem, index) => (
                              <Draggable
                                key={String(productQuestionItem.id)}
                                draggableId={String(productQuestionItem.id)}
                                index={index}
                              >
                                {(providedDrag) => (
                                  <div
                                    ref={providedDrag.innerRef}
                                    {...providedDrag.draggableProps}
                                    {...providedDrag.dragHandleProps}
                                  >
                                    <div className="content">
                                      <strong>
                                        {productQuestionItem.questionName}
                                      </strong>
                                      <p>
                                        Limite de caracteries:
                                        {` ${productQuestionItem.questionLimiteCaracteries}`}
                                      </p>
                                      <p>
                                        Nota:
                                        {productQuestionItem.questionScore}
                                      </p>
                                    </div>

                                    <div className="actions">
                                      <Button
                                        onClick={() =>
                                          openModalToUpdateProductQuestion({
                                            question_name:
                                              productQuestionItem.questionName,
                                            question_limit_caracteries:
                                              productQuestionItem.questionLimiteCaracteries,
                                            question_score:
                                              productQuestionItem.questionScore,
                                            question_id: productQuestionItem.id,
                                          })
                                        }
                                      >
                                        Editar
                                      </Button>
                                      <Button
                                        onClick={() =>
                                          excluseProductQuestion(
                                            productQuestionItem.id,
                                          )
                                        }
                                      >
                                        Excluir
                                      </Button>
                                    </div>
                                  </div>
                                )}
                              </Draggable>
                            ),
                          )}
                          {provided.placeholder}
                        </ProductQuestions>
                      )}
                    </Droppable>
                  </DragDropContext>
                </>
              )}

              {/* Modal Add Product Questions */}
              <Modal
                title="Cadastrar uma questão"
                open={isModalAddQuestionsOpen}
                footer={null}
                onCancel={closeModalAddProductQuestion}
                width={700}
              >
                <BoxInput style={{ marginTop: 30 }}>
                  <label htmlFor="questionName">Título</label>
                  <input
                    id="questionName"
                    type="text"
                    name="questionName"
                    placeholder="Título da questão"
                    value={questionName}
                    onChange={(event) => setQuestionName(event.target.value)}
                  />
                </BoxInput>

                <BoxInput>
                  <label htmlFor="questionLimiteCaracteries">
                    Limite de caracteries
                  </label>
                  <input
                    id="questionLimiteCaracteries"
                    type="number"
                    name="questionLimiteCaracteries"
                    placeholder="Limite de caracteries da questão"
                    value={questionLimiteCaracteries}
                    onChange={(event) =>
                      setQuestionLimiteCaracteries(event.target.value)
                    }
                  />
                </BoxInput>

                <BoxInput>
                  <label htmlFor="questionScore">Nota máxima</label>
                  <input
                    id="questionScore"
                    type="number"
                    name="questionScore"
                    placeholder="Nota máxima da questão"
                    value={questionScore}
                    onChange={(event) => setQuestionScore(event.target.value)}
                  />
                </BoxInput>

                <BoxInput style={{ marginTop: 30 }}>
                  <label htmlFor="observation">Observação</label>
                  <input
                    id="observation"
                    type="text"
                    name="observation"
                    placeholder="Observação da questão"
                    value={questionObservation}
                    onChange={(event) =>
                      setQuestionObservation(event.target.value)
                    }
                  />
                </BoxInput>

                <div style={{ textAlign: 'center', marginTop: 50 }}>
                  <Button
                    type="primary"
                    onClick={() => productQuestionActions({ action: 'add' })}
                  >
                    Adicionar questão
                  </Button>
                </div>
              </Modal>

              {/* Modal Update Product Questions */}
              <Modal
                title="Atualizar uma questão"
                open={isModalUpdateQuestionsOpen}
                footer={null}
                onCancel={closeModalAddProductQuestion}
                width={700}
              >
                <BoxInput style={{ marginTop: 30 }}>
                  <label htmlFor="questionName">Título</label>
                  <input
                    id="questionName"
                    type="text"
                    name="questionName"
                    placeholder="Título da questão"
                    value={questionName}
                    onChange={(event) => setQuestionName(event.target.value)}
                  />
                </BoxInput>

                <BoxInput>
                  <label htmlFor="questionLimiteCaracteries">
                    Limite de caracteries
                  </label>
                  <input
                    id="questionLimiteCaracteries"
                    type="number"
                    name="questionLimiteCaracteries"
                    placeholder="Limite de caracteries da questão"
                    value={questionLimiteCaracteries}
                    onChange={(event) =>
                      setQuestionLimiteCaracteries(event.target.value)
                    }
                  />
                </BoxInput>

                <BoxInput>
                  <label htmlFor="questionScore">Nota máxima</label>
                  <input
                    id="questionScore"
                    type="text"
                    name="questionScore"
                    placeholder="Nota máxima da questão"
                    value={questionScore}
                    onChange={(event) => setQuestionScore(event.target.value)}
                  />
                </BoxInput>

                <BoxInput style={{ marginTop: 30 }}>
                  <label htmlFor="observation">Observação</label>
                  <input
                    id="observation"
                    type="text"
                    name="observation"
                    placeholder="Observação da questão"
                    value={questionObservation}
                    onChange={(event) =>
                      setQuestionObservation(event.target.value)
                    }
                  />
                </BoxInput>

                <div style={{ textAlign: 'center', marginTop: 50 }}>
                  <Button
                    type="primary"
                    onClick={() => productQuestionActions({ action: 'update' })}
                  >
                    Atualizar questão
                  </Button>
                </div>
              </Modal>

              <ButtonContainer>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={buttonLoading}
                >
                  Cadastrar produto
                </Button>
              </ButtonContainer>
            </form>
          )}
        </Formik>
      </BoxForm>

      {contextHolder}
    </Container>
  );
}
