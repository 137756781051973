import styled from 'styled-components/macro';

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-right: 30px;
  margin-top: 20px;

  &:last-child {
    margin-right: 0;
  }

  label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 15px;
    margin-right: 20px;
  }

  :global(#background) {
    display: none;
  }

  textarea {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
      helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
      segoe ui emoji, segoe ui symbol, noto color emoji;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 100px;
    min-width: 0;
    background-color: #fff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
  }

  .question-footer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.88);
    font-size: 15px;
  }
`;
