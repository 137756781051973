import React from 'react';
import { Button, Dropdown } from 'antd';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';

import { MdKeyboardArrowDown } from 'react-icons/md';

import { SearchOutlined } from '@ant-design/icons';
import {
  BoxInput,
  Container,
  DateInputBox,
  FilterOptions,
  SearchBar,
} from './styles';
import { getOrderPaymentStatus } from '../../../../utils/getOrderPaymentStatus';
import { getOrderCorrectionStatus } from '../../../../utils/getOrderCorrectionStatus';

export function Filters({
  filterCorrectionStatus,
  setFilterCorrectionStatus,
  filterPaymentStatus,
  setFilterPaymentStatus,
  setSearchWord,
  search,
  clearSearch,
  searchWord,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
}) {
  const correctionStatus = [
    {
      key: '1',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('')}
        >
          Todos
        </Button>
      ),
    },

    {
      key: '2',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('NOT_STARTED')}
        >
          Disponível
        </Button>
      ),
    },
    {
      key: '3',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('STARTED')}
        >
          Em produção
        </Button>
      ),
    },
    {
      key: '4',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('COORDINATOR_REVISION')}
        >
          Revisão Coordenador
        </Button>
      ),
    },
    {
      key: '5',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('COMPLETED')}
        >
          Concluída
        </Button>
      ),
    },
    {
      key: '6',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('UNAFFORDABLE')}
        >
          Recurso incabível
        </Button>
      ),
    },
    {
      key: '7',
      label: (
        <Button
          style={{
            width: '100%',
            textAlign: 'left',
          }}
          type="button"
          onClick={() => setFilterCorrectionStatus('CANCELED')}
        >
          Cancelada
        </Button>
      ),
    },
  ];

  registerLocale('pt', pt);

  return (
    <Container>
      <SearchBar>
        <BoxInput>
          <input
            id="search"
            type="text"
            name="search"
            placeholder="Buscar"
            onChange={(event) => setSearchWord(event.target.value)}
            onKeyDown={(event) => event.key === 'Enter' && search()}
            value={searchWord}
          />
        </BoxInput>

        <Button
          icon={<SearchOutlined />}
          style={{ marginLeft: 20 }}
          type="primary"
          onClick={search}
        >
          Buscar
        </Button>

        <Button type="link" onClick={clearSearch}>
          Limpar Busca
        </Button>
      </SearchBar>

      <FilterOptions>
        <DateInputBox>
          <label>Data Inicial</label>
          <ReactDatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            locale="pt"
            dateFormat="dd/MM/yyyy"
          />
        </DateInputBox>

        <DateInputBox>
          <label>Data Final</label>
          <ReactDatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            locale="pt"
            dateFormat="dd/MM/yyyy"
          />
        </DateInputBox>

        <Dropdown menu={{ items: correctionStatus }} placement="bottomLeft">
          <Button
            style={{
              minWidth: 260,
              height: 38,
              display: 'flex',
              alignItems: 'center',
              marginLeft: 15,
            }}
            icon={<MdKeyboardArrowDown size={20} />}
          >
            <strong style={{ marginRight: 5 }}>Status de correção: </strong>
            <p>
              {filterCorrectionStatus
                ? `  ${getOrderCorrectionStatus(filterCorrectionStatus)}`
                : '  Todos'}
            </p>
          </Button>
        </Dropdown>
      </FilterOptions>
    </Container>
  );
}
