/* eslint-disable react/jsx-curly-newline */
/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable react/jsx-no-bind */
import { HomeOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Button,
  Select as ANTDSelect,
  Tabs,
  Table,
  Popconfirm,
  Tooltip,
  Alert,
} from 'antd';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import Select from 'react-select';

import { MdDeleteOutline } from 'react-icons/md';
import {
  AssociationProductContent,
  AssociationProductContentSelect,
  BoxForm,
  BoxInput,
  ButtonContainer,
  Container,
} from './styles';
import { PageTitle } from '../../components/PageTitle';
import { api } from '../../services/api';
import { Loading } from '../../components/Loading';

const { Option } = ANTDSelect;

export function UsersUpdate() {
  const [initialValues, setIntialValues] = useState({
    name: '',
    email: '',
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [userType, setUserType] = useState('admin');

  const [loading, setLoading] = useState(true);

  // Associação de produtos
  const [userProductsAssociations, setUserProductsAssociations] = useState();
  const [products, setProducts] = useState();
  const [productAssociationSelected, setProductAssociationSelected] =
    useState('');
  const [userUpdateErroMessage, setUserUpdateErroMessage] = useState();

  const navigate = useNavigate();

  const { user_id } = useParams();

  async function readUser() {
    try {
      const response = await api.get(`/admin-users/${user_id}`);
      setIntialValues(response.data);
      setUserType(response.data.permission_level);
      setLoading(false);
    } catch (err) {
      toast.error('Erro ao ler usuário');
      setLoading(false);
    }
  }

  async function readUserProductAssociations() {
    try {
      const response = await api.get(
        `/user-product-associations/?user_id=${user_id}`,
      );

      const userProductAssociationsFormatted = response.data.map(
        (association) => ({
          ...association,
          key: association.id,
          product_code: association.product.code,
          product_name: association.product.name,
        }),
      );

      setUserProductsAssociations(userProductAssociationsFormatted);
      setLoading(false);
    } catch (err) {
      toast.error('Erro ao ler Associações');
      setLoading(false);
    }
  }

  async function updateUser(values) {
    setButtonLoading(true);

    const { name } = values;
    try {
      await api.put(`/admin-users/${user_id}`, {
        name,
        permission_level: userType,
      });
      toast.success('User atualizado com sucesso');
      setButtonLoading(false);
      navigate(-1);
    } catch (err) {
      toast.error('Erro ao atualizar usuário');
      setButtonLoading(false);
    }
  }

  function handleUserType(type) {
    setUserType(type);
  }

  async function readProducts() {
    try {
      const response = await api.get('products');

      setProducts(
        response.data.map((product) => ({
          value: product.id,
          label: product.name,
        })),
      );
    } catch (err) {
      toast.error('Erro ao ler produtos');
      setButtonLoading(false);
    }
  }

  async function addProductToUser() {
    try {
      await api.post('/user-product-associations', {
        user_id,
        product_id: productAssociationSelected,
      });

      readUserProductAssociations();
      setUserUpdateErroMessage('');
      toast.success('Produto adicionado com sucesso');
    } catch (error) {
      toast.error('Erro ao ao adicionar produto para usuário');
      setUserUpdateErroMessage(error.response.data.message);
    }
  }

  useEffect(() => {
    readUser();
    readUserProductAssociations();
    readProducts();
  }, []);

  async function deleteProductAssiciation(asociationId) {
    try {
      await api.delete(`/user-product-associations/${asociationId}`);
      readUserProductAssociations();

      toast.success('Associação removida com sucesso');
    } catch (error) {
      toast.error('Erro ao remover associação');
    }
  }

  const columns = [
    {
      title: 'Código do produto',
      dataIndex: 'product_code',
      key: 'email',
    },
    {
      title: 'Nome do produto',
      dataIndex: 'product_name',
      key: 'name',
    },
    {
      title: 'Ações',
      key: 'action',
      render: (association) => (
        <div>
          <Tooltip title="Visualizar dados do pedido">
            <Button
              icon={<MdDeleteOutline size={20} color="#fff" />}
              style={{ background: '#ff4d4f' }}
              onClick={() => deleteProductAssiciation(association.id)}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  const formContent = (
    <BoxForm>
      <Formik initialValues={initialValues} onSubmit={updateUser}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <BoxInput>
              <label htmlFor="name">Tipo de usuário</label>
              <ANTDSelect
                defaultValue={userType}
                style={{ width: 300, height: 38 }}
                onChange={handleUserType}
              >
                <Option key="admin">Admin</Option>
                <Option key="coordinator">Coordenador</Option>
                <Option key="proofreader">Especialista</Option>
              </ANTDSelect>
            </BoxInput>
            <BoxInput>
              <label htmlFor="name">Nome</label>
              <Field id="name" type="text" name="name" placeholder="Nome" />
            </BoxInput>

            <BoxInput>
              <label htmlFor="email">E-mail</label>
              <Field
                id="email"
                type="text"
                name="email"
                placeholder="e-mail"
                disabled
              />
            </BoxInput>

            <ButtonContainer>
              <Button
                type="primary"
                htmlType="submit"
                size="middle"
                loading={buttonLoading}
              >
                Atualizar usuário
              </Button>
            </ButtonContainer>
          </form>
        )}
      </Formik>
    </BoxForm>
  );

  const associationProductContent = (
    <AssociationProductContent>
      <h3>Selecione um produto que o usuário poderá trabalhar</h3>
      <AssociationProductContentSelect>
        <Select
          options={products}
          onChange={(productId) =>
            setProductAssociationSelected(productId.value)
          }
        />

        <Popconfirm
          title="Adicionar produto para um usuário"
          description="Tem certeza que deseja associar esse produto ?"
          onConfirm={addProductToUser}
          okText="Confirmar"
          cancelText="Não"
        >
          <Button
            style={{
              marginLeft: 15,
              minWidth: 200,
              fontSize: 14,
            }}
            size="large"
            type="primary"
          >
            Adicionar
          </Button>
        </Popconfirm>
      </AssociationProductContentSelect>

      <Table columns={columns} dataSource={userProductsAssociations} />
    </AssociationProductContent>
  );

  const tabsContent = useMemo(
    () => [
      {
        label: 'Editar usuário',
        key: 1,
        children: formContent,
      },
      {
        label: 'Associação de produtos',
        key: 2,
        children: associationProductContent,
      },
    ],
    [
      initialValues,
      userProductsAssociations,
      products,
      productAssociationSelected,
    ],
  );

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="/usuarios">
          <NavLink to="/usuarios">Usuários</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Atualização</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle title="Atualização de usuário" />

      {userUpdateErroMessage && (
        <Alert
          message="Erro ao atribuir"
          description={userUpdateErroMessage}
          type="error"
          showIcon
          style={{ marginBottom: 30 }}
        />
      )}

      {!loading && (
        <Tabs
          defaultActiveKey="1"
          centered
          items={tabsContent.map((content) => content)}
        />
      )}

      {loading && <Loading />}
    </Container>
  );
}
