import { Button, Table, Tag, Tooltip } from 'antd';
import React from 'react';
import { MdDeleteOutline, MdModeEditOutline } from 'react-icons/md';
import { NavLink } from 'react-router-dom';

import { Pagination } from './styles';
import { getProductTypeColor } from '../../../../utils/getProductTypeColor';
import { getProductTypeName } from '../../../../utils/getProductTypeName';

export function ProductsTable({
  products,
  loading,
  paginationPage,
  setPaginationPage,
  productsTotal,
  isSearching,
  setProductToRemove,
  setShowModalDeleteProduct,
}) {
  const columns = [
    {
      title: 'Código',
      dataIndex: 'code',
      key: '1',
      sorter: (a, b) => a.product_id - b.product_id,
    },
    {
      title: 'Nome',
      dataIndex: 'name_formatted',
      key: '2',
      render: (_, { name, name_formated }) => (
        <Tooltip title={name}>{name_formated}</Tooltip>
      ),
    },
    {
      title: 'Descrição',
      dataIndex: 'product_overview_formmated',
      key: '3',
      render: (_, { product_overview, product_overview_formmated }) => (
        <Tooltip title={product_overview}>{product_overview_formmated}</Tooltip>
      ),
    },
    {
      title: 'Preço',
      dataIndex: 'price_formatted',
      key: '4',
    },
    {
      title: 'Pagamento',
      key: '5',
      dataIndex: 'payment_status',
      render: (_, { product_type }) => (
        <>
          {product_type.map((tag) => (
            <Tag color={getProductTypeColor(tag)} key="nice">
              {getProductTypeName(tag)}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (product) => (
        <div>
          <Tooltip title="Editar produto">
            <NavLink to={`/produtos/editar/${product.id}`}>
              <Button
                icon={<MdModeEditOutline size={20} color="#fff" />}
                style={{ background: '#1677ff' }}
              />
            </NavLink>
          </Tooltip>

          <Tooltip title="Remover produto">
            <Button
              icon={<MdDeleteOutline size={20} color="#fff" />}
              style={{ background: '#ff4d4f', marginLeft: 15 }}
              onClick={() => {
                setProductToRemove(product);
                setShowModalDeleteProduct(true);
              }}
            />
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={products}
        loading={loading}
        footer={() => `${productsTotal} produtos`}
        pagination={false}
      />

      {!isSearching && (
        <Pagination
          onChange={setPaginationPage}
          current={paginationPage}
          total={productsTotal}
          showSizeChanger={false}
          defaultPageSize={2}
        />
      )}
    </>
  );
}
