/* eslint-disable prettier/prettier */
import React from 'react';
import { Button, Popconfirm, Tooltip } from 'antd';
import { RollbackOutlined } from '@ant-design/icons';
import { Container } from './styles';
import { QuestionsPrint } from '../QuestionsPrint';

export function CorrectionActions({
  isAdminUserProofreader,
  isCorrectionFinish,
  isCorrectionInProgress,
  isCorrectionUnaffordable,
  validateCorrection,
  buttonLoading,
  validateCorrectionToSendRevision,
  unbindCorrection,
  removeCorrectionLoading,
  questionsAnswer,
  order,
  isCorrectionBlocked,
}) {
  return (
    <Container>
      <div>
        {!isAdminUserProofreader && !isCorrectionFinish && !isCorrectionBlocked && (
        <Button
          style={{ marginTop: 20, background: '#23A847', color: '#fff' }}
          type="primary"
          disabled={isCorrectionInProgress || isCorrectionUnaffordable}
          onClick={validateCorrection}
          loading={buttonLoading}
        >
          Finalizar
        </Button>
        )}

        {/* Botão devolver para especialista */}
        {!isAdminUserProofreader &&
        !isCorrectionFinish &&
        !isCorrectionBlocked && (
          <Button
            style={{
              marginBottom: 20,
              marginTop: 20,
              background: '#1677ff',
              color: '#fff',
            }}
            type="link"
            onClick={validateCorrectionToSendRevision}
            disabled={isCorrectionInProgress || isCorrectionUnaffordable}
          >
            Devolver para especialista
          </Button>
          // eslint-disable-next-line prettier/prettier
        )}

        {/* Botão Enviar para revisão do coordenador */}
        {isAdminUserProofreader &&
        !isCorrectionFinish &&
        !isCorrectionBlocked && (
          <Button
            style={{
              marginBottom: 20,
              marginTop: 20,
              background: '#1677ff',
              color: '#fff',
            }}
            type="link"
            onClick={validateCorrectionToSendRevision}
            disabled={isCorrectionBlocked || isCorrectionUnaffordable}
          >
            Enviar para revisão do coordenador
          </Button>
        )}

        {!isAdminUserProofreader && !isCorrectionFinish && (
        <Tooltip title="Remover recurso do especialista">
          <Popconfirm
            title="Remover recurso do especialista"
            description="Ao remover recurso, o pedido estará livre para outros especialistas"
            onConfirm={unbindCorrection}
            okText="Confirmar"
            cancelText="Não"
            loading={removeCorrectionLoading}
          >
            <Button
              style={{ marginBottom: 20, background: '#D4380D' }}
              icon={<RollbackOutlined />}
              type="primary"
              disabled={isCorrectionUnaffordable}
            >
              Desvilcular recurso
            </Button>
          </Popconfirm>
        </Tooltip>
        )}

        {!!questionsAnswer.length && (
        <QuestionsPrint questionsAnswer={questionsAnswer} order={order} />
        )}
      </div>

    </Container>
  );
}
