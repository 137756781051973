/* eslint-disable jsx-a11y/media-has-caption */
/* eslint-disable prettier/prettier */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-no-bind */
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import ReactPlayer from 'react-player';
import { toast } from 'react-toastify';
import { BoxInput, UploadVideo } from './styles';

import { api } from '../../../services/api';

export function ModalVirtualDispatcherConfirmation({
  setIsModalVirtualDispatcherConfirmationOpen,
  isModalVirtualDispatcherConfirmationOpen,
  virtualDispatcherConfirmationObservation,
  setVirtualDispatcherConfirmationObservation,
  virtualDispatcherConfirmationVideo,
  setVirtualDispatcherConfirmationVideo,
}) {
  const [buttonLoading, setButtonLoading] = useState(false);

  async function handleContentFile(event) {
    try {
      setButtonLoading(true);
      if (event.target.files) {
        const file = event.target.files[0];

        const formData = new FormData();
        formData.append('file', file, file.name);

        const response = await api.post(
          '/admin/sessions/lesson-content/upload',
          formData,
        );

        setButtonLoading(false);
      }
    } catch (error) {
      setButtonLoading(false);
      toast.error('Error ao fazer upload de imagem');
    }
  }

  return (
    <Modal
      title="Confirmação de envio do despachante"
      open={isModalVirtualDispatcherConfirmationOpen}
      footer={null}
      onCancel={() => setIsModalVirtualDispatcherConfirmationOpen(false)}
      width={900}
    >

      <UploadVideo>
        <h3>
          Vídeo
        </h3>

        <div className="video">
          {
            virtualDispatcherConfirmationVideo && (
            <ReactPlayer controls url={URL.createObjectURL(virtualDispatcherConfirmationVideo)} />
            )
          }

          <label htmlFor="avatar" className="">
            <div className="button">
              <UploadOutlined />
              Fazer upload
            </div>

            <input
              id="avatar"
              type="file"
              onChange={(event) => setVirtualDispatcherConfirmationVideo(event.target.files[0])}
            />
          </label>
        </div>
      </UploadVideo>

      <BoxInput>
        <label htmlFor="description">
          Observação
        </label>
        <textarea
          value={virtualDispatcherConfirmationObservation}
          onChange={(event) => setVirtualDispatcherConfirmationObservation(event.target.value)}
        />

      </BoxInput>

      <div>
        <Button loading={buttonLoading} type="primary">Enviar</Button>
      </div>
    </Modal>
  );
}
