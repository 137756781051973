/* eslint-disable react/jsx-no-bind */
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Select, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import { BoxForm, BoxInput, ButtonContainer, Container } from './styles';
import { PageTitle } from '../../components/PageTitle';
import { api } from '../../services/api';
import { Loading } from '../../components/Loading';

const { Option } = Select;

export function TechnicalTeamUpdate() {
  const [initialValues, setInitialValues] = useState(null);
  const [buttonLoading, setButtonLoading] = useState(false);
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [isShowHome, setIsShowHome] = useState(false);
  const [productCategoryId, setProductCategoryId] = useState('');
  const [productCategoryIdList, setProductCategoryIdList] = useState([]);
  const [testimonialToUpload, setTestimonialToUpload] = useState();
  const [testimonialPreview, setTestimonialPreview] = useState(null);

  const { testimonial_id } = useParams();

  async function updateTestimonials(values) {
    setButtonLoading(true);
    try {
      const formData = new FormData();

      if (testimonialToUpload) {
        formData.append('file', testimonialToUpload, testimonialToUpload.name);
      }

      const { name, text, position } = values;

      formData.append('name', name);
      formData.append('text', text);
      formData.append('is_show_home', isShowHome);
      formData.append('product_category_id', productCategoryId);
      formData.append('position', position);

      await api.put(`/technical-team/${testimonial_id}`, formData);
      toast.success('corpo técnico cadastrado com sucesso');

      setButtonLoading(false);
      navigate(-1);
    } catch (err) {
      toast.error('Erro ao cadastrar corpo técnico');
      setButtonLoading(false);
    }
  }

  async function loadTestimonial() {
    try {
      const response = await api.get(`/technical-team/show/${testimonial_id}`);

      setInitialValues(response.data);
      setIsShowHome(response.data.is_show_home);
      setProductCategoryId(response.data.productCategoryId);
      setTestimonialPreview(response.data.photo_url);
      setLoading(false);
    } catch (err) {
      toast.error('Erro ao carregar corpo técnico');
      setLoading(false);
    }
  }

  useEffect(() => {
    loadTestimonial();
  }, []);

  function handleProductCategoryId(type) {
    setProductCategoryId(type);
  }

  async function loadProductCategories() {
    try {
      const response = await api.get('/products/categories');
      setProductCategoryIdList(response.data);
    } catch (error) {
      toast.error('Erro ao carregar categorias do produto');
    }
  }

  useEffect(() => {
    loadProductCategories();
  }, []);

  function handleTestimonialPhono(event) {
    if (event.target.files) {
      const file = event.target.files[0];
      setTestimonialToUpload(file);

      const reader = new FileReader();

      reader.onload = () => {
        setTestimonialPreview(reader.result);
      };

      if (file) {
        reader.readAsDataURL(file);
      }
    }
  }

  console.log('initialValues', initialValues, productCategoryId);
  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="/corpo-tecnico">
          <NavLink to="/corpo-tecnico">Corpo técnico</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Criação</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Cadastro de corpo técnico"
        description="Gerenciamento de  corpo técnico"
      />

      {!loading && initialValues && (
        <BoxForm>
          <Formik initialValues={initialValues} onSubmit={updateTestimonials}>
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <img src={testimonialPreview} alt="" />
                <BoxInput style={{ marginTop: 30 }}>
                  <label htmlFor="photo">Foto</label>
                  <input
                    id="photo"
                    type="file"
                    name="photo"
                    onChange={handleTestimonialPhono}
                  />
                </BoxInput>

                <BoxInput>
                  <label htmlFor="name">Nome</label>
                  <Field id="name" type="text" name="name" placeholder="Nome" />
                </BoxInput>

                <BoxInput>
                  <label htmlFor="position">Cargo</label>
                  <Field
                    id="position"
                    type="text"
                    name="position"
                    placeholder="Cargo"
                  />
                </BoxInput>

                <BoxInput>
                  <label htmlFor="text">Texto</label>
                  <Field name="text">
                    {({ field }) => (
                      <div>
                        <textarea {...field} value={field.value || ''} />
                      </div>
                    )}
                  </Field>
                </BoxInput>

                <BoxInput>
                  <label htmlFor="name">Tipo de usuário</label>
                  <Select
                    defaultValue={
                      initialValues.productCategory
                        ? initialValues.productCategory.name
                        : 'Selecione o tipo de usuário'
                    }
                    style={{ width: 300, height: 38 }}
                    onChange={handleProductCategoryId}
                  >
                    {productCategoryIdList.length &&
                      productCategoryIdList.map((categoryItem) => (
                        <Option key={categoryItem.id}>
                          {categoryItem.name}
                        </Option>
                      ))}
                  </Select>
                </BoxInput>

                <BoxInput>
                  <label htmlFor="is_show_home">Mostrar na home</label>
                  <Switch
                    defaultChecked
                    checked={isShowHome}
                    onChange={() => setIsShowHome(!isShowHome)}
                  />
                </BoxInput>

                <ButtonContainer>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    loading={buttonLoading}
                  >
                    Atualizar corpo técnico
                  </Button>
                </ButtonContainer>
              </form>
            )}
          </Formik>
        </BoxForm>
      )}

      {loading && <Loading />}
    </Container>
  );
}
