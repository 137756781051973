/* eslint-disable react/jsx-curly-newline */
import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Divider } from 'antd';
import ReactDatePicker, { registerLocale } from 'react-datepicker';
import pt from 'date-fns/locale/pt';
import { SearchOutlined } from '@ant-design/icons';
import { toast } from 'react-toastify';
import {
  Container,
  DateInputBox,
  FilterActions,
  FilterOptions,
  FilterSelect,
} from './styles';
import { api } from '../../../services/api';

const CheckboxGroup = Checkbox.Group;

const filterCheckboxOptions = [
  {
    label: 'Recurso incabível',
    value: 'UNAFFORDABLE',
  },
];

export function Filters({
  search,
  clearSearch,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  setFilterProductSelected,
  setFilterProductCategorySelected,
  checkedFilters,
  setCheckedFilters,
}) {
  registerLocale('pt', pt);

  const hangleCheckboxFilter = (list) => {
    setCheckedFilters(list);
  };

  const [productCategories, setProductCategories] = useState([]);
  const [products, setProducts] = useState();

  async function getProductCategories() {
    try {
      const response = await api.get('/store/product-categories');

      setProductCategories(
        response.data.map((categoryItem) => ({
          value: categoryItem.id,
          label: categoryItem.name,
        })),
      );
    } catch (error) {
      toast.error('Erro ao obter categorias');
    }
  }

  async function readProducts() {
    try {
      const response = await api.get('products');

      const productsFormatted = response.data.map((product) => ({
        value: product.id,
        label: product.name,
      }));

      productsFormatted.unshift({
        value: '',
        label: 'Todos os produtos',
      });
      setProducts(productsFormatted);
    } catch (err) {
      toast.error('Erro ao ler produtos');
    }
  }

  useEffect(() => {
    getProductCategories();
    readProducts();
  }, []);
  return (
    <Container>
      <FilterOptions>
        <DateInputBox>
          <label>Data Inicial</label>
          <ReactDatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            locale="pt"
            dateFormat="dd/MM/yyyy"
          />
        </DateInputBox>

        <DateInputBox>
          <label>Data Final</label>
          <ReactDatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            locale="pt"
            dateFormat="dd/MM/yyyy"
          />
        </DateInputBox>

        <FilterSelect
          placeholder="Selecione um produto"
          options={products}
          onChange={(productId) => setFilterProductSelected(productId.value)}
        />

        <FilterSelect
          placeholder="Selecione uma categoria"
          options={productCategories}
          onChange={(productId) =>
            setFilterProductCategorySelected(productId.value)
          }
        />

        <CheckboxGroup
          style={{ marginTop: 15 }}
          options={filterCheckboxOptions}
          value={checkedFilters}
          onChange={hangleCheckboxFilter}
        />
      </FilterOptions>

      <FilterActions>
        <Button
          icon={<SearchOutlined />}
          style={{ marginLeft: 20 }}
          type="primary"
          onClick={search}
        >
          Buscar
        </Button>

        <Button type="link" onClick={clearSearch}>
          Limpar Busca
        </Button>
      </FilterActions>

      <Divider />
    </Container>
  );
}
