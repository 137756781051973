/* eslint-disable prettier/prettier */
/* eslint-disable no-confusing-arrow */
/* eslint-disable react/jsx-no-bind */
import { Modal } from 'antd';
import React from 'react';
import { Container } from './styles';

export function ModalProcuramentTerm({
  isModalProcuramentTermOpen,
  setIsModalProcuramentTermOpen,
  procuramentVirtual,
}) {
  return (
    <Modal
      open={isModalProcuramentTermOpen}
      footer={null}
      onCancel={() => setIsModalProcuramentTermOpen(false)}
      width={900}
    >
      <Container>{procuramentVirtual.accept_terms}</Container>
    </Modal>
  );
}
