import styled from 'styled-components/macro';

export const Container = styled.div`
  height: 100%;
  padding: 20px 50px;
  width: 100%;
  max-width: 1600px;
`;

export const BoxForm = styled.div`
  background: #fff;
  padding: 40px 30px;
  border-radius: 6px;
  box-shadow: rgba(0, 0, 0, 0.01) 0px 0px 0px 2px;
  min-height: 200px;

  h2 {
    font-size: 18px;
    margin-bottom: 40px;
  }

  &FormButton {
    margin-top: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;
