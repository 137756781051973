import { css } from 'styled-components';
import styled from 'styled-components/macro';

export const Container = styled.div`
  margin-top: 40px;

  .info {
    padding: 12px 0;

    strong {
      font-size: 18px;
      color: rgba(0, 0, 0, 0.88);
      margin-bottom: 8px;
      display: inline-block;
    }

    p {
      font-size: 14px;
      color: rgba(0, 0, 0, 0.45);
      line-height: 25px;
    }
  }

  .forms {
    margin-top: 20px;
    display: flex;

    .left-content {
      width: 70%;
      display: flex;
      flex-direction: column;
      padding-right: 50px;

      > div {
        width: 100%;
      }
    }

    .rigth-content {
      width: 30%;
    }
  }
`;

export const QuestionFiles = styled.div`
  img {
    width: 100%;
  }

  ul {
    display: flex;
  }
`;

export const QuestionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  .automatic-save {
    font-size: 14px;
    display: flex;
    align-items: center;
    color: #000000c4;

    svg {
      margin-right: 10px;
    }
  }
`;

export const BoxInput = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
  margin-right: 30px;

  &:last-child {
    margin-right: 0;
  }

  label {
    position: relative;
    display: inline-flex;
    align-items: center;
    height: 32px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 15px;
    margin-right: 20px;
  }

  :global(#background) {
    display: none;
  }

  input {
    box-sizing: border-box;
    margin: 0;
    padding: 7px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
      helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
      segoe ui emoji, segoe ui symbol, noto color emoji;
    position: relative;
    display: inline-block;
    width: 300px;
    min-width: 0;
    background-color: #fff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
  }

  textarea {
    box-sizing: border-box;
    margin: 0;
    padding: 4px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
      helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
      segoe ui emoji, segoe ui symbol, noto color emoji;
    position: relative;
    display: inline-block;
    width: 100%;
    height: 200px;
    min-width: 0;
    background-color: #fff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
  }

  .question-footer {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    color: rgba(0, 0, 0, 0.88);
    font-size: 15px;
  }

  ${({ isCharacterLimitOver }) =>
    isCharacterLimitOver &&
    css`
      textarea {
        border: 1px solid red;
      }

      input {
        border: 1px solid red;
        color: red;
      }

      label {
        color: red;
      }

      .question-footer {
        span {
          color: red;
        }
      }
    `}
`;
