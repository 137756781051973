/* eslint-disable react/jsx-no-bind */
import { Button, Modal, Result } from 'antd';
import React from 'react';

export function ModalQuestionEmpty({
  isModalQuestionEmpty,
  setIsModalQuestionEmpty,
}) {
  return (
    <Modal
      title="Existem questões em branco"
      open={isModalQuestionEmpty}
      footer={null}
      onCancel={() => setIsModalQuestionEmpty(false)}
      width={700}
    >
      <Result
        status="warning"
        title="Existem questões em branco, tem certeza que quer enviar para o coordenador ?"
      />

      <div style={{ textAlign: 'center', marginTop: 50 }}>
        <Button type="primary" onClick={() => setIsModalQuestionEmpty(false)}>
          Corrigir
        </Button>
      </div>
    </Modal>
  );
}
