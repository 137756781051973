/* eslint-disable react/jsx-indent-props */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable prettier/prettier */
/* eslint-disable react/jsx-no-bind */
import React, { useEffect, useState } from 'react';
import { Breadcrumb, Button, Popconfirm } from 'antd';
import { NavLink } from 'react-router-dom';
import { HomeOutlined } from '@ant-design/icons';
import Select from 'react-select';
import { toast } from 'react-toastify';
import { DragDropContext, Draggable, Droppable } from '@hello-pangea/dnd';

import { MdDeleteOutline, MdVisibility } from 'react-icons/md';
import {
  AssociationProductContent,
  AssociationProductContentSelect,
  Container,
  ProductList,
  ProductListItem,
} from './styles';
import { PageTitle } from '../../components/PageTitle';
import { Loading } from '../../components/Loading';

import { api } from '../../services/api';

export function HomeProducts() {
  const [loading, setLoading] = useState(false);
  const [products, setProducts] = useState([]);
  const [productAssociationSelected, setProductAssociationSelected] =
    useState('');

  const [homeProducts, setHomeProducts] = useState([]);

  async function readProducts() {
    try {
      const response = await api.get('/products');

      setProducts(
        response.data.map((product) => ({
          value: product.id,
          label: product.name,
        })),
      );
    } catch (err) {
      toast.error('Erro ao ler produtos');
    }
  }

  async function readHomeProducts() {
    try {
      setLoading(true);
      const response = await api.get('/home-products');

      setHomeProducts(response.data);
      setLoading(false);
    } catch (err) {
      setLoading(false);
      toast.error('Erro ao ler produtos');
    }
  }

  async function addHomeProduct() {
    try {
      await api.post(`/home-products/${productAssociationSelected}`);

      readHomeProducts();
      toast.success('Produto adicionado com sucesso');
    } catch (error) {
      toast.error('Erro ao ao adicionar produto');
    }
  }

  useEffect(() => {
    readProducts();
    readHomeProducts();
  }, []);

  function handleOnDragEnd(result) {
    if (!result.destination) return;

    const items = Array.from(homeProducts);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setHomeProducts(items);
  }

  async function removeProductHome(productId) {
    try {
      await api.delete(`/home-products/${productId}`);
      toast.success('Produto removido com sucesso');
      readHomeProducts();
    } catch (error) {
      toast.error('Erro ao remover produto da home');
    }
  }

  async function sortProductHome() {
    try {
      await api.post('/home-products/sort/products', {
        home_products: homeProducts,
      });
      toast.success('Produtos ordenado com sucesso');
      readHomeProducts();
    } catch (error) {
      toast.error('Erro ao ordenar produtos');
    }
  }

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Produtos Destaque</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Produtos Destaque"
        description="Produtos destaque da home"
      />

      <AssociationProductContent>
        <h3>Selecione um produto que o usuário poderá trabalhar</h3>
        <AssociationProductContentSelect>
          <Select
            options={products}
            onChange={(productId) =>
              setProductAssociationSelected(productId.value)}
          />

          <Popconfirm
            title="Adicionar produto na home"
            description="Tem certeza que deseja adicionar esse produto ?"
            onConfirm={addHomeProduct}
            okText="Confirmar"
            cancelText="Não"
          >
            <Button
              style={{
                marginLeft: 15,
                minWidth: 200,
                fontSize: 14,
              }}
              size="large"
              type="primary"
            >
              Adicionar
            </Button>
          </Popconfirm>
        </AssociationProductContentSelect>
      </AssociationProductContent>

      {
        !loading && (
          <>

            <DragDropContext onDragEnd={handleOnDragEnd}>
              <Droppable droppableId="characters">
                {(provided) => (
                  <ProductList
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {homeProducts.map((productItem, index) => (
                      <Draggable
                        key={productItem.id}
                        draggableId={productItem.id}
                        index={index}
                      >
                        {(providedDrag) => (
                          <ProductListItem
                            ref={providedDrag.innerRef}
                            {...providedDrag.draggableProps}
                            {...providedDrag.dragHandleProps}
                          >
                            <div className="info">
                              <strong>{productItem.product.name}</strong>
                              <p>{productItem.product.description}</p>
                            </div>

                            <div className="actions">
                              <a target="_blank" href={`/produtos/editar/${productItem.product.id}`} rel="noreferrer">
                                <Button type="primary" style={{ background: '#1677ff' }} icon={<MdVisibility size={20} color="#fff" />} />
                              </a>

                              <Popconfirm
                                title="Remover produto da home"
                                description="Tem certeza que deseja remover o produto ?"
                                onConfirm={() => removeProductHome(productItem.id)}
                                okText="Confirmar"
                                cancelText="Não"
                              >
                                <Button
                                icon={<MdDeleteOutline size={20} color="#fff" />}
                                style={{ background: '#ff4d4f', marginLeft: 15 }}
                                color="green"
                                type="primary"
                                />
                              </Popconfirm>

                            </div>

                          </ProductListItem>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </ProductList>
                )}
              </Droppable>
            </DragDropContext>

            <div className="button-container">
              <Button type="primary" onClick={sortProductHome}> Salvar Ordenação</Button>
            </div>
          </>
        )
      }

      {
        loading && (
          <Loading />
        )
      }

    </Container>
  );
}
