import { Modal } from 'antd';
import React from 'react';
import { CorrectionInfo } from '../CorrectionInfo';

export function ModalCorrectionInfo({
  isModalCorrectionInfoOpen,
  setIsModalCorrectionInfoOpen,
  order,
}) {
  return (
    <Modal
      open={isModalCorrectionInfoOpen}
      footer={null}
      onCancel={() => setIsModalCorrectionInfoOpen(false)}
      width={1200}
    >
      <CorrectionInfo order={order} />
    </Modal>
  );
}
