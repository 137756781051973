export function getOrderPaymentStatusColor(type) {
  switch (type) {
    case 'STARTED':
      return '#2db7f5';
    case 'STARTED_PIX':
      return '#2db7f5';
    case 'AUTHORIZED':
      return 'orange';
    case 'PAID':
      return 'green';
    case 'IN_ANALYSIS':
      return 'orange';
    case 'DECLINED':
      return 'red';
    case 'CANCELED':
      return 'red';
    case 'PRE_ANALYSIS':
      return 'orange';
    default:
      return '';
  }
}
