import styled from 'styled-components/macro';

export const Container = styled.div`
  width: 100%;
`;

export const ProductQuestionsBox = styled.div`
  width: 100%;

  > div {
    border-block-end: 1px solid rgba(5, 5, 5, 0.06);
    display: flex;
    justify-content: space-between;
    padding: 12px 0;

    .actions {
      button {
        margin-left: 15px;
      }
    }
  }
`;

export const ProductQuestionContent = styled.div`
  margin-bottom: 10px;
  width: 100%;

  > div {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  strong {
    color: rgba(0, 0, 0, 0.88);
    margin-bottom: 8px;
    display: inline-block;
  }

  p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 25px;
  }
`;

export const ProductQuestionOptions = styled.div`
  display: flex;
`;

export const SubQuestions = styled.div`
  margin-top: 20px;
  padding-left: 40px;

  strong {
    color: rgba(0, 0, 0, 0.88);
    margin-bottom: 8px;
    display: inline-block;
  }

  p {
    font-size: 14px;
    color: rgba(0, 0, 0, 0.45);
    line-height: 25px;
  }
`;
