import { Button, Modal, Result } from 'antd';
import React from 'react';

export function ModalUnaffordableResource({
  isModalUnaffordableOpen,
  setIsModalUnaffordableOpen,
  unaffordableCorrection,
  buttonUnaffordableLoading,
}) {
  return (
    <Modal
      open={isModalUnaffordableOpen}
      footer={null}
      onCancel={() => setIsModalUnaffordableOpen(false)}
      width={700}
    >
      <Result
        status="warning"
        title="Tem certeza que deseja definir a correção como recurso incabível ?"
      />

      <div style={{ textAlign: 'center', marginTop: 50 }}>
        <Button
          style={{ background: '#ff4d4f' }}
          color="green"
          type="primary"
          onClick={unaffordableCorrection}
          loading={buttonUnaffordableLoading}
        >
          Enviar
        </Button>
      </div>
    </Modal>
  );
}
