export function getOrderCorrectionStatusColor(type) {
  switch (type) {
    case 'NOT_STARTED':
      return 'red';
    case 'STARTED':
      return 'orange';
    case 'IN_PROGRESS':
      return 'orange';
    case 'COORDINATOR_REVISION':
      return '#2db7f5';
    case 'PROOFREADER_CORRECTION':
      return 'red';
    case 'COMPLETED':
      return 'green';
    case 'UNAFFORDABLE':
      return 'red';
    case 'PRE_ANALYSIS_APPROVED':
      return 'green';
    case 'PRE_ANALYSIS_UNAFFORDABLE':
      return 'red';
    case 'PRE_ANALYSIS_PENDING':
      return 'orange';
    case 'CANCELED':
      return 'red';
    case 'SENDING_DOCUMENTS':
      return 'orange';
    default:
      return '';
  }
}
