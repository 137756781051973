/* eslint-disable react/jsx-no-bind */
import { HomeOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Table,
  Space,
  Tag,
  Tooltip,
  Button,
  Popconfirm,
} from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdDeleteOutline, MdOutlineMode, MdVisibility } from 'react-icons/md';
import { Container } from './styles';
import { PageTitle } from '../../components/PageTitle';

import { api } from '../../services/api';

const userNames = {
  all: 'Todos',
  proofreader: 'Especialista',
  coordinator: 'Coordenador',
};

export function MessageCenter() {
  const [centralMessages, setCentralMessages] = useState([]);
  // const [loading, setLoading] = useState(false);

  async function loadMessageCenter() {
    try {
      const response = await api.get('/message-center');
      const usersFormmated = response.data.map((message, index) => ({
        ...message,
        key: index,
        tags: [message.allow_to],
      }));

      setCentralMessages(usersFormmated);
    } catch (error) {
      toast.error('Erro ao carregar usuários');
    }
  }

  async function confirmMessegeDelete(messageId) {
    try {
      await api.delete(`/message-center/${messageId}`);
      loadMessageCenter();
      toast.success('Mensagem remvida com sucesso');
    } catch (error) {
      toast.error('Erro ao carregar usuários');
    }
  }

  // Filters
  const columns = [
    {
      title: 'Título',
      dataIndex: 'title',
      key: 'title',
    },
    {
      title: 'Mensagem',
      dataIndex: 'message',
      key: 'message',
      render: (messageItem) => (
        <div
          style={{
            maxWidth: '250px',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
          }}
        >
          {messageItem}
        </div>
      ),
    },
    {
      title: 'Habilitada',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = 'green';
            if (tag === 'all') {
              color = 'volcano';
            }

            if (tag === 'proofreader') {
              color = 'cyan';
            }

            return (
              <Tag color={color} key="nice">
                {userNames[tag]}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (user) => (
        <div>
          <Tooltip title="Visualizar mensagem">
            <NavLink to={`/central-mensagens/editar/${user.id}`}>
              <Button
                icon={<MdOutlineMode size={20} color="#fff" />}
                style={{ margin: '0 10px', background: '#1677ff' }}
              />
            </NavLink>
          </Tooltip>

          <Popconfirm
            title="Remover mensagem"
            description="Tem certeza que deseja remover a mensagem ?"
            onConfirm={() => confirmMessegeDelete(user.id)}
            okText="Confirmar"
            cancelText="Não"
          >
            <Tooltip title="Deletar mensagem">
              <Button
                icon={<MdDeleteOutline size={20} color="#fff" />}
                style={{ background: '#ff4d4f' }}
              />
            </Tooltip>
          </Popconfirm>
        </div>
      ),
    },
  ];

  useEffect(() => {
    loadMessageCenter();
  }, []);

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Central de mensagens</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Central de mensagens"
        description="Mensagens de e-mail dos coordenadores e especialistas"
        link="/central-mensagens/criar"
        linkTitle="Cadastrar mensagem"
      />
      <Table columns={columns} dataSource={centralMessages} />
    </Container>
  );
}
