/* eslint-disable react/jsx-no-bind */
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Switch } from 'antd';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { BoxForm, Container } from './styles';
import { PageTitle } from '../../components/PageTitle';
import { Loading } from '../../components/Loading';

import { api } from '../../services/api';
import { BoxInput } from '../UsersUpdate/styles';
import { ButtonContainer } from '../../components/ButtonContainer';

export function StoreConfings() {
  const [storeConfigs, setStoreConfigs] = useState({
    show_whatsapp_button: false,
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  function handleUpdateWhatsappConfig() {
    setStoreConfigs((prevState) => ({
      show_whatsapp_button: !prevState.show_whatsapp_button,
    }));
  }

  async function handleUpdateStoreConfigs() {
    try {
      setButtonLoading(true);
      await api.put('/admin/store-configs', {
        show_whatsapp_button: storeConfigs.show_whatsapp_button,
      });
      setButtonLoading(false);
      toast.success('Configurações atualizada com sucesso');
    } catch (error) {
      setButtonLoading(false);
      toast.error('Erro au atualizar configurações');
    }
  }

  async function loadStoreConfigs() {
    try {
      setLoading(true);
      const response = await api.get('/admin/store-configs');
      setStoreConfigs(response.data);
      setLoading(false);
    } catch (error) {
      toast.error('Erro ao carregar configurações');
      setLoading(false);
    }
  }

  useEffect(() => {
    loadStoreConfigs();
  }, []);

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Configurações</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle title="Configurações" description="Configurações da loja" />

      {!loading && (
        <BoxForm>
          <BoxInput>
            <label htmlFor="available_for_sale">Mostrar no checkout ?</label>
            <Switch
              defaultChecked
              checked={storeConfigs.show_whatsapp_button}
              onChange={handleUpdateWhatsappConfig}
            />
          </BoxInput>

          <ButtonContainer position="center">
            <Button
              onClick={handleUpdateStoreConfigs}
              type="primary"
              size="middle"
              loading={buttonLoading}
            >
              Atualizar configurações
            </Button>
          </ButtonContainer>
        </BoxForm>
      )}

      {loading && <Loading />}
    </Container>
  );
}
