import styled from 'styled-components/macro';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 50px;
`;

export const BoxInput = styled.div`
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  input {
    box-sizing: border-box;
    margin: 0;
    padding: 7px 11px;
    color: rgba(0, 0, 0, 0.88);
    font-size: 14px;
    line-height: 1.5714285714285714;
    list-style: none;
    font-family: -apple-system, BlinkMacSystemFont, segoe ui, Roboto,
      helvetica neue, Arial, noto sans, sans-serif, apple color emoji,
      segoe ui emoji, segoe ui symbol, noto color emoji;
    position: relative;
    display: flex;
    width: 100%;
    min-width: 500px;
    background-color: #fff;
    background-image: none;
    border-width: 1px;
    border-style: solid;
    border-color: #d9d9d9;
    border-radius: 6px;
    transition: all 0.2s;
  }
`;

export const BoxSelect = styled.div``;

export const SearchBar = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 15px;
`;

export const FilterOptions = styled.div`
  display: flex;
`;

export const DateInputBox = styled.div`
  margin-right: 15px;

  label {
    margin-right: 10px;
    font-size: 14px;
  }

  input {
    min-width: 260px;
    height: 38px;
    background-color: #ffffff;
    border: 1px solid rgb(217, 217, 217);
    padding: 4px 15px;
    border-radius: 6px;
  }
`;
