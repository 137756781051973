/* eslint-disable react/jsx-no-bind */
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button } from 'antd';
import React, { useState, useEffect } from 'react';
import { NavLink, useNavigate, useParams } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import { BoxForm, BoxInput, ButtonContainer, Container } from './styles';
import { PageTitle } from '../../components/PageTitle';
import { api } from '../../services/api';
import { Loading } from '../../components/Loading';

export function CategoriesUpdate() {
  const [initialValues, setInitialValues] = useState({
    name: '',
    code: '',
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const { product_categorie_id } = useParams();

  async function loadProductCategorie() {
    try {
      const response = await api.get(
        `/products/categories/${product_categorie_id}`,
      );

      setInitialValues(response.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error('Erro ao carregar dados da categoria');
    }
  }

  async function updateProductCategorie(values) {
    setButtonLoading(true);
    try {
      const { name } = values;

      await api.put(`/products/categories/${product_categorie_id}`, {
        name,
      });

      toast.success('Categoria atualizada com sucesso');
      setButtonLoading(false);
      navigate(-1);
    } catch (err) {
      toast.error('Erro ao atualizar categoria');
      setButtonLoading(false);
    }
  }

  useEffect(() => {
    loadProductCategorie();
  }, []);

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="/categorias">
          <NavLink to="/categorias">Categorias</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Criação</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle title="Cadastro de categoria" />

      {!loading && (
        <BoxForm>
          <Formik
            initialValues={initialValues}
            onSubmit={updateProductCategorie}
          >
            {({ handleSubmit }) => (
              <form onSubmit={handleSubmit}>
                <BoxInput>
                  <label htmlFor="code">Código</label>
                  <Field
                    id="code"
                    type="text"
                    name="code"
                    placeholder="Nome"
                    disabled
                  />
                </BoxInput>

                <BoxInput>
                  <label htmlFor="name">Nome</label>
                  <Field id="name" type="text" name="name" placeholder="Nome" />
                </BoxInput>

                <ButtonContainer>
                  <Button
                    type="primary"
                    htmlType="submit"
                    size="middle"
                    loading={buttonLoading}
                  >
                    Atualizar categoria
                  </Button>
                </ButtonContainer>
              </form>
            )}
          </Formik>
        </BoxForm>
      )}

      {loading && <Loading />}
    </Container>
  );
}
