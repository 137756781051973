/* eslint-disable react/jsx-no-bind */
import { HomeOutlined } from '@ant-design/icons';
import {
  Breadcrumb,
  Table,
  Space,
  Tag,
  Tooltip,
  Button,
  Popconfirm,
} from 'antd';
import React, { useEffect, useState, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import { MdDeleteOutline, MdVisibility } from 'react-icons/md';
import { Container } from './styles';
import { PageTitle } from '../../components/PageTitle';

import { api } from '../../services/api';
import { Filters } from './components/Filters';

const userNames = {
  admin: 'Admin',
  master: 'Admin Master',
  proofreader: 'Especialista',
  coordinator: 'Coordenador',
};

export function Users() {
  const [users, setUsers] = useState([]);
  // const [loading, setLoading] = useState(false);

  // Filters
  const [userType, setUserType] = useState('');
  const [searchWord, setSearchWord] = useState('');

  async function loadUsers() {
    try {
      const response = await api.get('/admin-users');
      const usersFormmated = response.data.map((user, index) => ({
        ...user,
        key: index,
        tags: [user.permission_level],
      }));

      setUsers(usersFormmated);
    } catch (error) {
      toast.error('Erro ao carregar usuários');
    }
  }

  useEffect(() => {
    loadUsers();
  }, []);

  const searchUser = useCallback(
    async (type) => {
      try {
        const response = await api.get(
          `/admin-users/s/search?search=${searchWord}&permission_level=${
            type || userType
          }`,
        );

        const usersFormmated = response.data.map((user, index) => ({
          ...user,
          key: index,
          tags: [user.permission_level],
        }));

        setUsers(usersFormmated);
      } catch (error) {
        toast.error('Erro ao buscar usuários');
      }
    },
    [userType, searchWord],
  );

  async function deleteUser(userId) {
    try {
      await api.delete(`/admin-users/${userId}`);
      toast.success('Usuário removido com sucesso');
      loadUsers();
    } catch (error) {
      toast.error('Error ao remover usuário');
    }
  }

  const columns = [
    {
      title: 'Nome',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'E-mail',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Tipo de usuário',
      key: 'tags',
      dataIndex: 'tags',
      render: (_, { tags }) => (
        <>
          {tags.map((tag) => {
            let color = 'green';
            if (tag === 'admin' || tag === 'master') {
              color = 'volcano';
            }

            if (tag === 'proofreader') {
              color = 'cyan';
            }

            return (
              <Tag color={color} key="nice">
                {userNames[tag]}
              </Tag>
            );
          })}
        </>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (user) => (
        <div>
          <Tooltip title="Visualizar dados do pedido">
            <NavLink to={`/usuarios/editar/${user.id}`}>
              <Button
                icon={<MdVisibility size={20} color="#fff" />}
                href="https://www.google.com"
                style={{ background: '#1677ff' }}
              />
            </NavLink>
          </Tooltip>

          <Tooltip title="Remover usuário">
            <Popconfirm
              title="Remover usuário"
              description="Tem certeza que deseja remover este usuário ?"
              onConfirm={() => deleteUser(user.id)}
              okText="Confirmar"
              cancelText="Não"
            >
              <Button
                icon={<MdDeleteOutline size={20} color="#fff" />}
                style={{ background: '#ff4d4f', marginLeft: 15 }}
              />
            </Popconfirm>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Usuários</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Gerenciamento de usuários"
        description="Gerenciamento de coordenadores e especialistas"
        link="/usuarios/criar"
        linkTitle="Cadastrar usuário"
      />

      <Filters
        setUserType={setUserType}
        userType={userType}
        setSearchWord={setSearchWord}
        searchUser={searchUser}
      />

      <Table columns={columns} dataSource={users} />
    </Container>
  );
}
