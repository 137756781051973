/* eslint-disable react/jsx-no-bind */
import { HomeOutlined } from '@ant-design/icons';
import { Breadcrumb, Button, Select } from 'antd';
import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { Field, Formik } from 'formik';
import { toast } from 'react-toastify';
import { BoxForm, BoxInput, ButtonContainer, Container } from './styles';
import { PageTitle } from '../../components/PageTitle';
import { api } from '../../services/api';

const { Option } = Select;
export function MessageCenterCreate() {
  const [initialValues] = useState({
    title: '',
    message: '',
    allow_to: '',
  });
  const [buttonLoading, setButtonLoading] = useState(false);
  const [messageAllowTo, setMessageAllowTo] = useState('all');
  const [emailMessage, setEmailMessage] = useState('');

  const navigate = useNavigate();

  async function createMessageCenter(values) {
    setButtonLoading(true);
    try {
      const { title } = values;

      await api.post('/message-center', {
        title,
        message: emailMessage,
        allow_to: messageAllowTo,
      });
      toast.success('Mensagem cadastrada com sucesso');

      setButtonLoading(false);
      navigate(-1);
    } catch (err) {
      toast.error('Erro ao cadastrar mensagem');
      setButtonLoading(false);
    }
  }

  function handleUserType(type) {
    setMessageAllowTo(type);
  }

  return (
    <Container>
      <Breadcrumb style={{ marginBottom: 40 }}>
        <Breadcrumb.Item key="/">
          <NavLink to="/">
            <HomeOutlined />
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item key="/central-mensagens">
          <NavLink to="/central-mensagens">Central de Mensagens</NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item>Criação</Breadcrumb.Item>
      </Breadcrumb>

      <PageTitle
        title="Cadastro de mensagens"
        description="Mensagens para coordenadores e especialistas"
      />

      <BoxForm>
        <Formik initialValues={initialValues} onSubmit={createMessageCenter}>
          {({ handleSubmit }) => (
            <form onSubmit={handleSubmit}>
              <BoxInput>
                <label htmlFor="name">Habilitado para</label>
                <Select
                  defaultValue="Selecione o tipo de usuário"
                  style={{ width: 300, height: 38 }}
                  onChange={handleUserType}
                >
                  <Option key="all">Todos</Option>
                  <Option key="coordinator">Coordenador</Option>
                  <Option key="proofreader">Especialista</Option>
                </Select>
              </BoxInput>

              <BoxInput>
                <label htmlFor="title">Título</label>
                <Field
                  id="title"
                  type="text"
                  name="title"
                  placeholder="Título do e-mail"
                />
              </BoxInput>

              <BoxInput>
                <label htmlFor="message">Mensagem</label>
                <textarea
                  value={emailMessage}
                  onChange={(event) => setEmailMessage(event.target.value)}
                  style={{ height: 400 }}
                />
              </BoxInput>

              <ButtonContainer>
                <Button
                  type="primary"
                  htmlType="submit"
                  size="middle"
                  loading={buttonLoading}
                >
                  Cadastrar mensagem
                </Button>
              </ButtonContainer>
            </form>
          )}
        </Formik>
      </BoxForm>
    </Container>
  );
}
