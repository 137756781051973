import { Button, Table, Tag, Tooltip } from 'antd';
import React from 'react';
import { MdOutlineNewspaper, MdVisibility } from 'react-icons/md';
import { NavLink } from 'react-router-dom';
import { getOrderCorrectionStatusColor } from '../../../../utils/getOrderCorrectionStatusColor';
import { getOrderCorrectionStatus } from '../../../../utils/getOrderCorrectionStatus';
import { useAuth } from '../../../../hooks/AuthContext';
import { Pagination } from './styles';

export function CorrectionsTable({
  corrections,
  loading,
  isSearching,
  setPaginationPage,
  paginationPage,
  correctionsTotal,
}) {
  const { user } = useAuth();

  const columns = [
    {
      title: 'Id',
      dataIndex: 'product_id',
      key: '1',
    },
    {
      title: 'Produto',
      dataIndex: 'product_name',
      key: '2',
    },
    {
      title: 'Pedido',
      dataIndex: 'order_id',
      key: '3',
    },
    {
      title: 'Data de Atualização',
      dataIndex: 'updatedFormatted',
      key: '6',
    },
    {
      title: 'Trabalhando',
      key: '7',
      render: (correction) => (
        <div>
          <p>
            {`Coordenador: ${
              correction.coordinator && correction.coordinator.name
            }`}
          </p>
          <p>
            {`Especialista: ${
              correction.proofreader && correction.proofreader.name
            }`}
          </p>
        </div>
      ),
    },
    {
      title: 'Status de correção',
      key: 'correction_status',
      dataIndex: 'correction_status',
      render: (_, { correction_status }) => (
        <>
          {correction_status.map((tag) => (
            <Tag color={getOrderCorrectionStatusColor(tag)} key="nice">
              {getOrderCorrectionStatus(tag)}
            </Tag>
          ))}
        </>
      ),
    },
    {
      title: 'Ações',
      key: 'action',
      render: (order) => (
        <div>
          <Tooltip title="Visualizar dados do pedido">
            <NavLink to={`/pedidos/visualizar/${order.order.id}`}>
              <Button
                icon={<MdVisibility size={20} color="#fff" />}
                href="https://www.google.com"
                style={{ background: '#1677ff' }}
              />
            </NavLink>
          </Tooltip>

          <Tooltip title="Visualizar correção">
            <NavLink
              style={{ marginLeft: 10 }}
              to={`${
                user.permission_level !== 'proofreader'
                  ? `/correcao/coordenador/${order.order.id}`
                  : `/correcao/${order.order.id}`
              }`}
            >
              <Button
                icon={<MdOutlineNewspaper size={20} color="#fff" />}
                href="https://www.google.com"
                style={{ background: 'orange' }}
              />
            </NavLink>
          </Tooltip>
        </div>
      ),
    },
  ];

  return (
    <>
      <Table
        columns={columns}
        dataSource={corrections}
        loading={loading}
        pagination={false}
      />

      {!isSearching && (
        <Pagination
          onChange={setPaginationPage}
          current={paginationPage}
          total={correctionsTotal}
          showSizeChanger={false}
          defaultPageSize={10}
        />
      )}
    </>
  );
}
