import React from 'react';
import { Button } from 'antd';

import { BoxInput, Container } from './styles';

export function Filters({ setSearchWord, searchProducts, clearSearch }) {
  return (
    <Container>
      <BoxInput>
        <input
          id="search"
          type="text"
          name="search"
          placeholder="Buscar"
          onChange={(event) => setSearchWord(event.target.value)}
          onKeyDown={(event) => event.key === 'Enter' && searchProducts()}
        />
      </BoxInput>

      <Button type="link" onClick={clearSearch}>
        Limpar Busca
      </Button>
    </Container>
  );
}
